import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { useMarkDraftPayoutReport, usePreparePayoutReport, usePublishPayoutReport, useUpdatePayoutReportMutation } from '../../../hooks/use-mutations';
import useOnError from '../../../hooks/use-on-error';
import { useGetPayoutReportQuery } from '../../../hooks/use-queries';
import PageWrapper from '../../PageWrapper';
import PayoutReportForm from '../shared/PayoutReportForm';

interface Props {
    onSuccess?: (projectId: string, message?: string) => void;
    onError?: (error) => void;
}

const EditPayoutReportsPage: FC<Props> = () => {
    const { payoutReportId } = useParams();
    const { data: payoutReport, isLoading, error } = useGetPayoutReportQuery(payoutReportId);
    const { enqueueSnackbar } = useSnackbar();
    const onError = useOnError();
    const mutation = useUpdatePayoutReportMutation(() => onSuccess('updated'));
    const publishMutation = usePublishPayoutReport(() => onSuccess('published'));
    const preparePayoutMutation = usePreparePayoutReport(() => onSuccess('prepared'));
    const draftPayoutMutation = useMarkDraftPayoutReport(() => enqueueSnackbar(`Payout Report marked as draft`, { variant: 'success' }));

    function onSuccess(type: 'prepared' | 'published' | 'updated') {
        enqueueSnackbar(`Payout Report got ${type}`, { variant: 'success' });
    }

    if (!isLoading && error) {
        onError(error);
    }

    const breadcrumbs = {
        '/dashboard': 'Dashboard',
        '/payout-reports': 'Payout Reports',
        '': `${payoutReportId}`,
    };
    return (
        <PageWrapper title="Payout Report" breadcrumbs={breadcrumbs} status={payoutReport?.status}>
            {isLoading ? (
                <Loader shouldOverride />
            ) : (
                <PayoutReportForm
                    mutation={mutation}
                    publishMutation={publishMutation}
                    preparePayoutMutation={preparePayoutMutation}
                    draftPayoutMutation={draftPayoutMutation}
                    payoutReport={payoutReport}
                />
            )}
        </PageWrapper>
    );
};

export default EditPayoutReportsPage;
