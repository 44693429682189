import { Box, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DRAWER_WIDTH } from '../../../../constants';
import Logo from '../../components/Logo';
import MHidden from '../../components/MHidden';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import { sidebarOptions } from '../../menus';

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
    },
}));

interface Props {
    isOpenSidebar: boolean;
    onCloseSidebar: () => void;
}
const DashboardSidebar: FC<Props> = ({ isOpenSidebar, onCloseSidebar }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [onCloseSidebar, isOpenSidebar, pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: '100%',
                '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' },
            }}
        >
            <Box sx={{ px: 2.5, py: 3 }}>
                <Logo />
            </Box>

            <NavSection navConfig={sidebarOptions} />

            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    return (
        <RootStyle>
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
};

export default DashboardSidebar;
