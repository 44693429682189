import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Badge from '@mui/material/Badge';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useState } from 'react';
import { brands } from '../pages/project/constants';
import type { Brand } from '../pages/project/types';

export type TabsComponents = Record<Brand, JSX.Element>;
export type WarnIcon = Record<Brand, boolean>;
interface Props {
    activeTab?: Brand;
    tabsComponents: TabsComponents;
    warnIcon?: WarnIcon;
}

const BrandTabs: FC<Props> = ({ activeTab = brands[0], tabsComponents, warnIcon }) => {
    const [tab, setTab] = useState<string>(activeTab);

    return (
        <TabContext value={tab}>
            <TabList onChange={(_, value: string) => setTab(value)}>
                {brands.map(brand => (
                    <Tab
                        key={`tab-${brand}`}
                        label={
                            !warnIcon ? (
                                brand
                            ) : (
                                <Badge badgeContent="!" color="warning" invisible={warnIcon[brand]}>
                                    {brand}
                                </Badge>
                            )
                        }
                        value={brand}
                    />
                ))}
            </TabList>
            {brands.map(brand => (
                <TabPanel key={`tab-panel-${brand}`} value={brand} sx={{ padding: 0, pt: 1 }}>
                    {tabsComponents[brand] || (
                        <Typography color="text.primary" textAlign="center">
                            Tab content is empty
                        </Typography>
                    )}
                </TabPanel>
            ))}
        </TabContext>
    );
};

export default BrandTabs;
