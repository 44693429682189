import { currency } from '@condo/formatters';
import OutboundIcon from '@mui/icons-material/NorthEast';
import InboundIcon from '@mui/icons-material/SouthWest';
import Link from '@mui/material/Link';
import type { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import type { IOrder } from '../../../../../domain-entities/models/order';
import type { GetUserFileResponse } from '../../../../../schemas';
import { useGetData } from '../../../hooks/use-get-data';
import Section from '../../Section';

interface Props {
    userId: string;
}

const UserTransactions: FC<Props> = ({ userId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        dataGridPaginationProps,
        list = [],
        error,
        isLoading,
    } = useGetData({
        dataKey: 'USER_TRANSACTIONS',
        defaultPageSize: 15,
        extraProps: { userId },
    });

    if (!isLoading && error) {
        enqueueSnackbar(error.message, { variant: 'error' });
    }

    const columns: GridColumns = [
        {
            field: 'flow',
            headerName: 'Flow',
            width: 50,
            renderCell: (params: GridRenderCellParams) => {
                return params.row.flow === 'INBOUND' ? <InboundIcon color="success" /> : <OutboundIcon color="warning" />;
            },
        },
        {
            field: 'type',
            headerName: 'Type',
            minWidth: 130,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<IOrder>) => currency(params.row.amount),
        },
        {
            field: 'project',
            headerName: 'Project',
            minWidth: 130,
            renderCell: (params: GridRenderCellParams) => params.row.project.code,
        },
        {
            field: 'order',
            headerName: 'Order',
            renderCell: (params: GridRenderCellParams) => {
                if (!params.row.order) {
                    return '–';
                }
                return (
                    <Link href={`/orders/view/${params.row.orderId}`} target="_blank">
                        {params.row.order.code}
                    </Link>
                );
            },
        },
        {
            field: 'orderItem',
            headerName: 'Order Item',
            minWidth: 130,
            renderCell: (params: GridRenderCellParams) => params.row.orderItem?.code || '–',
        },
        {
            field: 'createAt',
            headerName: 'Created',
            type: 'dateTime',
            minWidth: 180,
            valueGetter: (params: GridRenderCellParams<string, GetUserFileResponse>) => new Date(params.row.createdAt),
        },
    ];

    return (
        <Section title="Transactions">
            <DataGrid rows={list} columns={columns} error={error} autoHeight loading={isLoading} checkboxSelection={false} {...dataGridPaginationProps} />
        </Section>
    );
};

export default UserTransactions;
