import { currency } from '@condo/formatters';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import type { IPaymentRequest } from '../../../../domain-entities/models/payment-request';
import type { ITransaction } from '../../../../domain-entities/models/transaction';
import DetailsItem from '../DetailsItem';
import Section from '../Section';
import PaymentRequestStatusChip from './StatusChip';

interface Props {
    data: Required<IPaymentRequest> & { transaction?: ITransaction };
}

const PaymentRequestDetails: FC<Props> = ({ data }) => {
    const { recipient, sender } = data;
    return (
        <Section title="Payment Request">
            <DetailsItem title="Status" value={<PaymentRequestStatusChip status={data.status} />} />
            <DetailsItem title="Type" value={data.type} />
            <DetailsItem title="Created at" value={new Date(data.createdAt).toLocaleString()} />
            <DetailsItem title="Paid at" value={data.paidAt ? new Date(data.paidAt).toLocaleString() : null} />

            <Typography color="text.secondary" component="p" variant="overline">
                Payment Transfer Details
            </Typography>
            <DetailsItem title="Amount" value={currency(data.amount)} />
            <DetailsItem title="Account holder" value={recipient.accountName} />
            <DetailsItem title="Bank name" value={recipient.bankName} />
            <DetailsItem title="IBAN" value={recipient.iban} />
            <DetailsItem title="BIC" value={recipient.bic} />
            <DetailsItem title="Payment Ref." value={recipient.referenceCode} />

            <Typography color="text.secondary" component="p" variant="overline">
                Sender Payment Details
            </Typography>
            <DetailsItem title="Account holder" value={sender?.accountName} />
            <DetailsItem title="Bank name" value={sender?.bankName} />
            <DetailsItem title="IBAN" value={sender?.iban} />
        </Section>
    );
};

export default PaymentRequestDetails;
