import { currency } from '@condo/formatters';
import { DataGridOptions } from '@condo/invest-utils';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import type { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
// @ts-expect-error
import _isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import type { IOrder, OrderStatus, OrderWithProjectData } from '../../../../../domain-entities/models/order';
import type { IOrderItem } from '../../../../../domain-entities/models/order-item';
import { getOrderState } from '../../../helpers/order';
import { useGetUserOrders } from '../../../hooks/use-queries';
import Loader from '../../Loader';
import Section from '../../Section';
import OrderItemsCell from '../../order/OrderItemsCell';
import OrderStatusChip from '../../order/OrderStatusChip';
import OrdersSummary from '../../order/OrdersSummary';

interface Props {
    userId: string;
}

const UserOrders: FC<Props> = ({ userId }) => {
    const { isLoading, data: orders, error } = useGetUserOrders(userId);

    const { enqueueSnackbar } = useSnackbar();

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    if (!isLoading && error) {
        onError(error);
    }

    const columns: GridColumns = [
        {
            field: 'userOrderItemStatus',
            headerName: 'Resolved State',
            minWidth: 120,
            filterable: false,
            sortable: false,
            renderCell: (params: GridRenderCellParams<IOrder>) => {
                const orderState = getOrderState(params.row);
                return <OrderStatusChip orderStatus={orderState as OrderStatus} />;
            },
        },
        {
            field: 'code',
            headerName: 'Code',
            minWidth: 80,
            renderCell: (params: GridRenderCellParams<IOrder>) => (
                <Link href={`/orders/view/${params.id}`} target="_blank" rel="noreferrer">
                    {params.value}
                </Link>
            ),
        },
        {
            field: 'project',
            headerName: 'Project code',
            minWidth: 200,
            renderCell: (params: GridRenderCellParams<OrderWithProjectData>) => (
                <Link href={`/projects/edit/${params.value.id}`} target="_blank" rel="noreferrer">
                    {params.value.code}
                </Link>
            ),
        },
        {
            field: 'amount',
            headerName: 'Amount',
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<IOrder>) => {
                return currency(params.row.tokens * params.row.tokenPrice);
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 100,
            valueOptions: ['OPEN', 'CANCELLED', 'PAID', 'SETTLED', 'BUYBACK'],
            renderCell: (params: GridRenderCellParams<string, IOrder>) => {
                return <OrderStatusChip orderStatus={params.value as OrderStatus} variant="outlined" />;
            },
        },
        {
            field: 'orderItems',
            headerName: 'Items (paid/amount)',
            minWidth: 160,
            renderCell: (params: GridRenderCellParams<IOrderItem[]>) => {
                return <OrderItemsCell items={params.value} />;
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            type: 'dateTime',
            minWidth: 180,
            valueGetter: (params: GridRenderCellParams<string, IOrder>) => new Date(params.value),
        },
        {
            field: 'completedAt',
            headerName: 'Completed At',
            type: 'dateTime',
            minWidth: 180,
            valueGetter: (params: GridRenderCellParams<string, IOrder>) => (params.value ? new Date(params.value) : ''),
        },
    ];

    return (
        <Section title="User orders">
            {isLoading ? (
                <Loader />
            ) : _isEmpty(orders) ? (
                <Typography color="text.primary">No User orders found</Typography>
            ) : (
                <>
                    <DataGrid
                        rows={orders}
                        columns={columns}
                        error={error}
                        autoHeight
                        loading={isLoading}
                        checkboxSelection={false}
                        pageSize={15}
                        rowsPerPageOptions={DataGridOptions.rowsPerPageOptions}
                    />
                    <Box sx={{ mt: 2 }}>
                        <OrdersSummary orders={orders} />
                    </Box>
                </>
            )}
        </Section>
    );
};

export default UserOrders;
