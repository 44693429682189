import { Grid, InputAdornment } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import { Fragment, useEffect } from 'react';
import type { IPayoutReport } from '../../../../../domain-entities/models/payout-report';
import type { ITempFile } from '../../../../../domain-entities/models/tempFile';
import { calcTotalDividendAmount } from '../../../../../infrastructure/helpers/calc';
import ContentEditor from '../../../components/ContentEditor';
import { FileUploader } from '../../../components/FileUploader';
import { getNextPayout } from '../../../helpers/date';
import { useGetProjectQuery } from '../../../hooks/use-queries';
import type { BaseProjectComponentProps } from '../../project/types';
import { payoutReportElementsGroups } from '../constants';
import TextField from './TextField';

type Props = BaseProjectComponentProps & {
    projectId: string;
};
export type FormValues = IPayoutReport & { checksum: number };

const PayoutReportFormElements: FC<Props> = ({ projectId }) => {
    const { values, setFieldValue } = useFormikContext<FormValues>();
    const { data: projectData } = useGetProjectQuery(projectId);

    const onFileUpload = (files: ITempFile[]) => {
        const [{ id: reportFileId }] = files;
        setFieldValue('reportFileId', reportFileId);
    };

    useEffect(() => {
        if (projectData?.details?.firstPayout?.value && !values?.year) {
            const { year, quarter, date } = getNextPayout(projectData.details.firstPayout.value);
            setFieldValue('year', year);
            setFieldValue('quarter', quarter);
            setFieldValue('payoutDate', date);
        }
    }, [projectData, setFieldValue]);

    useEffect(() => {
        const checkSum = calcTotalDividendAmount(values);
        setFieldValue('checksum', checkSum);
    }, [values, setFieldValue]);

    const disabled = values?.status !== 'DRAFT';
    const showReportUpload = values?.id && values?.status !== 'PUBLISHED';

    if (!projectData) {
        return null;
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <ContentEditor label="Summary Text" name="summaryText" disabled={disabled} />
            </Grid>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="h5" mt={2}>
                    Financial Data
                </Typography>
            </Grid>
            {payoutReportElementsGroups.map((payoutReportElements, idx) => {
                const fields = payoutReportElements.map(payoutReportElement => (
                    <Grid item xs={12} sm={4} key={payoutReportElement.key}>
                        <TextField
                            name={payoutReportElement.key}
                            label={payoutReportElement.label}
                            type="number"
                            inputProps={{
                                startAdornment: <InputAdornment position="start">EUR</InputAdornment>,
                                disabled: payoutReportElement.disabled || disabled,
                                value: values[payoutReportElement.key],
                            }}
                        />
                    </Grid>
                ));

                return (
                    <Fragment key={idx}>
                        {fields}
                        {idx !== payoutReportElementsGroups.length - 1 ? (
                            <Grid item xs={12} key={`group-${idx}`}>
                                <Divider />
                            </Grid>
                        ) : null}
                    </Fragment>
                );
            })}

            {showReportUpload ? (
                <>
                    <Grid item xs={12}>
                        <Typography color="text.secondary" variant="h5" mt={2}>
                            Report File
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FileUploader id="payout-report" allowedFileTypes={['application/pdf']} showItems onUploadSucceed={onFileUpload} />
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
};

export default PayoutReportFormElements;
