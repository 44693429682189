import { Navigate, useRoutes } from 'react-router-dom';

import { FileViewer } from './components/FileViewer';
import PublicLayout from './layouts/PublicLayout';
// TODO: add lazy-loading
import DashboardLayout from './layouts/dashboard';
import DashboardApp from './pages/DashboardApp';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import BankTransactionPage from './pages/bank-transactions/BankTransactionPage';
import TransactionsImportLog from './pages/bank-transactions/TransactionsImportLog';
import UploadBankTransactions from './pages/bank-transactions/UploadBankTransactions';
import ImportsHistoryPage from './pages/bank-transactions/import-history/Page';
import BankTransactionsList from './pages/bank-transactions/list/Page';
import DistributionCreatePage from './pages/distribution/create/Page';
import DistributionEditPage from './pages/distribution/edit/Page';
import DistributionListPage from './pages/distribution/list/Page';
import { HistoryEventsListPage } from './pages/history-events/list/Page';
import JobsListPage from './pages/job/list/Page';
import JobViewPage from './pages/job/view/Page';
import OrdersListPage from './pages/order/list/Page';
import OrderViewPage from './pages/order/view/Page';
import PaymentRequestsListPage from './pages/payment-requests/list/Page';
import PaymentRequestPage from './pages/payment-requests/view/Page';
import PayoutReportCreatePage from './pages/payout-report/create/Page';
import PayoutReportEditPage from './pages/payout-report/edit/Page';
import PayoutReportsListPage from './pages/payout-report/list/Page';
import ProjectCreatePage from './pages/project/create/Page';
import ProjectEditPage from './pages/project/edit/Page';
import ProjectsListPage from './pages/project/list/Page';
import IntegrationsPage from './pages/settings/IntegrationsPage';
import MergingCategoriesPage from './pages/settings/MergingCategoriesPage';
import VoucherAccountPage from './pages/settings/VoucherAccountPage';
import AdminUserCreatePage from './pages/settings/adminUser/create/Page';
import AdminUserEditPage from './pages/settings/adminUser/edit/Page';
import AdminUsersListPage from './pages/settings/adminUser/list/Page';
import DocumentsPage from './pages/settings/documents/Page';
import PlatformSettingsPage from './pages/settings/platformSettings/Page';
import UserListPage from './pages/user/list/Page';
import UserViewPage from './pages/user/view/Page';

export default function Router() {
    return useRoutes([
        {
            path: '/document',
            element: <DashboardLayout />,
            children: [{ index: true, element: <FileViewer /> }],
        },
        {
            path: '/dashboard',
            element: <DashboardLayout />,
            children: [
                { index: true, element: <Navigate to="/dashboard/app" replace /> },
                { path: 'app', element: <DashboardApp /> },
            ],
        },
        {
            path: '/projects',
            element: <DashboardLayout />,
            children: [
                { index: true, element: <ProjectsListPage /> },
                { path: 'edit/:projectId', element: <ProjectEditPage /> },
                { path: 'add', element: <ProjectCreatePage /> },
            ],
        },
        {
            path: '/payout-reports',
            element: <DashboardLayout />,
            children: [
                { index: true, element: <PayoutReportsListPage /> },
                { path: 'edit/:payoutReportId', element: <PayoutReportEditPage /> },
                { path: 'add', element: <PayoutReportCreatePage /> },
            ],
        },
        {
            path: '/history-events',
            element: <DashboardLayout />,
            children: [{ index: true, element: <HistoryEventsListPage /> }],
        },
        {
            path: '/users',
            element: <DashboardLayout />,
            children: [
                { index: true, element: <UserListPage /> },
                { path: 'view/:userId/:tabName?', element: <UserViewPage /> },
            ],
        },

        {
            path: '/orders',
            element: <DashboardLayout />,
            children: [
                { index: true, element: <Navigate to="/orders/all" /> },
                { path: 'all', element: <OrdersListPage key="all" entity="order" title="All Orders" /> },
                { path: 'view/:orderId', element: <OrderViewPage /> },
            ],
        },
        {
            path: 'jobs',
            element: <DashboardLayout />,
            children: [
                { index: true, element: <Navigate to="/jobs/all" /> },
                { path: 'all', element: <JobsListPage /> },
                { path: 'view/:jobId', element: <JobViewPage /> },
            ],
        },
        {
            path: '/distribution',
            element: <DashboardLayout />,
            children: [
                { index: true, element: <Navigate to="/distribution/list" /> },
                { path: 'list', element: <DistributionListPage /> },
                { path: 'add', element: <DistributionCreatePage /> },
                { path: 'edit/:distributionId', element: <DistributionEditPage /> },
            ],
        },
        {
            path: '/payment-requests',
            element: <DashboardLayout />,
            children: [
                { index: true, element: <Navigate to="/payment-requests/list" /> },
                {
                    path: 'list',
                    element: <PaymentRequestsListPage title="Payment Requests" />,
                },
                {
                    path: 'view/:paymentRequestId',
                    element: <PaymentRequestPage title="Payment Request Details" />,
                },
            ],
        },
        {
            path: '/bank-transactions',
            element: <DashboardLayout />,
            children: [
                { index: true, element: <Navigate to="/bank-transactions/list" /> },
                {
                    path: 'import',
                    element: <UploadBankTransactions title="Bank Incoming Payments" />,
                },
                {
                    path: 'list',
                    element: <BankTransactionsList title="List Bank Transactions" />,
                },
                {
                    path: 'view/:id',
                    element: <BankTransactionPage title="Bank Transaction Details" />,
                },
                {
                    path: 'imports-history',
                    element: <ImportsHistoryPage title="Imports History" />,
                },
                {
                    path: 'imports-history/:importRef',
                    element: <TransactionsImportLog title="Transactions Import Results" />,
                },
            ],
        },
        {
            path: '/settings',
            element: <DashboardLayout />,
            children: [
                { index: true, element: <Navigate to="/settings/admin-user" /> },
                { path: 'admin-user', element: <AdminUsersListPage /> },
                { path: 'admin-user/create', element: <AdminUserCreatePage /> },
                { path: 'admin-user/:userId', element: <AdminUserEditPage /> },
                { index: true, element: <Navigate to="/settings/integrations" /> },
                { path: 'integrations', element: <IntegrationsPage /> },
                { path: 'voucher-account', element: <VoucherAccountPage /> },
                { path: 'documents', element: <DocumentsPage /> },
                { path: 'merging-categories', element: <MergingCategoriesPage /> },
                { path: 'platform-settings', element: <PlatformSettingsPage /> },
            ],
        },
        {
            path: '/',
            element: <PublicLayout />,
            children: [
                { path: 'login', element: <Login /> },
                { path: '404', element: <NotFound /> },
                { path: '/', element: <Navigate to="/dashboard" /> },
                { path: '*', element: <Navigate to="/404" /> },
            ],
        },
        { path: '*', element: <Navigate to="/404" /> },
    ]);
}
