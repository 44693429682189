import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import type { FC, PropsWithChildren } from 'react';
import SimpleBarReact from 'simplebar-react';

const RootStyle = styled('div')({
    flexGrow: 1,
    // height: '100%',
    overflow: 'hidden',
});

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
    // maxHeight: '100%',
    '& .simplebar-scrollbar': {
        '&:before': {
            backgroundColor: alpha(theme.palette.grey[600], 0.48),
        },
        '&.simplebar-visible:before': {
            opacity: 1,
        },
    },
    '& .simplebar-track.simplebar-vertical': {
        width: 10,
    },
    '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
        height: 6,
    },
    '& .simplebar-mask': {
        zIndex: 'inherit',
    },
}));

interface Props {
    sx?: SxProps;
}

const Scrollbar: FC<PropsWithChildren<Props>> = ({ children, sx }) => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobile) {
        return <Box sx={{ overflowX: 'auto', ...sx }}>{children}</Box>;
    }

    return (
        <RootStyle>
            <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx}>
                {children}
            </SimpleBarStyle>
        </RootStyle>
    );
};

export default Scrollbar;
