import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { IDistribution } from '../../../../../domain-entities/models/distribution';
import type { IProject } from '../../../../../domain-entities/models/project';
import type { DataGridProps } from '../../../components/DataGrid';
import DataGrid, { useGridColumns } from '../../../components/DataGrid';
import StatusChip from '../../../components/StatusChip';
import { getStatusColor } from '../../../helpers/color';
import PageWrapper from '../../PageWrapper';
import type { DistributionStatus } from '.prisma/client';

type GridData = IDistribution & { project: Required<IProject> };

const DistributionListPage: FC = () => {
    const columns = useGridColumns<GridData>([
        {
            accessorKey: 'project.code',
            header: 'Project Code',
            size: 120,
        },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 120,
            filterVariant: 'multi-select',
            filterSelectOptions: ['DRAFT', 'INPROGRESS', 'PREPARED', 'COMPLETED', 'FAILED'] as DistributionStatus[],
            Cell: ({ cell }) => <StatusChip label={cell.getValue()} size="small" color={getStatusColor(cell.getValue<string>())} />,
        },
        {
            accessorKey: 'distributedAt',
            header: 'Distributed At',
            dataType: 'date-time',
        },
        {
            accessorKey: 'createdAt',
            header: 'Created At',
            dataType: 'date-time',
        },
    ]);

    const dataGridProps: DataGridProps<GridData> = {
        columns,
        defaultPageSize: 100,
        dataFetch: {
            key: 'DISTRIBUTION',
        },
        actions: {
            view(row) {
                const { id } = row;
                window.open(`/distribution/edit/${id}`, 'blank');
            },
        },
    };

    return (
        <PageWrapper
            title="Tokens Distributions"
            actionButtons={[
                <Button key="add" variant="contained" component={RouterLink} to="/distribution/add" startIcon={<AddIcon />}>
                    Add Distribution
                </Button>,
            ]}
        >
            <Card>
                <DataGrid {...dataGridProps} />
            </Card>
        </PageWrapper>
    );
};
export default DistributionListPage;
