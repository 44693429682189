import { useSnackbar } from 'notistack';

const useOnError = () => {
    const { enqueueSnackbar } = useSnackbar();

    return (error: Error & { code?: string }) => {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    };
};

export default useOnError;
