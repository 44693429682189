import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Numbers from '@mui/icons-material/Numbers';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import TabsList from '@mui/lab/TabList';
import { Tab } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { FC } from 'react';
import type { TabListS } from '../types';

interface Props {
    currentTab: number;
    setCurrentTab: (tab: number) => void;
    tabList: TabListS;
    addTab: () => void;
    openDeleteDialog: (id?: string) => void;
}

const ProjectEstatesTabs: FC<Props> = ({ currentTab, setCurrentTab, tabList, addTab, openDeleteDialog }) => {
    const disallowAddEstate = tabList.some(tab => tab.id === undefined);

    return (
        <Box sx={{ display: 'flex' }}>
            <TabsList
                value={currentTab.toString()}
                onChange={(_event: React.SyntheticEvent, value: string) => {
                    setCurrentTab(Number.parseInt(value, 10));
                }}
                textColor="primary"
                indicatorColor="secondary"
                sx={{ mb: 2 }}
            >
                {tabList.map(tabElement => {
                    return (
                        <Tab
                            key={tabElement.value}
                            value={tabElement.value.toString()}
                            label={tabElement.value.toString()}
                            icon={<Numbers />}
                            iconPosition="start"
                        />
                    );
                })}
            </TabsList>
            {!disallowAddEstate && (
                <Button onClick={addTab}>
                    <AddCircleOutline />
                </Button>
            )}
            {tabList.length > 0 && (
                <Button
                    onClick={() => {
                        openDeleteDialog(tabList[currentTab - 1].id);
                    }}
                >
                    <RemoveCircleOutline />
                </Button>
            )}
        </Box>
    );
};

export default ProjectEstatesTabs;
