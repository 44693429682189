import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button } from '@mui/material';
import { ExportToCsv } from 'export-to-csv';
import type { MRT_RowSelectionState } from 'material-react-table';
import type { FC } from 'react';
import { useState } from 'react';
import ConfirmDialog from '../Dialog';
import type { ExportColumns, ExportElements } from './types';

const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
};
export const csvExporter = (exportedFileName: string) => new ExportToCsv({ ...csvOptions, filename: exportedFileName ?? 'generated' });

interface Props {
    exportElements: ExportElements;
    setCurrentExportId: (id: 'tax-data' | 'payment-data' | 'payment-requests-sepa-xml-data' | 'payment-requests') => void;
    setExportColumns?: (data: ExportColumns) => void;
    tableRef?: any;
    rowSelection?: MRT_RowSelectionState;
    setFetchWithoutPagination?: (value: boolean) => void;
}
const ExportButtons: FC<Props> = ({ exportElements, setCurrentExportId, setExportColumns, tableRef, setFetchWithoutPagination, rowSelection }) => {
    const [dialogOpen, setDialogOpen] = useState<boolean[]>([]);

    const toggleDialogState = (id: string | number, isOpen: boolean) => {
        setDialogOpen(prevState => {
            const newState = [...prevState];
            newState[id] = isOpen;
            return newState;
        });
    };
    return (
        <>
            {exportElements?.length && exportElements.length > 0
                ? exportElements.map(({ action, hasIcon, buttonText, dialogContent, id, dialogTitle }, index) => {
                      return (
                          <div key={id}>
                              <Button
                                  color="secondary"
                                  size="small"
                                  onClick={() => {
                                      setCurrentExportId(id);
                                      if (dialogContent) {
                                          toggleDialogState(index, true);
                                          return;
                                      }
                                      if (action) {
                                          action(Object.keys(rowSelection), tableRef, setExportColumns, setFetchWithoutPagination);
                                      }
                                  }}
                                  variant="outlined"
                                  startIcon={hasIcon ? <FileDownloadIcon /> : null}
                              >
                                  {buttonText}
                              </Button>
                              {dialogContent ? (
                                  <ConfirmDialog
                                      content={dialogContent}
                                      title={dialogTitle}
                                      isOpened={dialogOpen[index] || false}
                                      handleClose={() => toggleDialogState(index, false)}
                                      handleConfirm={() => {
                                          if (action) {
                                              action(Object.keys(rowSelection), tableRef, setExportColumns, setFetchWithoutPagination);
                                          }
                                          toggleDialogState(index, false);
                                      }}
                                  />
                              ) : null}
                          </div>
                      );
                  })
                : null}
        </>
    );
};
export default ExportButtons;
