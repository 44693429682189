import type { User as FirebaseUser, Unsubscribe } from 'firebase/auth';
import type { FirebaseAuth, IWebDI } from '../di';

interface IAuthError {
    code: string;
    message: string;
}

export interface IAuthResult {
    result: 'success' | 'error';
    user: IUser | null;
    error?: IAuthError;
}

export interface IUser {
    uid: string;
    displayName: string;
    email: string;
    photoUrl: string | null;
}

export type AuthEvent = 'state-change:user' | 'state-change:token';

export class AuthService {
    public token: string = null;
    private user: FirebaseUser = null;
    private authApi: FirebaseAuth;
    private observers: Unsubscribe[] = [];
    private listeners = { 'state-change:user': [] as Function[], 'state-change:token': [] as Function[] };

    constructor({ firebaseAuth }: IWebDI) {
        this.authApi = firebaseAuth;
        this.initObservers();
    }

    tryRecoverSession(): Promise<boolean> {
        if (this.isLoggedIn()) {
            return Promise.resolve(true);
        }

        return new Promise(resolve => {
            const unsubscribe = this.authApi.onAuthStateChanged(this.authApi.getAuth(), async user => {
                unsubscribe();
                this.user = user;
                if (user) {
                    try {
                        this.token = await user.getIdToken();
                        resolve(true);
                    } catch (e) {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            });
        });
    }

    async loginWithEmailPassword(email: string, password: string): Promise<IAuthResult> {
        const auth = this.authApi.getAuth();
        try {
            const { user } = await this.authApi.signInWithEmailAndPassword(auth, email, password);
            this.user = user;
            return { result: 'success', user: this.getProfile() };
        } catch (error) {
            await this.logOut();
            return {
                result: 'error',
                error: {
                    code: error.code,
                    message: error.message,
                },
                user: null,
            };
        }
    }

    async loginWithOneLogin(): Promise<IAuthResult> {
        const auth = this.authApi.getAuth();
        const provider = new this.authApi.SAMLAuthProvider('saml.onelogin');
        try {
            const { user } = await this.authApi.signInWithPopup(auth, provider);
            this.user = user;
            return { result: 'success', user: this.getProfile() };
        } catch (error) {
            await this.logOut();
            return {
                result: 'error',
                error: {
                    code: error.code,
                    message: error.message,
                },
                user: null,
            };
        }
    }

    isLoggedIn(): boolean {
        return Boolean(this.getProfile());
    }

    getProfile(): IUser | null {
        const auth = this.authApi.getAuth();
        const user = auth.currentUser;
        if (user !== null) {
            const { uid, displayName, email, photoURL: photoUrl } = user;
            return { uid, displayName, email, photoUrl };
        }

        return null;
    }

    async getToken(): Promise<string | null> {
        const auth = this.authApi.getAuth();
        const user = auth.currentUser;
        return user?.getIdToken() ?? null;
    }

    logOut(): Promise<void> {
        return this.authApi.signOut(this.authApi.getAuth());
    }

    dispose(): void {
        if (this.observers.length) {
            this.observers.forEach(unsubscribe => unsubscribe());
        }
    }

    on<T>(event: AuthEvent, callback: (data: T) => void) {
        this.listeners[event].push(callback);
        return () => {
            this.listeners[event].forEach((fn, idx) => {
                if (fn === callback) {
                    this.listeners[event].splice(idx, 1);
                }
            });
        };
    }

    private initObservers() {
        const auth = this.authApi.getAuth();
        this.observers.push(
            this.authApi.onAuthStateChanged(auth, user => {
                if (user) {
                    this.user = user;
                } else {
                    // user is signed out
                    this.user = null;
                }

                this.emit<IUser>('state-change:user', this.getProfile());
            }),
        );

        this.observers.push(
            this.authApi.onIdTokenChanged(auth, async user => {
                if (user) {
                    user.getIdToken().then(token => {
                        this.token = token;
                    });
                } else {
                    // user is signed out
                    this.token = null;
                }

                this.emit<string>('state-change:token', this.token);
            }),
        );
    }

    private emit<T>(event: AuthEvent, data: T) {
        if (this.listeners[event].length) {
            this.listeners[event].forEach(fn => fn(data));
        }
    }
}
