export const payoutReportElementsGroups = [
    [{ key: 'netRevenue', label: 'Net Revenue' }],
    [
        { key: 'securityTrustee', label: 'Security Trustee' },
        { key: 'icServiceFees', label: 'IC Service Fees' },
        { key: 'invPmFees', label: 'INV PM Fees' },
        { key: 'camPmFees', label: 'CAM PM Fees' },
        { key: 'rentalLossRisk', label: 'Rental Loss Risk' },
        { key: 'maintenanceCosts', label: 'Maintenance Costs' },
        { key: 'loanInterest', label: 'Loan Interest' },
        { key: 'loanPayments', label: 'Loan Payments' },
        { key: 'otherCosts', label: 'Other Costs' },
    ],
    [
        { key: 'totalDividendAmount', label: 'Total Dividend Amount' },
        { key: 'checksum', label: 'Checksum to validate numbers', disabled: true },
    ],
];
export const defaultValues = {
    payoutReport: {
        year: 0,
        quarter: 'Q1',
        payoutDate: '',
        status: 'DRAFT',
        summaryText: '',
        reportFile: '',
        reportFileId: '',
        ...payoutReportElementsGroups.flat().reduce(
            (result, curr) => {
                return { [curr.key]: 0, ...result };
            },
            {} as { [key: string]: number },
        ),
    },
};
