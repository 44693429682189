import { LoadingButton } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel';
import { Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Form, Formik } from 'formik';
import _isBoolean from 'lodash/isBoolean';
import _isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import type { EntityDetails } from '../../../../../../domain-entities/models/base';
import type { IEstateDetails } from '../../../../../../domain-entities/models/estate';
import Loader from '../../../../components/Loader';
import { estateFormGeneralValidationSchema } from '../../../../helpers/formSchemas';
import { validateForm } from '../../../../helpers/formValidation';
import { useSaveEstateMutation } from '../../../../hooks/use-mutations';
import { defaultValues } from '../../constants';
import type { AssetMedia } from '../../types';
import type { EstateFormProps } from '../types';
import EstateDetailsForm from './EstateDetailsForm';

function formatData(
    currentTab: number,
    estate?: any,
): {
    id?: string;
    estateDetails: any;
    estateMediaFiles: AssetMedia;
} {
    if (_isEmpty(estate)) {
        return { id: undefined, estateDetails: defaultValues.estateDetails, estateMediaFiles: defaultValues.estateMediaFiles };
    }
    const { id, estateDetails, estateMediaFiles } = estate;
    const keyedDetails = estateDetails?.reduce(
        (result, item) => ({
            ...result,
            [`${item.name}${item.dimension && item.dimension !== 'generic' ? `_${item.dimension}` : ''}`]: item,
        }),
        {} as Record<string, IEstateDetails>,
    );
    return {
        id,
        estateDetails: {
            ...defaultValues.estateDetails,
            ...keyedDetails,
        },
        estateMediaFiles,
    };
}

const EstateEstateForm: FC<EstateFormProps> = ({ currentTab, isDeleteMutationLoading, openDeleteDialog, projectId, onSuccess, onError, estate }) => {
    const saveMutation = useSaveEstateMutation(onSuccess, onError);
    const formik = {
        initialValues: formatData(currentTab, estate),
        onSubmit: async (values, { setSubmitting }) => {
            Object.keys(values.estateMediaFiles).forEach(mediaFileKey => {
                if (!_isBoolean(values.estateMediaFiles[mediaFileKey]?.isCover)) {
                    values.estateMediaFiles[mediaFileKey].isCover = false;
                }
            });
            const { id, estateDetails, estateMediaFiles } = values;
            const dataToSet = { projectId, id, estateDetails: Object.values<IEstateDetails>(estateDetails), estateMediaFiles };
            await saveMutation.mutateAsync(dataToSet);

            setSubmitting(false);
        },
        validate: values => {
            const formattedDetails = {};
            Object.keys(values.estateDetails).map(key => {
                return Object.assign(formattedDetails, { [key]: values.estateDetails[key].value });
            });
            return validateForm<typeof estateFormGeneralValidationSchema>({ estateDetails: formattedDetails }, estateFormGeneralValidationSchema);
        },
        enableReinitialize: true,
        key: currentTab,
    };
    return (
        <Formik {...formik}>
            {({ isSubmitting, submitForm, values }) => (
                <Form
                    key={currentTab}
                    onSubmit={submitForm}
                    onBlur={() => {
                        estate = {
                            id: values.id,
                            projectId,
                            estateDetails: Object.values<Omit<EntityDetails, 'estateId' | 'dimension'>>(values.estateDetails),
                            estateMediaFiles: values.estateMediaFiles,
                        };
                    }}
                >
                    <>
                        {isSubmitting || saveMutation.isLoading ? <Loader shouldOverride /> : null}

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TabPanel value={currentTab.toString()}>
                                <EstateDetailsForm {...{ onSuccess, projectId, onError, currentTab }} />
                            </TabPanel>
                            <Stack sx={{ p: 2 }} direction="row" justifyContent="end">
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    loading={isSubmitting || saveMutation.isLoading}
                                    onClick={submitForm}
                                    sx={{ mr: 2 }}
                                >
                                    Save
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    color="warning"
                                    loading={isDeleteMutationLoading}
                                    onClick={() => {
                                        openDeleteDialog(values.id);
                                    }}
                                >
                                    Delete
                                </LoadingButton>
                            </Stack>
                        </LocalizationProvider>
                    </>
                </Form>
            )}
        </Formik>
    );
};

export default EstateEstateForm;
