import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { GetUserFullDataResponse } from '../../../../../../schemas';
import { useDeleteUserMutation } from '../../../../hooks/use-mutations';
import ConfirmDialog from '../../../Dialog';

interface Props {
    user: GetUserFullDataResponse;
}

const UserDeleteButton: FC<Props> = ({ user }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [dialogOpened, setDialogOpened] = useState(false);
    const mutation = useDeleteUserMutation(
        () => {
            setDialogOpened(false);
            enqueueSnackbar('User have been deleted', { variant: 'success' });
            return navigate('/users');
        },
        error => {
            enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
        },
    );

    if (!['CREATED', 'ONBOARDING_INPROGRESS'].includes(user.status)) {
        return null;
    }

    return (
        <>
            <LoadingButton variant="contained" color="error" onClick={() => setDialogOpened(true)} disabled={mutation.isLoading} loading={mutation.isLoading}>
                Delete User
            </LoadingButton>
            <ConfirmDialog
                content="Are you sure you want to delete this user?"
                title="User deletion"
                isOpened={dialogOpened}
                handleClose={() => setDialogOpened(false)}
                handleConfirm={() => {
                    mutation.mutate(user.id);
                }}
            />
        </>
    );
};

export default UserDeleteButton;
