import Alert from '@mui/material/Alert';
import type { FC } from 'react';
import { useEffect } from 'react';
import type { GetDistributionStatsResponse } from '../../../../../schemas';
import type { Stat } from '../../../components/StatsCards';
import StatsCards from '../../../components/StatsCards';
import { useGetProjectDistributionStatsQuery } from '../../../hooks/use-queries';

interface Props {
    onFetch?: (stats: GetDistributionStatsResponse) => void;
    projectId?: string;
    distributionId?: string;
}

const ProjectDistributionStats: FC<Props> = ({ projectId, distributionId, onFetch }) => {
    const { data, isLoading, isError } = useGetProjectDistributionStatsQuery({ projectId, distributionId });
    useEffect(() => {
        if (onFetch && data) {
            onFetch(data);
        }
    }, [onFetch, data]);

    if (isLoading) {
        return null;
    }

    if (isError) {
        return <Alert severity="error">Unable to load distribution stats</Alert>;
    }

    const stats: Stat[] = [
        { label: 'Involved Users', value: data.users },
        { label: 'Onboarded Users', value: data.onboardedUsers },
        { label: 'Required Tokens', value: data.tokensRequired },
        { label: 'Order (paid/ready)', value: `${data.paidOrders}/${data.eligibleOrders}` },
        { label: 'Prepared Order', value: data.preparedOrders },
    ];

    return <StatsCards stats={stats} />;
};

export default ProjectDistributionStats;
