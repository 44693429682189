import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useState } from 'react';
import type { IProject } from '../../../domain-entities/models/project';
import { useGetListData } from '../hooks/use-queries';
import type { ProjectStatus } from '.prisma/client';

interface Props {
    onChange: (projectId: string) => void;
    statuses?: ProjectStatus[];
    filterFn?: (projects: IProject[]) => IProject[];
}

const ProjectsSelect: FC<Props> = ({ onChange, filterFn, statuses = ['FUNDING_INPROGRESS', 'FUNDING_COMPLETE'] }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [projectId, setProjectId] = useState<string>();

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    const { data, isLoading, error } = useGetListData<IProject>('PROJECTS', {
        page: 0,
        pageSize: 100, // TODO: it's hardcoded for now, but probably there is a better way to get all
        filterQuery: JSON.stringify({ status: { in: statuses } }),
        sortQuery: { code: 'asc' },
    });

    if (isLoading) {
        return (
            <FormControl>
                <InputLabel id="project">Loading...</InputLabel>
                <Select disabled sx={{ width: 250, mb: 1 }} />
            </FormControl>
        );
    }

    if (!isLoading && error) {
        onError(error);
    }

    return (
        <FormControl>
            <InputLabel id="project">Project</InputLabel>
            <Select
                value={projectId}
                labelId="project"
                label="Project"
                sx={{ width: 250, mb: 1 }}
                onChange={(event: SelectChangeEvent) => {
                    setProjectId(event.target.value);
                    onChange(event.target.value);
                }}
            >
                {(filterFn ? filterFn(data.list) : data.list).map(project => (
                    <MenuItem value={project.id} key={project.code}>
                        {project.code}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ProjectsSelect;
