import { intlDateFormat } from '@condo/formatters';
import PhoneIcon from '@mui/icons-material/ContactPhone';
import EmailIcon from '@mui/icons-material/Email';
import OtherGenderIcon from '@mui/icons-material/PeopleAlt';
import MaleIcon from '@mui/icons-material/Person';
import FemaleIcon from '@mui/icons-material/Person3';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import type SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { userStatusColor } from '../../../../../constants';
import type { GetUserFullDataResponse } from '../../../../../schemas';
import { stringAvatar } from '../../../helpers/string';
import StatusChip from '../../StatusChip';
import SummaryBox from '../../SummaryBox';

const genderIcons = {
    MALE: {
        color: '#1890FF',
        icon: MaleIcon,
    },
    FEMALE: {
        color: '#e926ff',
        icon: FemaleIcon,
    },
    OTHER: {
        color: '#229A16',
        icon: OtherGenderIcon,
    },
};

const Info: FC<{ icon: typeof SvgIcon; text: string }> = ({ icon: Icon, text }) =>
    text ? (
        <Box sx={{ display: 'flex', direction: 'row' }}>
            <Icon fontSize="small" />
            <Typography fontSize="small">{text}</Typography>
        </Box>
    ) : null;

const UserSummary: FC<{ user: GetUserFullDataResponse }> = ({ user }) => {
    const fullName = `${user.profile?.firstName || '–'} ${user.profile?.lastName || '–'}`;
    const { icon: GenderIcon, color } = genderIcons[user.profile?.gender] || {};

    return (
        <SummaryBox>
            <Avatar {...stringAvatar(fullName)} />
            <Stack direction="column">
                <Typography variant="h4" gutterBottom>
                    {fullName} {color ? <GenderIcon htmlColor={color} /> : null}
                </Typography>
                <Info icon={EmailIcon} text={user.email} />
                <Info icon={PhoneIcon} text={user?.profile?.phoneNumber} />
            </Stack>
            <Stack direction="column">
                <StatusChip label={user.status} color={userStatusColor[user.status] || 'default'} size="small" />
                <Typography fontSize="small">
                    <b>ID:</b> {user.id}
                </Typography>
                <Typography fontSize="small">
                    <b>Created:</b> {intlDateFormat(user.createdAt, true)}
                </Typography>
            </Stack>
        </SummaryBox>
    );
};

export default UserSummary;
