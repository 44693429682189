import { DataGridOptions } from '@condo/invest-utils';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import { GridActionsCellItem } from '@mui/x-data-grid';
import _camelCase from 'lodash/camelCase';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import type { MRT_ColumnDef, MRT_Row, MRT_RowSelectionState } from 'material-react-table';
import MaterialReactTable from 'material-react-table';
import type { FC, ReactElement } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import Loader from '../Loader';
import { csvExporter } from './ExportButtons';
import TopToolbarCustomActions from './TopToolbarCustomActions';
import { useGetGridData } from './hooks/use-get-grid-data';
import type { ActionKey, Actions, DataGridProps } from './types';

const icons: Record<ActionKey, ReactElement> = {
    delete: <DeleteIcon />,
    edit: <EditIcon />,
    view: <ViewIcon />,
};

const DataGrid: FC<DataGridProps> = ({
    dataFetch,
    enableRowSelection,
    getRowId,
    columns,
    actions,
    toolbarActions,
    defaultPageSize,
    fetchService,
    defaultExportId,
    exportElements,
    rowSelectionData,
}) => {
    const [columnFilters] = useState([]);
    const [fetchWithoutPagination, setFetchWithoutPagination] = useState(false);
    const tableInstanceRef = useRef(null);
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [currentExportId, setCurrentExportId] = useState(defaultExportId);
    const [columnsToExport, setExportColumns] = useState<{ key: string; type?: 'object' | 'string'; childrenColumns?: string[]; prefix?: string }[]>(
        exportElements?.find(({ id }) => id === currentExportId)?.columns,
    );
    const { dataGridPaginationProps, list, isLoading } = useGetGridData({
        defaultPageSize,
        dataKey: dataFetch.key,
        extraProps: dataFetch.params,
        tableRef: tableInstanceRef.current,
        fetchService,
        fetchWithoutPagination,
    });

    const handleExportRows = useCallback(
        (rows: any[]) => {
            const valuesForCSV = rows.map(row => {
                return columnsToExport.reduce((result, column) => {
                    if (column.type === 'object') {
                        const objectElementData = {};
                        column.childrenColumns.forEach(element => {
                            const value = Array.isArray(_get(row, column.key)[element])
                                ? _get(row, column.key)[element].join(',')
                                : _get(row, column.key)[element];
                            objectElementData[element] = `${column.prefix || ''}${value ?? ''}`;
                        });
                        return { ...objectElementData, ...result };
                    }
                    const value = Array.isArray(row[column.key]) ? row[column.key].join(',') : row[column.key];
                    return { ...result, [column.key]: `${column.prefix || ''}${value}` };
                }, {});
            });
            return csvExporter(exportElements?.find(({ id }) => id === currentExportId)?.fileName).generateCsv(valuesForCSV);
        },
        [currentExportId, exportElements, columnsToExport],
    );

    const props = {
        enableRowSelection,
        getRowId,
        onRowSelectionChange: setRowSelection,
        tableInstanceRef,
        columns: columns as MRT_ColumnDef[],
        data: list,
        initialState: { density: 'compact' },
        enableGlobalFilter: true,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: false,
        enableColumnFilterModes: true,
        enableDensityToggle: false,
        enableFilters: true,
        enableRowActions: Boolean(actions),
        renderRowActions: actions ? ({ row }) => getActions(actions, row) : undefined,
        positionActionsColumn: 'last',
        filterFns: {
            betweenDates: () => {
                return true;
            },
            isDate: () => {
                return true;
            },
        },
        muiTablePaginationProps: { rowsPerPageOptions: DataGridOptions.rowsPerPageOptions },
        muiTableBodyCellProps: ({ row }) => {
            if (actions?.edit || actions?.view) {
                return {
                    onDoubleClick: () => (actions.view || actions.edit)(row.original),
                };
            }
        },
        renderTopToolbarCustomActions: ({ table }) => {
            // TODO: should be refactored to use some generic approach for export buttons, e.g. toolbarActions
            return (
                <TopToolbarCustomActions
                    {...{
                        toolbarActions,
                        enableRowSelection,
                        exportElements,
                        rowSelection,
                        rowSelectionData,
                        setCurrentExportId,
                        handleExportRows,
                        setExportColumns,
                        setFetchWithoutPagination,
                        tableRef: table,
                    }}
                />
            );
        },
        state: {
            rowSelection,
            isLoading,
            columnFilters,
            showProgressBars: isLoading,
            showSkeletons: isLoading,
            showGlobalFilter: true,
            ...dataGridPaginationProps.state,
        },
        ..._omit(dataGridPaginationProps, 'state'),
    };

    useEffect(() => {
        if (fetchWithoutPagination && tableInstanceRef && !isLoading) {
            handleExportRows(list);
            setFetchWithoutPagination(false);
        }
    }, [fetchWithoutPagination, handleExportRows, list, isLoading]);

    useEffect(() => {
        setExportColumns(exportElements?.find(({ id }) => id === currentExportId)?.columns);
    }, [exportElements, currentExportId]);

    return (
        <>
            {fetchWithoutPagination ? (
                <>
                    Exporting...
                    <Loader />
                </>
            ) : (
                <MaterialReactTable {...props} />
            )}
        </>
    );
};

function getActions(actions: Actions<unknown>, row: MRT_Row<any>) {
    const result = [];
    (['view', 'edit', 'delete'] as ActionKey[]).forEach(key => {
        if (actions[key]) {
            result.push(<GridActionsCellItem icon={icons[key]} onClick={() => actions[key](row.original)} key={`${key}-action`} label={_camelCase(key)} />);
        }
    });

    return result;
}

export default DataGrid;
