// @ts-expect-error
import dayjs from 'dayjs';
// @ts-expect-error
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectsSelect from '../../../components/ProjectsSelect';
import { useCreatePayoutReportMutation } from '../../../hooks/use-mutations';
import PageWrapper from '../../PageWrapper';
import PayoutReportForm from '../shared/PayoutReportForm';

dayjs.extend(isSameOrBefore);

interface Props {
    onSuccess?: (projectId: string, message?: string) => void;
    onError?: (error) => void;
}

const CreatePayoutReportsPage: FC<Props> = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [projectId, setProjectId] = useState<string>();
    const navigate = useNavigate();

    const mutation = useCreatePayoutReportMutation(onSuccess, onError);

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    function onSuccess(payoutReportId: string) {
        enqueueSnackbar('Payout Report got created', { variant: 'success' });
        navigate(`/payout-reports/edit/${payoutReportId}`);
    }

    return (
        <PageWrapper title="Create Payout Report" breadcrumbs={{ '/dashboard': 'Dashboard', '/payout-reports': 'Payout Reports', '': 'New' }}>
            <ProjectsSelect
                onChange={setProjectId}
                filterFn={projects =>
                    // display only the projects that are started
                    projects.filter(({ projectStartsAt }) => dayjs(projectStartsAt).isSameOrBefore(dayjs()))
                }
            />

            <PayoutReportForm projectId={projectId} mutation={mutation} {...{ onError, onSuccess }} />
        </PageWrapper>
    );
};

export default CreatePayoutReportsPage;
