import { Box, Button, FormControlLabel, ImageList, ImageListItem, MenuItem } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Select, Switch, TextField } from 'formik-mui';
import type { FC } from 'react';
import { useState } from 'react';
import { FileUploader } from '../../../../components/FileUploader';
import Loader from '../../../../components/Loader';
import { useDeleteFileMutation } from '../../../../hooks/use-mutations';
import type { defaultValues } from '../../constants';
import { assetFileCategories } from '../../constants';
import type { AssetMediaFiles, BaseProjectComponentProps } from '../../types';

type TDetailsKeys = keyof typeof defaultValues.details;
type TDetails = Record<TDetailsKeys, any>;
type Props = BaseProjectComponentProps & { projectId: string; currentTab?: number };

const EstateMedia: FC<Props> = ({ projectId, onSuccess, onError, currentTab }) => {
    const [isLoading] = useState(false);
    const { values, setFieldValue } = useFormikContext<{ estateDetails: TDetails; id: string; estateMediaFiles: { [key: string]: AssetMediaFiles } }>();
    const deleteMutation = useDeleteFileMutation(onSuccess, onError);
    return (
        <>
            {isLoading ? <Loader /> : null}
            <Box
                sx={{
                    display: 'flex',
                    p: 2,
                    mb: 2,
                    borderRadius: 1,
                    border: theme => `solid 2px ${theme.palette.grey[500_8]}`,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <FileUploader
                    id={`estateMediaFiles-${currentTab}`}
                    allowedFileTypes={['image/*']}
                    onUploadSucceed={data => {
                        setFieldValue(
                            'estateMediaFiles',
                            data.reduce((acc, { id, ...item }) => ({ ...acc, [id]: { ...item, tempFileId: id } }), values.estateMediaFiles),
                        );
                    }}
                    multiple
                />
            </Box>
            <ImageList sx={{ width: '100%', height: 'auto' }} cols={3} rowHeight="auto">
                {values?.estateMediaFiles
                    ? Object.keys(values?.estateMediaFiles)?.map(key => {
                          const { id, tempFileId, ...item } = values.estateMediaFiles[key];
                          const elementId = tempFileId || id;
                          return (
                              <ImageListItem
                                  key={elementId}
                                  sx={{
                                      mt: 1.5,
                                      ml: 0.5,
                                      borderRadius: 2,
                                      border: theme => `solid 2px ${theme.palette.grey[500_8]}`,
                                  }}
                              >
                                  <img src={item.link} alt={item.fileName} loading="lazy" />
                                  <Box
                                      sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          m: 2,
                                          gap: 2,
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                      }}
                                  >
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                                          <Field
                                              fullWidth
                                              component={Select}
                                              value={values.estateMediaFiles[elementId]?.category || 'NOT_SELECTED'}
                                              name={`estateMediaFiles.${elementId}.category`}
                                              label="Category"
                                              sx={{ width: 100 }}
                                          >
                                              <MenuItem value="NOT_SELECTED" key="NOT_SELECTED" disabled>
                                                  Please Select
                                              </MenuItem>
                                              {assetFileCategories.map(category => (
                                                  <MenuItem value={category} key={category}>
                                                      {category.toLowerCase()}
                                                  </MenuItem>
                                              ))}
                                          </Field>
                                          <Field
                                              value={values.estateMediaFiles[elementId]?.title || ''}
                                              name={`estateMediaFiles.${elementId}.title`}
                                              component={TextField}
                                              label="Title"
                                              type="text"
                                              sx={{ width: 150 }}
                                          />
                                      </Box>
                                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                                          <FormControlLabel
                                              control={
                                                  <Field
                                                      component={Switch}
                                                      name={`estateMediaFiles.${elementId}.isCover`}
                                                      checked={values.estateMediaFiles[elementId]?.isCover || false}
                                                      type="checkbox"
                                                      onChange={e => {
                                                          setFieldValue(`estateMediaFiles.${key}.isCover`, e.target.checked);
                                                      }}
                                                  />
                                              }
                                              label="Is Cover"
                                          />
                                          <Button
                                              variant="contained"
                                              color="warning"
                                              onClick={async () => {
                                                  if (elementId) {
                                                      await deleteMutation.mutateAsync({
                                                          type: tempFileId ? 'TEMPORARY' : 'PROJECT_ESTATE_FILE',
                                                          fileId: elementId,
                                                          projectId,
                                                          estateId: values.id,
                                                      });
                                                      delete values.estateMediaFiles[elementId];
                                                  }
                                              }}
                                          >
                                              Delete
                                          </Button>
                                      </Box>
                                  </Box>
                              </ImageListItem>
                          );
                      })
                    : null}
            </ImageList>
        </>
    );
};

export default EstateMedia;
