import _difference from 'lodash/difference';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';
import type { FC } from 'react';
import Loader from '../../../components/Loader';
import NotFoundEntity from '../../../components/NotFoundEntity';
import type { Stat } from '../../../components/StatsCards';
import StatsCards from '../../../components/StatsCards';
import { useGetPayoutReportStatsQuery } from '../../../hooks/use-queries';

interface Props {
    payoutReportId: string;
}
const Stats: FC<Props> = ({ payoutReportId }) => {
    const { data, isLoading, error } = useGetPayoutReportStatsQuery(payoutReportId);

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <>Error with getting stats data</>;
    }

    if (_isEmpty(data)) {
        return <NotFoundEntity name="Payout Report Stats" />;
    }

    const allStatKeys = Object.keys(data);
    const statsOrder = [
        'projectValue',
        'distributionYield',
        'possibleOrders',
        'eligibleOrders',
        'numberOfParticipants',
        'usersTokens',
        'remainingTokens',
        'distributionAmount',
        'remainingAmount',
    ];
    const unorderedKeys = _difference<string>(allStatKeys, statsOrder);
    const stats: Stat[] = statsOrder
        .concat(unorderedKeys)
        .map(payoutReportStatKey => ({ ...data[payoutReportStatKey], label: _startCase(payoutReportStatKey) }));

    return <StatsCards stats={stats} />;
};

export default Stats;
