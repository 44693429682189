import TabContext from '@mui/lab/TabContext';
import Paper from '@mui/material/Paper';
import _isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import ConfirmDialog from '../../../../components/Dialog';
import Loader from '../../../../components/Loader';
import { useDeleteEstateMutation } from '../../../../hooks/use-mutations';
import { useGetProjectEstatesQuery } from '../../../../hooks/use-queries';
import type { TabListS } from '../types';
import EstatesForm from './EstateForm';
import EstatesTabs from './EstatesTabs';

interface Props {
    projectId: string;
    onSuccess?: (projectId: string, message?: string) => void;
    onError?: (error) => void;
}

const ProjectEstates: FC<Props> = ({ projectId, onSuccess, onError }) => {
    const { isLoading, data: estates = [], error } = useGetProjectEstatesQuery(projectId);
    const estatesMapped = estates.map(estate => ({
        ...estate,
        estateMediaFiles: estate.estateMediaFiles.reduce((acc, file) => ({ ...acc, [file.id]: file }), {}),
    }));
    const deleteMutation = useDeleteEstateMutation(() => {
        setDialogOpened(false);
        setCurrentTab(1);
        onSuccess(projectId, `Estate ${estateIdToDelete} has been deleted`);
    }, onError);

    const [currentTab, setCurrentTab] = useState(1);
    const [dialogOpened, setDialogOpened] = useState(false);
    const [estateIdToDelete, setEstateIdToDelete] = useState<string>(undefined);

    const [tabList, setTabList] = useState<TabListS>([
        {
            // ids will be fetched from the server. the ones created on client will not have an id until clicking save.
            id: undefined,
            value: 1,
        },
    ]);

    useEffect(() => {
        if (!_isEmpty(estates)) {
            const tabListArray = estatesMapped.map((element, i) => ({ id: element.id, value: i + 1 }));
            setTabList(tabListArray);
        }
    }, [estates.length, isLoading, error, estates]);

    function addTab() {
        const newTab = (tabList.length || 0) + 1;
        setTabList([
            ...tabList,
            {
                id: undefined,
                value: newTab,
            },
        ]);
        setCurrentTab(newTab);
    }

    function openDeleteDialog(id?: string) {
        setDialogOpened(true);
        setEstateIdToDelete(id);
    }

    function removeTab() {
        const updatedTabList = tabList.reduce((prev: TabListS, current: TabListS[0], i) => {
            if (current.value > currentTab) {
                i -= 1;
            }
            if (currentTab !== current.value) {
                prev.push({
                    id: current.id,
                    value: i + 1,
                });
            }
            return prev;
        }, []);
        setTabList(updatedTabList);
        setCurrentTab(1);
    }

    const deleteEstate = () => {
        if (estateIdToDelete) {
            deleteMutation.mutate({ id: estateIdToDelete, projectId });
        }
        if (!estateIdToDelete) {
            setDialogOpened(false);
            onSuccess(projectId, 'The selected estate has been deleted and we refreshed the estates got refreshed');
            removeTab();
        }
    };
    if (!isLoading && error) {
        onError(error);
    }
    // means the last added estate is not saved to the server yet.
    return isLoading ? (
        <Loader />
    ) : (
        <>
            <Paper>
                <TabContext value={currentTab.toString()}>
                    <EstatesTabs {...{ currentTab, addTab, openDeleteDialog, setCurrentTab, tabList }} />
                    <EstatesForm
                        {...{ currentTab, projectId, onSuccess, onError, openDeleteDialog }}
                        estate={estatesMapped[currentTab - 1] || {}}
                        isDeleteMutationLoading={deleteMutation.isLoading}
                    />
                </TabContext>
            </Paper>
            <ConfirmDialog
                content="Are you sure you want to delete this estate?"
                title="Estate deletion"
                isOpened={dialogOpened}
                handleClose={() => setDialogOpened(false)}
                handleConfirm={() => {
                    deleteEstate();
                }}
            />
        </>
    );
};

export default ProjectEstates;
