import type { LinkProps } from '@mui/material/Link';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

interface LinkRouterProps extends LinkProps {
    to: string;
}
const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

export default LinkRouter;
