import AddIcon from '@mui/icons-material/Add';
import OpenIcon from '@mui/icons-material/Visibility';
import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import type { GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import type { GetAdminUsersListResponse } from '../../../../../../schemas';
import Page from '../../../../components/Page';
import { useGetAdminUserList } from '../../../../hooks/use-queries';

export default function AdminUsersListPage() {
    const navigate = useNavigate();
    const { isLoading, data: users = [], error } = useGetAdminUserList();

    function openUser(userId: string) {
        return navigate(`/settings/admin-user/${userId}`);
    }
    const columns: GridColumns = [
        { field: 'uid', headerName: 'User Uid', minWidth: 200 },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 100,
            type: 'date',
            valueGetter: (params: GridRenderCellParams<string, GetAdminUsersListResponse>) => new Date(params.value),
        },
        {
            field: 'actions',
            type: 'actions',
            width: 100,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<OpenIcon />}
                    onClick={() => {
                        openUser(params.id as string);
                    }}
                    key="view-action"
                    label="View"
                />,
            ],
        },
    ];

    return (
        <Page title="Application Users">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Admin Users
                        </Typography>
                        <Button variant="contained" component={RouterLink} to="create" startIcon={<AddIcon />}>
                            Add
                        </Button>
                    </Stack>
                    <Card>
                        <DataGrid
                            rows={users}
                            columns={columns}
                            error={error}
                            autoHeight
                            loading={isLoading}
                            checkboxSelection={false}
                            onRowDoubleClick={(params: GridRowParams) => {
                                openUser(params.id as string);
                            }}
                        />
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}
