import { Button } from '@mui/material';
import type { FC } from 'react';
import { useState } from 'react';
import ConfirmDialog from './Dialog';

interface Props {
    label: string;
    disabled?: boolean;
    dialogTitle?: string;
    dialogContent: string;
    onConfirm: () => void;
}

const ActionButton: FC<Props> = ({ label, disabled, onConfirm, dialogContent, dialogTitle = 'Please confirm' }) => {
    const [showDialog, setShowDialog] = useState(false);
    return (
        <>
            <Button
                variant="contained"
                onClick={() => {
                    setShowDialog(true);
                }}
                disabled={disabled}
            >
                {label}
            </Button>
            <ConfirmDialog
                content={dialogContent}
                title={dialogTitle}
                isOpened={showDialog}
                handleClose={() => setShowDialog(false)}
                handleConfirm={() => {
                    onConfirm();
                    setShowDialog(false);
                }}
            />
        </>
    );
};

export default ActionButton;
