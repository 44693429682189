import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';

type Variant = 'normal' | 'small';

const sizes: Record<Variant, { value: any; title: any }> = {
    normal: {
        value: { variant: 'h3' },
        title: { variant: 'body2' },
    },
    small: {
        value: { variant: 'h5' },
        title: { variant: 'body2' },
    },
};

const DataCard: FC<{ title: string; value: string; cardWidth?: number; variant?: Variant }> = ({ title, value, cardWidth = 200, variant = 'normal' }) => {
    return (
        <Card sx={{ width: cardWidth }}>
            <CardContent>
                <Typography align="center" color="text.secondary" gutterBottom {...sizes[variant].title}>
                    {title}
                </Typography>
                <Typography align="center" component="div" {...sizes[variant].value}>
                    {value || '-'}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default DataCard;
