import type { FunctionComponent } from 'react';
import StatusChip from '../StatusChip';
import type { PaymentRequestStatus } from '.prisma/client';

interface PaymentRequestStatusChipProps {
    status: PaymentRequestStatus;
}

const statusColor = {
    OPEN: 'info',
    REJECTED: 'warning',
    PAID: 'success',
};

const PaymentRequestStatusChip: FunctionComponent<PaymentRequestStatusChipProps> = ({ status }) => {
    return <StatusChip label={status} size="small" color={statusColor[status] || 'default'} />;
};

export default PaymentRequestStatusChip;
