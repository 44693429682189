import { Box, Card, Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, Page } from '../../../../components';
import Loader from '../../../../components/Loader';
import NotFoundEntity from '../../../../components/NotFoundEntity';
import { useGetAdminUser } from '../../../../hooks/use-queries';
import AdminUserForm from '../shared/Form';

const AdminUserEditPage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { userId } = useParams();
    const { isLoading, data: user, error } = useGetAdminUser(userId);

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    function onSuccess() {
        enqueueSnackbar('User is updated', { variant: 'success' });
    }

    if (!isLoading && error) {
        onError(error);
    }

    return (
        <Page title="User Details">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4" gutterBottom>
                        Edit User
                    </Typography>

                    <Breadcrumbs items={{ '/settings': 'Settings', '/settings/admin-user': 'Users', '': isLoading ? '...' : user?.uid }} />

                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Card>{!user ? <NotFoundEntity name="User" /> : <AdminUserForm user={user} onError={onError} onSuccess={onSuccess} />}</Card>
                    )}
                </Box>
            </Container>
        </Page>
    );
};

export default AdminUserEditPage;
