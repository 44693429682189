import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, MenuItem, Stack } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import type { FC } from 'react';
import DetailsItem from '../../../../components/DetailsItem';
import Loader from '../../../../components/Loader';
import { useSaveAdminUserMutation } from '../../../../hooks/use-mutations';

interface AdminUser {
    uid: string;
    email: string;
}

interface Props {
    user?: AdminUser;
    onSuccess: (userId: string) => void;
    onError: (error) => void;
}

const roleOptions = [{ label: 'Admin', value: 'admin' }];
const initialValues = {
    email: '',
    role: '',
};

const AdminUserForm: FC<Props> = ({ user, onError, onSuccess }) => {
    const mutation = useSaveAdminUserMutation(onSuccess, onError);

    const onSubmit = (values, { setSubmitting }) => {
        mutation.mutate({
            ...values,
            uid: user?.uid,
        });
        setSubmitting(false);
    };

    return (
        <Formik initialValues={user || initialValues} onSubmit={onSubmit}>
            {({ isSubmitting, submitForm }) => (
                <>
                    {isSubmitting ? <Loader shouldOverride /> : null}

                    <Form>
                        <Card>
                            <Box sx={{ px: 2 }}>
                                <Grid container spacing={2}>
                                    {user ? (
                                        <Grid item xs={12}>
                                            <DetailsItem title="Firebase Uid" value={user.uid} />
                                            <DetailsItem title="Email" value={user.email} />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} sm={4} mt={2}>
                                            <Field fullWidth component={TextField} name="email" label="Email" />
                                        </Grid>
                                    )}
                                    <Field component={TextField} name="uid" type="hidden" />
                                    <Grid item xs={12} sm={4} mt={2}>
                                        <Field component={Select} name="role" label="Role" sx={{ width: 100 }}>
                                            {roleOptions.map(({ label, value }) => (
                                                <MenuItem value={value} key={value}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Stack sx={{ p: 2 }} direction="row" justifyContent="end">
                                <LoadingButton variant="contained" color="primary" loading={isSubmitting} onClick={submitForm}>
                                    {user ? 'Update' : 'Create'}
                                </LoadingButton>
                            </Stack>
                        </Card>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export default AdminUserForm;
