import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-mui';
import type { FC } from 'react';
import type { ISetting } from '../../../../domain-entities/models/settings';
import { useUpsertSettingMutation } from '../../hooks/use-mutations';
import Loader from '../Loader';

interface Props {
    onSuccess: () => void;
    onError: (error) => void;
    settings?: Record<string, ISetting> | null;
}

const PlatformSettingsForm: FC<Props> = ({ onError, onSuccess, settings }) => {
    const mutation = useUpsertSettingMutation(onSuccess, onError);

    const initialValues = {
        hideProjectsProgress: false,
        ...(settings && Object.values(settings).reduce((result, item) => ({ ...result, [item.key]: item.value }), {})),
    };

    const onSubmit = (values, { setSubmitting }) => {
        Object.keys(values).forEach(key => {
            mutation.mutate({ key, value: values[key], format: 'BOOLEAN', category: 'PLATFORM' });
        });
        setSubmitting(false);
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting, submitForm }) => (
                <>
                    {isSubmitting ? <Loader shouldOverride /> : null}

                    <Form>
                        <Card>
                            <Box sx={{ px: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} mt={2}>
                                        <span>Hide Projects Progress</span>
                                        <Field component={Switch} name="hideProjectsProgress" type="checkbox" label="Hide Projects Progress" />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Stack sx={{ p: 2 }} direction="row" justifyContent="end">
                                <LoadingButton variant="contained" color="primary" loading={isSubmitting} onClick={submitForm}>
                                    Update
                                </LoadingButton>
                            </Stack>
                        </Card>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export default PlatformSettingsForm;
