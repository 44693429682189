import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';

interface Props {
    quiz: Record<string, string>;
    score: number;
    isPassed: boolean;
}

const StepQuizDetails: FC<Props> = ({ score, isPassed }) => {
    if (typeof score === 'undefined') {
        return null;
    }

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="span" variant="caption">
                    Quiz Results
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component="span" variant="body2">
                    Score: {score}
                    <br />
                    Is successfully passed:{' '}
                    {isPassed ? (
                        <Typography component="b" color="primary">
                            yes
                        </Typography>
                    ) : (
                        <Typography component="b" color="warning">
                            no
                        </Typography>
                    )}
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};

export default StepQuizDetails;
