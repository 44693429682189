import _round from 'lodash/round';
import _toNumber from 'lodash/toNumber';
import type { IPayoutReport } from '../../domain-entities/models/payout-report';

export function calcTotalDividendAmount(payoutReport: IPayoutReport): number {
    const costsKeys: (keyof IPayoutReport)[] = [
        'rentalLossRisk',
        'otherCosts',
        'securityTrustee',
        'icServiceFees',
        'camPmFees',
        'invPmFees',
        'maintenanceCosts',
        'loanPayments',
        'loanInterest',
    ];

    const runningCosts = costsKeys.reduce((total, key) => total + _toNumber(payoutReport[key]), 0);
    return _round(payoutReport.netRevenue - runningCosts, 2);
}
