import { currency } from '@condo/formatters';
import dayjs from 'dayjs';
import type { MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import MuiDateRangePicker from '../MuiDateRangePicker';
import type { GridDataType } from '../types';

export type Columns<T = any> = (MRT_ColumnDef<T> & { dataType?: GridDataType })[];

export function useGridColumns<T>(columns: Columns<T>) {
    const enrichedColumns = columns.map(column => {
        if (column.enableColumnFilter === false) {
            return column;
        }

        const { accessorKey, id, dataType } = column;
        const name = (accessorKey || id) as string;

        switch (dataType) {
            case 'date-time':
            case 'date': {
                Object.assign(column, {
                    columnFilterModeOptions: ['betweenDates', 'isDate'],
                    filterFn: 'betweenDates',
                    sortingFn: 'datetime',
                    size: column.size || 120,
                    Filter: ({ header }) => {
                        return (
                            <MuiDateRangePicker
                                onSelect={(from, to) => {
                                    header.column.setFilterValue({ gte: from, lt: dayjs(to).add(1, 'd').startOf('day').toDate() });
                                }}
                            />
                        );
                    },
                    accessorFn: row => (row[name] ? new Date(row[name])[dataType === 'date' ? 'toLocaleDateString' : 'toLocaleString']() : '-'),
                } as Partial<MRT_ColumnDef>);
                break;
            }

            case 'currency':
            case 'number':
                column.filterFn = 'equals';
                column.columnFilterModeOptions = ['equals', 'betweenInclusive', 'greaterThanOrEqualTo', 'lessThanOrEqualTo', 'greaterThan', 'lessThan'];
                if (dataType === 'currency') {
                    column.accessorFn = row => currency(row[name]);
                }
                break;

            default: {
                // Handles the case of string / select from the list filter, as there's no other way to correctly distinguish them
                if (!column.filterSelectOptions) {
                    column.filterFn = 'contains';
                }
                column.columnFilterModeOptions = [];
                break;
            }
        }

        return column;
    });
    return useMemo<typeof columns>(() => enrichedColumns, []);
}
