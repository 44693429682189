import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import type { FC, ReactComponentElement } from 'react';
import { useState } from 'react';

export type TabsConfig = Record<string, { label: string; disabled?: boolean; component: () => ReactComponentElement<any> }>;

interface Props {
    tabs: TabsConfig;
}

const Tabs: FC<Props> = ({ tabs }) => {
    const keys = Object.keys(tabs);
    const [tab, setTab] = useState(keys[0]);

    function onTabChange(_, newTab: string) {
        setTab(newTab);
    }

    return (
        <TabContext value={tab}>
            <TabList onChange={onTabChange} sx={{ mt: 2 }}>
                {keys.map(value => (
                    <Tab value={value} key={`tab-${value}`} label={tabs[value].label} disabled={tabs[value].disabled} />
                ))}
            </TabList>

            {keys.map(value => (
                <TabPanel key={`panel-${value}`} value={value} sx={{ px: 0 }}>
                    {tabs[value].component()}
                </TabPanel>
            ))}
        </TabContext>
    );
};

export default Tabs;
