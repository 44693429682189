import { bpsToPercent } from '@condo/formatters';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import { useState } from 'react';
import type { TabsComponents, WarnIcon } from '../../../../components/BrandTabs';
import BrandTabs from '../../../../components/BrandTabs';
import { brands, projectContentSections } from '../../constants';
import type { TDetails } from '../ProjectDetailsForm';
import { render } from './index';

interface BaseProps {
    currency: string;
}
export const PortfolioLocationSection: FC = () => {
    return (
        <>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="h5" mt={2}>
                    Portfolio Location
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                {render('locationDescription', 'About Portfolio Locations')}
            </Grid>
        </>
    );
};

export const ChancesRisksSection: FC = () => {
    return (
        <>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="h5" mt={2}>
                    Chances & Risks
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                {render('chancesList', 'Chances')}
            </Grid>
            <Grid item xs={12} sm={6}>
                {render('risksList', 'Risks')}
            </Grid>
        </>
    );
};
export const FinancialDataSection: FC<BaseProps> = ({ currency }) => {
    return (
        <>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="h5" mt={2}>
                    Financial Data
                </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
                {render('financialDataTransferTax', 'Transfer Tax', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('financialDataNotaryFees', 'Notary Fee', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('financialDataBrokerFees', 'Brokerage Fee', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('financialDataStructureCosts', 'Structure Costs', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('financialDataPrefinancingCosts', 'Pre-Financing Costs', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('financialDataLiquidityReserve', 'Liquidity Reserve', { adornment: currency })}
            </Grid>
        </>
    );
};

export const BrandedDetailsSection: FC<{ type: 'introVideoLink'; title: string }> = ({ type, title }) => {
    const { values } = useFormikContext<TDetails>();
    const tabsComponents = {} as TabsComponents;
    const warnIcon = {} as WarnIcon;
    brands.forEach(brand => {
        tabsComponents[brand] = render(`${type}_${brand}`, brand);
        warnIcon[brand] = Boolean(values[`${type}_${brand}`]?.value);
    });

    return (
        <>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="h5" mt={2}>
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <BrandTabs tabsComponents={tabsComponents} warnIcon={warnIcon} />
            </Grid>
        </>
    );
};
export const MainDetailsSection: FC<BaseProps> = ({ currency }) => {
    const { values } = useFormikContext<TDetails>();
    return (
        <>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="h5" mt={2}>
                    Main Details
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {render('mainTitle', 'Main Title')}
            </Grid>

            <Grid item xs={12}>
                {render('location', 'Location')}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('estatesTotalArea', 'Total Renting Area')}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('rentedPercentage', 'Rented Percentage', { adornment: '%' })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('bankFinancingAmount', 'Bank Financing Amount', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('expectedReturnBps', 'Expected Return', {
                    adornment: 'BPS',
                    helperText: `Percentage example: ${bpsToPercent(values.expectedReturnBps.value)}`,
                })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('firstPayout', 'First Payout')}
            </Grid>
        </>
    );
};

export const FactsSection: FC = () => {
    return (
        <>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="h5" mt={2}>
                    Facts
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                {render('factsInvestment', 'About Investment')}
            </Grid>
            <Grid item xs={12} sm={6}>
                {render('factsProperty', 'About Property')}
            </Grid>
            <Grid item xs={12} sm={6}>
                {render('factsRenting', 'About Renting')}
            </Grid>
            <Grid item xs={12} sm={6}>
                {render('factsLocation', 'About Location')}
            </Grid>
            <Grid item xs={12} sm={6}>
                {render('factsRenovation', 'About Renovation')}
            </Grid>
        </>
    );
};

export const ForecastTab: FC<{ variant: '1year' | '10years' } & BaseProps> = ({ variant, currency }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                {render(`forecastNetRevenue_${variant}`, 'Net Revenue', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render(`forecastOtherCosts_${variant}`, 'Other Costs', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render(`forecastSecurityTrustee_${variant}`, 'Security Trustee', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render(`forecastICServiceFees_${variant}`, 'IC Service Fees', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render(`forecastINVPmFees_${variant}`, 'INV PM Fees', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render(`forecastCAMPmFees_${variant}`, 'CAM PM Fees', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render(`forecastRentalLossRisk_${variant}`, 'Rental Loss Risk', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render(`forecastMaintenanceCosts_${variant}`, 'Maintenance Costs', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render(`forecastLoanInterest_${variant}`, 'Loan Interest', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render(`forecastLoanPayments_${variant}`, 'Loan Payments', { adornment: currency })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render(`forecastValueAppreciationBps_${variant}`, 'Value Appreciation', { adornment: 'BPS' })}
            </Grid>
        </Grid>
    );
};
export const ForecastSection: FC<BaseProps> = ({ currency }) => {
    const [tab, setTab] = useState('1year');
    return (
        <>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="h5" mt={2}>
                    Forecast
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Tabs
                    value={tab}
                    onChange={(_, value) => {
                        setTab(value);
                    }}
                    aria-label="basic tabs example"
                >
                    <Tab label="1 Year" value="1year" />
                    <Tab label="10 Years" value="10years" />
                </Tabs>
            </Grid>
            <TabContext value={tab}>
                <TabPanel value="1year" sx={{ width: '100%' }}>
                    <ForecastTab variant="1year" currency={currency} />
                </TabPanel>
                <TabPanel value="10years" sx={{ width: '100%' }}>
                    <ForecastTab variant="10years" currency={currency} />
                </TabPanel>
            </TabContext>
        </>
    );
};

export const CostsAndFeesSection: FC = () => {
    return (
        <>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="h5" mt={2}>
                    Costs & Fees
                </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                {render('costsInvestableCommissionBps', 'Investable Commission', { adornment: 'BPS' })}
            </Grid>
            <Grid item xs={12} sm={3}>
                {render('costsDuration', 'Duration', { adornment: 'Years' })}
            </Grid>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="caption" mt={2}>
                    One time
                </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                {render('costsDistributionBps_onetime', 'Distribution costs', { adornment: 'BPS' })}
            </Grid>
            <Grid item xs={12} sm={3}>
                {render('costsCommissionsBps_onetime', 'Thereof commissions', { adornment: 'BPS' })}
            </Grid>
            <Grid item xs={12} sm={3}>
                {render('costsFinancialInstrumentsBps_onetime', 'Financial instrument cost', { adornment: 'BPS' })}
            </Grid>
            <Grid item xs={12} sm={3}>
                {render('costsTotalBps_onetime', 'Total costs', { adornment: 'BPS' })}
            </Grid>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="caption" mt={2}>
                    Running
                </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                {render('costsDistributionBps_running', 'Distribution costs', { adornment: 'BPS' })}
            </Grid>
            <Grid item xs={12} sm={3}>
                {render('costsCommissionsBps_running', 'Thereof commissions', { adornment: 'BPS' })}
            </Grid>
            <Grid item xs={12} sm={3}>
                {render('costsFinancialInstrumentsBps_running', 'Financial instrument costs', { adornment: 'BPS' })}
            </Grid>
            <Grid item xs={12} sm={3}>
                {render('costsTotalBps_running', 'Total costs', { adornment: 'BPS' })}
            </Grid>
        </>
    );
};
export const LegalSection: FC = () => {
    return (
        <>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="h5" mt={2}>
                    Legal Notes
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                {render('legalNotes', 'Details about legal entity')}
            </Grid>
            <Grid item xs={12} sm={12}>
                {render('lockedSections', 'Blocked sections for non-registered users', undefined, projectContentSections)}
            </Grid>
        </>
    );
};
