import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Form, FormikProvider, useFormik } from 'formik';
import _groupBy from 'lodash/groupBy';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { Fragment } from 'react';
import type { IProjectDocument } from '../../../../../../domain-entities/models/project';
import type { TabsComponents, WarnIcon } from '../../../../components/BrandTabs';
import BrandTabs from '../../../../components/BrandTabs';
import NotFoundEntity from '../../../../components/NotFoundEntity';
import { buildDocumentsSchema } from '../../../../helpers/formSchemas';
import { validateForm } from '../../../../helpers/formValidation';
import { useSaveProjectDocuments } from '../../../../hooks/use-mutations';
import DocumentItem from './DocumentItem';
import type { FormValues } from './InputForm';
import InputForm from './InputForm';

interface Props {
    projectId: string;
    documents: Record<string, IProjectDocument>;
}

const ProjectDocuments: FC<Props> = ({ documents, projectId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const mutation = useSaveProjectDocuments(
        () => {
            enqueueSnackbar('Documents got saved', { variant: 'success' });
        },
        error => {
            enqueueSnackbar(`Error while uploading project documents: ${error}`, { variant: 'error' });
        },
    );
    const formik = useFormik<FormValues>({
        onSubmit(values, { setSubmitting }) {
            mutation.mutate({ projectId, ...values } as { documents: Required<Record<string, IProjectDocument>> });
            setSubmitting(false);
        },
        enableReinitialize: true,
        initialValues: { documents, brand: 'IS24', category: '' },
        validate: async values => {
            const schema = buildDocumentsSchema(values.documents);
            return validateForm<typeof schema>(values, schema);
        },
    });

    const { values, isSubmitting, handleSubmit } = formik;

    const hasDocs = !_isEmpty(values?.documents);
    const groupByCategory = _groupBy(
        Object.keys(values?.documents)?.map((key, index) => ({ ...values?.documents[key], index })),
        'category',
    );

    const mappedDocs = Object.keys(groupByCategory).reduce((list, category) => {
        list[category] = { ..._keyBy(groupByCategory[category], 'brand') };
        return list;
    }, groupByCategory);

    return (
        <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
                <InputForm />
                <Paper>
                    {hasDocs ? (
                        <>
                            <Stack gap={2} sx={{ p: 2 }} divider={<Divider orientation="horizontal" flexItem />}>
                                {Object.keys(mappedDocs)
                                    .sort()
                                    .map(category => {
                                        const brandDocs = mappedDocs[category];
                                        const tabs = Object.keys(brandDocs).reduce((list, brand) => {
                                            return {
                                                ...list,
                                                [brand]: (
                                                    <DocumentItem
                                                        key={`document_${brandDocs[brand].link}`}
                                                        document={brandDocs[brand]}
                                                        projectId={projectId}
                                                        documentId={brandDocs[brand].id || brandDocs[brand].tempFileId}
                                                    />
                                                ),
                                            };
                                        }, {} as TabsComponents);

                                        const warnIconsState = Object.keys(brandDocs).reduce(
                                            (list, brand) => ({
                                                ...list,
                                                [brand]: Boolean(brandDocs[brand]),
                                            }),
                                            {} as WarnIcon,
                                        );
                                        return (
                                            <Fragment key={`document_${category}`}>
                                                <Typography variant="h6">{category}</Typography>
                                                <BrandTabs tabsComponents={tabs} warnIcon={warnIconsState} />
                                            </Fragment>
                                        );
                                    })}
                            </Stack>
                            <Box justifyContent="right" sx={{ p: 2, display: 'flex', pt: 4 }}>
                                <LoadingButton type="submit" variant="contained" color="primary" loading={isSubmitting || mutation.isLoading}>
                                    Save
                                </LoadingButton>
                            </Box>
                        </>
                    ) : (
                        <NotFoundEntity name="Project Documents" plain />
                    )}
                </Paper>
            </Form>
        </FormikProvider>
    );
};

export default ProjectDocuments;
