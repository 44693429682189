import DateRangeIcon from '@mui/icons-material/DateRange';
import { IconButton, InputAdornment, Popover, TextField } from '@mui/material';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const dateFormat = 'DD/MM/YYYY';

interface Props {
    onSelect: (fromDate: Date, toDate: Date) => void;
}

const MuiDateRangePicker: FC<Props> = ({ onSelect }) => {
    const anchorRef = useRef(null);
    const [displayCalendar, setDisplayCalendar] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState('');
    const [fromDate, setFromDate] = useState<Date | null>(new Date());
    const [toDate, setToDate] = useState<Date | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const onAdornmentClick = () => {
        setDisplayCalendar(true);
        setAnchorEl(anchorRef.current);
    };
    const onPopoverClose = () => {
        setDisplayCalendar(false);
        setAnchorEl(null);
    };

    return (
        <div>
            <TextField
                fullWidth
                value={inputValue}
                variant="standard"
                size="small"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={onAdornmentClick} ref={anchorRef}>
                                <DateRangeIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Popover
                open={displayCalendar}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={onPopoverClose}
            >
                <div>
                    <DateRangePicker
                        ranges={[
                            {
                                startDate: fromDate,
                                endDate: toDate,
                                key: 'selection',
                            },
                        ]}
                        onChange={selection => {
                            const result = onSelectDateRanges(selection);
                            setFromDate(result.fromDate);
                            setToDate(result.toDate);
                            setInputValue(result.inputValue);

                            if (result.fromDate && result.toDate) {
                                onSelect(result.fromDate, result.toDate);
                            }
                        }}
                        editableDateInputs
                        staticRanges={undefined}
                        inputRanges={undefined}
                        maxDate={new Date()}
                        showMonthAndYearPickers
                        moveRangeOnFirstSelection={false}
                        showDateDisplay={false}
                        scroll={{ enabled: true }}
                    />
                </div>
            </Popover>
        </div>
    );
};

function onSelectDateRanges({ selection }): { fromDate: Date | null; toDate?: Date | null; inputValue: string } {
    const { startDate, endDate } = selection;

    let fromDate = dayjs(startDate);
    fromDate = fromDate.isValid() ? fromDate.startOf('date').toDate() : null;

    let toDate = dayjs(endDate);
    toDate = toDate.isValid() ? toDate.endOf('date').toDate() : null;

    let inputValue = '';
    if (fromDate) {
        inputValue += dayjs(fromDate).format(dateFormat);
    }
    if (toDate) {
        inputValue += ` - ${dayjs(toDate).format(dateFormat)}`;
    }

    return { fromDate, toDate, inputValue };
}

export default MuiDateRangePicker;
