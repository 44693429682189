import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import type { FC } from 'react';

interface Props {
    title?: string;
    content: string;
    isOpened: boolean;
    handleClose: () => void;
    handleConfirm: () => void | Promise<void>;
}

const ConfirmDialog: FC<Props> = ({ isOpened, content, title, handleClose, handleConfirm }) => {
    return (
        <Dialog open={isOpened} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            {title ? <DialogTitle id="alert-dialog-title">{title}</DialogTitle> : null}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>Yes</Button>
                <Button onClick={handleClose}>No</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
