import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import type { MRT_RowSelectionState, MRT_TableInstance, MaterialReactTableProps } from 'material-react-table';
import type { FC } from 'react';
import ExportButtons from './ExportButtons';
import RowSelectionActionButtons from './RowSelectionActionButtons';
import type { ExportColumns, ExportElements, RowSelectionData, ToolbarAction } from './types';

interface Props {
    enableRowSelection?: MaterialReactTableProps['enableRowSelection'];
    rowSelection?: MRT_RowSelectionState;
    setExportColumns?: (data: ExportColumns) => void;
    setFetchWithoutPagination?: (value: boolean) => void;
    toolbarActions?: ToolbarAction<any>[];

    tableRef?: MRT_TableInstance<any>;
    rowSelectionData?: RowSelectionData;
    exportElements: ExportElements;
    setCurrentExportId: (id: 'tax-data' | 'payment-data' | 'payment-requests-sepa-xml-data' | 'payment-requests') => void;
}
const TopToolbarCustomActions: FC<Props> = ({
    enableRowSelection,
    rowSelection,
    setFetchWithoutPagination,
    setExportColumns,
    rowSelectionData,
    toolbarActions,
    tableRef,
    exportElements,
    setCurrentExportId,
}) => {
    return (
        <Stack sx={{ p: 2, width: '100%' }} gap={2} direction="row" justifyContent="end">
            {toolbarActions?.map(({ title, onClick, icon }) => {
                return (
                    <Button
                        key={title}
                        color="secondary"
                        size="small"
                        onClick={() => {
                            const selectedModel = tableRef.getSelectedRowModel();
                            const allModel = tableRef.getRowModel();
                            const selected = selectedModel.rows.map(row => row.original);
                            const rows = allModel.rows.map(row => row.original);
                            onClick({ rows, selected, table: tableRef });
                        }}
                        variant="outlined"
                        startIcon={icon}
                    >
                        {title}
                    </Button>
                );
            })}
            <RowSelectionActionButtons
                {...{
                    enableRowSelection,
                    rowSelection,
                    setFetchWithoutPagination,
                    setExportColumns,
                    rowSelectionData,
                    tableRef,
                }}
            />
            <ExportButtons
                {...{
                    exportElements,
                    setCurrentExportId,
                    setExportColumns,
                    tableRef,
                    setFetchWithoutPagination,
                    rowSelection,
                }}
            />
        </Stack>
    );
};

export default TopToolbarCustomActions;
