import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack } from '@mui/material';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { TextField } from 'formik-mui';
import type { FC } from 'react';
import type { Brands } from '../../../../../constants';
import type { ISetting } from '../../../../../domain-entities/models/settings';
import Loader from '../../../components/Loader';
import { useUpsertSettingMutation } from '../../../hooks/use-mutations';
import { brands } from '../../project/constants';
import type { Brand } from '../../project/types';

interface Props {
    brand: Brands;
    onSuccess: () => void;
    onError: (error) => void;
    settings: Record<string, ISetting>;
}

const AddSupportEmailForm: FC<Props> = ({ settings, brand, onError, onSuccess }) => {
    const mutation = useUpsertSettingMutation(onSuccess, onError);
    const defaultValues = brands.reduce((list, brand) => ({ ...list, [brand]: '' }), {} as { [key in Brand]: string });
    const formik = useFormik({
        onSubmit(values, { setSubmitting }) {
            const value = { ...settings?.supportEmail?.value, [brand]: values[brand] };
            mutation.mutate({
                key: 'supportEmail',
                value: JSON.stringify(value),
                format: 'JSON',
                category: 'PLATFORM',
            });
            setSubmitting(false);
        },
        initialValues: { ...defaultValues, ...settings?.supportEmail?.value },
        enableReinitialize: true,
    });

    const { isSubmitting, handleSubmit } = formik;

    return (
        <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
                <>
                    {isSubmitting ? <Loader shouldOverride /> : null}

                    <Card>
                        <Box sx={{ px: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} mt={2}>
                                    <Field fullWidth component={TextField} name={brand} label="Support Email" />
                                </Grid>
                            </Grid>
                        </Box>
                        <Stack sx={{ p: 2 }} direction="row" justifyContent="end">
                            <LoadingButton variant="contained" color="primary" loading={isSubmitting} type="submit">
                                Save
                            </LoadingButton>
                        </Stack>
                    </Card>
                </>
            </Form>
        </FormikProvider>
    );
};

export default AddSupportEmailForm;
