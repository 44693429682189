import type { Restrictions } from '@uppy/core';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { useMemo } from 'react';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { useDI } from './use-di';

export const useFileUploader = (id, { multiple, restrictions }: { multiple: boolean; restrictions?: Restrictions }) => {
    const { authService, apiClient } = useDI();
    const fileUploader = useMemo(
        () =>
            new Uppy({ id, autoProceed: true, debug: true, allowMultipleUploadBatches: multiple, restrictions }).use(XHRUpload, {
                endpoint: `${apiClient.httpClient.getUri()}/api/temp-file`,
                fieldName: `files-${id}`,
                formData: true,
                limit: 2,
                headers: {
                    Authorization: `Bearer ${authService.token}`,
                },
            }),
        [authService, apiClient, id, multiple, restrictions],
    );
    return fileUploader;
};
