import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import type { OrderStatus, OrderType } from '../../../../domain-entities/models/order';
import { Breadcrumbs } from '../../components';
import Loader from '../../components/Loader';
import NotFoundEntity from '../../components/NotFoundEntity';
import Page from '../../components/Page';
import BankTransactionDetails from '../../components/bankTransaction/BankTransactionDetails';
import ManualMatchForm from '../../components/bankTransaction/ManualMatchForm';
import OrderDetailsBox from '../../components/order/OrderDetailsBox';
import { useGetBankTransaction } from '../../hooks/use-queries';

interface Props {
    title: string;
    status?: OrderStatus | OrderStatus[];
    type?: OrderType;
}

const PageContainer: FC<PropsWithChildren<{ title: string; id: string }>> = ({ children, title, id }) => (
    <Page title={title}>
        <Container maxWidth="xl">
            <Box sx={{ pb: 5, mt: 5 }}>
                <Typography variant="h4" gutterBottom>
                    {title}
                </Typography>
                <Breadcrumbs
                    items={{
                        '/dashboard': 'Dashboard',
                        '/bank-transactions': 'Bank Transactions',
                        '': id,
                    }}
                />
                <Box>{children}</Box>
            </Box>
        </Container>
    </Page>
);

const BankTransactionPage: FC<Props> = ({ title }) => {
    const { id } = useParams();
    const { isLoading, data } = useGetBankTransaction(id);

    if (isLoading) {
        return (
            <PageContainer title={title} id={id}>
                <Loader />
            </PageContainer>
        );
    }

    if (!data) {
        return (
            <PageContainer title={title} id={id}>
                <NotFoundEntity name="Bank Transaction" />
            </PageContainer>
        );
    }

    return (
        <PageContainer title={title} id={id}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <BankTransactionDetails data={data} />
                </Grid>
                {data.status === 'PENDING' && (
                    <Grid item xs={12} md={6}>
                        <ManualMatchForm bankTransactionId={id} />
                    </Grid>
                )}

                <Grid item xs={12} md={6}>
                    {data.order ? <OrderDetailsBox order={data.order} /> : null}
                </Grid>
            </Grid>
        </PageContainer>
    );
};
export default BankTransactionPage;
