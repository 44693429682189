import { currency } from '@condo/formatters';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import EuroIcon from '@mui/icons-material/Euro';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MailIcon from '@mui/icons-material/Mail';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import ShopIcon from '@mui/icons-material/Shop';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SmsIcon from '@mui/icons-material/Sms';
import Unsubscribe from '@mui/icons-material/Unsubscribe';
import Typography from '@mui/material/Typography';
import type { FC, PropsWithChildren } from 'React';
import type { UserHistoryEvent } from '../../../infrastructure/api/history/types';
import { ViewLink } from '../ViewLink';

const TextLine: FC<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <Typography variant="caption" component="div" color="text.secondary">
            {children}
        </Typography>
    );
};

// TODO: add proper eventName type
export const getTimelineContent = (eventName: string, event: UserHistoryEvent) => {
    switch (eventName) {
        case 'OrderPaid':
        case 'OrderCreated':
            return (
                <>
                    <TextLine>
                        <b>Order:</b> <ViewLink entityType="order" entityId={event.payload.orderId} title={event.payload.orderCode} />
                    </TextLine>
                    <TextLine>
                        <b>Project:</b> <ViewLink entityType="project" entityId={event.payload.projectId} title={event.payload.projectCode} />
                    </TextLine>
                    <TextLine>
                        <b>Amount:</b> {currency(event.payload.amount)}
                    </TextLine>
                </>
            );
        case 'OrderSettled':
        case 'OrderCancelled':
            return (
                <>
                    <TextLine>
                        <b>Order:</b> <ViewLink entityType="order" entityId={event.payload.orderId} title={event.payload.orderCode} />
                    </TextLine>
                    <TextLine>
                        <b>Project:</b> <ViewLink entityType="project" entityId={event.payload.projectId} title={event.payload.projectCode} />
                    </TextLine>
                </>
            );
        case 'OrderItemPaymentReceived':
            return (
                <>
                    <TextLine>
                        <b>Order Item:</b> <ViewLink entityType="order" entityId={event.payload.orderId} title={event.payload.orderItemCode} />
                    </TextLine>
                    <TextLine>
                        <b>Received Amount:</b> {currency(event.payload.receivedAmount)}
                    </TextLine>
                    <TextLine>
                        <ViewLink entityType="bankTransaction" entityId={event.payload.bankTransactionId} title="Transaction" />
                    </TextLine>
                </>
            );
        case 'CommunicationSent':
            return (
                <>
                    <TextLine>
                        <b>{event.payload.channel}:</b> {event.payload.messageType}
                    </TextLine>
                    <TextLine>
                        {event.payload.data && Object.keys(event.payload.data).length > 0 ? (
                            <>
                                <b>Data:</b> {JSON.stringify(event.payload.data)}
                            </>
                        ) : null}
                    </TextLine>
                </>
            );
        case 'InvestmentStarted':
            return (
                <>
                    <TextLine>
                        <b>Project:</b> <ViewLink entityType="project" entityId={event.payload.projectId} title={event.payload.projectCode} />
                    </TextLine>
                    <TextLine>
                        <b>Amount:</b> {currency(event.payload.selectedAmount)}
                    </TextLine>
                </>
            );
        case 'UserPayoutReportPublished':
            return (
                <>
                    {/* TODO: add link to payout report page */}
                    {/*<TextLine>*/}
                    {/*    <b>Payout Report:</b> <ViewLink entityType="project" entityId={event.payload.projectId} title={event.payload.projectCode} />*/}
                    {/*</TextLine>*/}
                    <TextLine>
                        <b>Quarter:</b> {`${event.payload.year}-${event.payload.quarter}`}
                    </TextLine>
                </>
            );
        case 'UserBrandChanged':
            return <TextLine>{`${event.payload.oldBrand} ⇒ ${event.payload.newBrand}`}</TextLine>;
        case 'MailgunStatusReceived':
            return (
                <>
                    <TextLine>
                        <b>Message Type: </b>
                        {event.payload.messageType}
                    </TextLine>
                    <TextLine>{`Mailgun status: ${event.payload.mailgunStatus} ⇒ Outbox status: ${event.payload.outboxStatus}`}</TextLine>
                    {event.payload.mailgunMessage.toLowerCase() !== 'ok' ? <TextLine>{`Delivery msg: ${event.payload.mailgunMessage}`}</TextLine> : null}
                </>
            );
        default:
            return null;
    }
};

export const getTimelineIcon = (event: UserHistoryEvent): React.ReactNode => {
    switch (event.eventName) {
        case 'OrderCreated':
            return <ShoppingCartIcon />;
        case 'OrderCancelled':
            return <RemoveShoppingCartIcon />;
        case 'OrderPaid':
            return <EuroIcon />;
        case 'OrderSettled':
            return <CurrencyBitcoinIcon />;
        case 'OrderItemPaymentReceived':
            return <EuroIcon />;
        case 'CommunicationSent':
            return event.payload.channel ? event.payload.channel.toLowerCase() === 'push' ? <SmsIcon /> : <MailIcon /> : <QuestionMarkIcon />;
        case 'AccountDeletionRequested':
            return <PersonRemoveIcon />;
        case 'InvestmentStarted':
            return <ShopIcon />;
        case 'UserPayoutReportPublished':
            return <AssessmentIcon />;
        case 'UserSignedIn':
            return <LockOpenIcon />;
        case 'UserSignedOut':
            return <LockIcon />;
        case 'UserBrandChanged':
            return <PublishedWithChangesIcon />;
        case 'MailgunStatusReceived':
            return event.payload.outboxStatus.toLowerCase() === 'delivered' ? <MailIcon /> : <Unsubscribe />;
        default:
            return <QuestionMarkIcon />;
    }
};
