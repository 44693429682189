import { LoadingButton } from '@mui/lab';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import DetailsItem from '../../../components/DetailsItem';
import ExtLink from '../../../components/ExtLink';
import Loader from '../../../components/Loader';
import SummaryBox from '../../../components/SummaryBox';
import { ViewLink } from '../../../components/ViewLink';
import { downloadFile } from '../../../helpers/dom';
import { useRunDistributionPreparation } from '../../../hooks/use-mutations';
import { useGetDistributionListFile, useGetDistributionQuery } from '../../../hooks/use-queries';
import PageWrapper from '../../PageWrapper';
import ProjectDistributionStats from '../shared/ProjectDistributionStats';
import OnboardingProgress from './OnboardingProgress';
import UsersList from './UsersList';

interface Props {
    onSuccess?: (projectId: string, message?: string) => void;
    onError?: (error) => void;
}

const DistributionEditPage: FC<Props> = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { distributionId } = useParams();

    const breadcrumbs = { '/dashboard': 'Dashboard', '/distribution': 'Distributions', '': distributionId };
    const wrapperProps = { breadcrumbs, title: 'Edit Tokens Distribution' };
    const { isLoading, data } = useGetDistributionQuery(distributionId);
    const mutation = useRunDistributionPreparation(() => {
        enqueueSnackbar('Distribution preparation started', { variant: 'success' });
    });

    const { refetch: getDistributionListFile } = useGetDistributionListFile({ distributionId });

    if (isLoading) {
        return (
            <PageWrapper {...wrapperProps}>
                <Loader />
            </PageWrapper>
        );
    }

    return (
        <PageWrapper {...{ ...wrapperProps, status: data.status }}>
            <SummaryBox>
                <Stack direction="column">
                    <DetailsItem title="Project" value={<ViewLink entityType="project" entityId={data.projectId} title={data.project.code} />} />
                    <DetailsItem title="Created At" value={new Date(data.createdAt)} />
                    <DetailsItem title="Distributed At" value={data.distributedAt ? new Date(data.distributedAt) : null} />
                    <DetailsItem title="Transaction Url" value={data.transactionUrl ? <ExtLink url={data.transactionUrl} /> : null} />
                </Stack>
            </SummaryBox>
            <ProjectDistributionStats distributionId={distributionId} />
            {data ? <OnboardingProgress distribution={data} /> : null}
            <UsersList distributionId={distributionId} />

            <Stack sx={{ p: 2 }} direction="row" gap={1} justifyContent="end">
                <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={mutation.isLoading}
                    onClick={() => {
                        mutation.mutate({ projectId: data.projectId, distributionId: data.id });
                    }}
                    sx={{ mr: 2 }}
                    disabled={data?.status === 'COMPLETED'}
                >
                    Re-run Preparation
                </LoadingButton>
                {data && data.status !== 'DRAFT' ? (
                    <LoadingButton
                        variant="contained"
                        color="secondary"
                        loading={mutation.isLoading}
                        onClick={async () => {
                            const { data } = await getDistributionListFile();
                            downloadFile(new Blob([data.csv]), `${data.filename}.csv`);
                        }}
                        sx={{ mr: 2 }}
                    >
                        Download distribution list
                    </LoadingButton>
                ) : null}
            </Stack>
        </PageWrapper>
    );
};

export default DistributionEditPage;
