// import { InvestEventName } from '@condo/events';

// Note: hardcode the list as there are some compile issues with vite. Need to be fixed later
enum InvestEventName {
    AccountDeletionRequested = 'AccountDeletionRequested',
    CommunicationSent = 'CommunicationSent',
    OrderCreated = 'OrderCreated',
    OrderCancelled = 'OrderCancelled',
    OrderPaid = 'OrderPaid',
    OrderSettled = 'OrderSettled',
    OrderItemPaymentReceived = 'OrderItemPaymentReceived',
    InvestmentStarted = 'InvestmentStarted',
    UserPayoutReportPublished = 'UserPayoutReportPublished',
    UserProfileDataChanged = 'UserProfileDataChanged',
    UserSignedIn = 'UserSignedIn',
    UserSignedOut = 'UserSignedOut',
    UserBrandChanged = 'UserBrandChanged',
    MailgunStatusReceived = 'MailgunStatusReceived',
}

export const EVENT_TYPES_FILTER_LIST = Object.values(InvestEventName);
