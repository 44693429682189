import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';
import Link from '@mui/material/Link';
import type { GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import type { GetUserFileResponse } from '../../../../../schemas';
import { downloadFile } from '../../../helpers/dom';
import { useDI } from '../../../hooks/use-di';
import { useGetData } from '../../../hooks/use-get-data';
import { useGenerateAnnualTaxReportsMutation } from '../../../hooks/use-mutations';
import Section from '../../Section';

interface Props {
    userId: string;
}

const UserFiles: FC<Props> = ({ userId }) => {
    const { apiClient } = useDI();
    const {
        dataGridPaginationProps,
        list = [],
        error,
        isLoading,
    } = useGetData({
        dataKey: 'USER_FILES',
        defaultPageSize: 15,
        extraProps: { userId },
    });

    const { enqueueSnackbar } = useSnackbar();

    const onSuccess = (data: any) => {
        downloadFile(data, 'Annual Tax Report Preview.pdf');
    };

    const previewAnnualTaxReportMutation = useGenerateAnnualTaxReportsMutation({ onSuccess, onError });

    const download = (userFileId: string, fileName: string) => {
        apiClient
            .downloadUserFile(userId, userFileId, fileName)
            .then(({ data, fileName }) => {
                downloadFile(data, fileName);
            })
            .catch(onError);
    };

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    if (!isLoading && error) {
        onError(error);
    }

    const columns: GridColumns = [
        {
            field: 'category',
            headerName: 'Category',
            minWidth: 100,
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 250,
        },
        {
            field: 'createAt',
            headerName: 'Date',
            type: 'dateTime',
            minWidth: 180,
            valueGetter: (params: GridRenderCellParams<string, GetUserFileResponse>) => new Date(params.row.updatedAt || params.row.createdAt),
        },
        {
            field: 'orderCode',
            headerName: 'Order code',
            minWidth: 180,
        },
        {
            field: 'projectCode',
            headerName: 'Project code',
            minWidth: 180,
        },

        {
            field: 'context',
            type: 'actions',
            getActions: (params: GridRowParams<GetUserFileResponse>) => {
                const { orderId, projectId } = params.row;
                return [
                    orderId && (
                        <Link href={`/orders/view/${orderId}`} target="_blank">
                            Order
                        </Link>
                    ),
                    projectId && (
                        <Link href={`/projects/edit/${projectId}`} target="_blank">
                            Project
                        </Link>
                    ),
                ].filter(v => v);
            },
        },
        {
            field: 'actions',
            type: 'actions',
            width: 70,
            getActions: (params: GridRowParams<GetUserFileResponse>) => [
                <GridActionsCellItem
                    icon={<DownloadIcon />}
                    onClick={() => {
                        download(params.id as string, params.row.name);
                    }}
                    key="view-action"
                    label="View"
                />,
            ],
        },
    ];

    return (
        <Section title="User Files">
            <DataGrid rows={list} columns={columns} error={error} autoHeight loading={isLoading} checkboxSelection={false} {...dataGridPaginationProps} />
            <Button onClick={() => previewAnnualTaxReportMutation.mutate({ userId })}>Preview User Annual Tax Report</Button>
        </Section>
    );
};

export default UserFiles;
