import { currency } from '@condo/formatters';
import { Typography, styled } from '@mui/material';
import type { GridCellParams, GridColumns } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { ResponsiveBullet } from '@nivo/bullet';
import _isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { Fragment } from 'react';
import type { ProjectFundingStats } from '../../../../domain-entities/models/reports';
import { useGetProjectFundingStats } from '../../hooks/use-queries';
import Loader from '../Loader';

const Container = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    height: 400,
}));

const LegendContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    height: 32,
    // justifyContent: 'space-evenly',
    padding: theme.spacing(4, 4),
}));

const ProjectsFundingStats: FC = () => {
    const { isLoading, data: stats } = useGetProjectFundingStats();
    if (isLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }
    if (_isEmpty(stats)) {
        return (
            <Container>
                <Typography variant="h6">No Stats Found</Typography>
            </Container>
        );
    }
    const legend: { title: string; color: string }[] = [
        { title: 'Open', color: 'rgba(255, 0, 0, 1)' },
        { title: 'Paid', color: 'rgba(128, 0, 128, 1)' },
    ];

    const data = stats.map(({ code, projectAmount, open, paid }) => ({
        id: code,
        ranges: [0, projectAmount],
        measures: [paid],
        markers: [open],
    }));

    const columns: GridColumns<ProjectFundingStats> = [
        {
            field: 'code',
            headerName: 'Project Code',
            minWidth: 180,
        },
        {
            field: 'paid',
            headerName: 'Paid Amount',
            minWidth: 130,
            renderCell: (params: GridCellParams<ProjectFundingStats>) => {
                return currency(params.row.paid);
            },
        },
        {
            field: 'open',
            headerName: 'Open Amount',
            minWidth: 130,
            renderCell: (params: GridCellParams<ProjectFundingStats>) => {
                return currency(params.row.open);
            },
        },
        {
            field: 'projectAmount',
            headerName: 'Project Amount',
            minWidth: 130,
            renderCell: (params: GridCellParams<ProjectFundingStats>) => {
                return currency(params.row.projectAmount);
            },
        },
        {
            field: 'ordersNumber',
            headerName: 'Number of Orders',
            minWidth: 150,
        },
    ];
    const fillRow = (data: Record<string, any>, sign: string | number) => Object.keys(data).reduce((r, key) => ({ ...r, [key]: sign }), {} as any);

    const totals = stats?.reduce(
        (result, row) => {
            Object.keys(row).forEach(key => {
                if (key === 'code') {
                    result[key] = 'Total: ';
                } else {
                    result[key] += row[key];
                }
            });
            return result;
        },
        fillRow(stats[0], 0),
    );
    const statsWithTotals = [...stats, fillRow(stats[0], '='), totals];

    return (
        <>
            <Container>
                <Typography variant="h6">Projects Funding</Typography>
                <ResponsiveBullet
                    data={data}
                    spacing={70}
                    margin={{ top: 50, right: 90, bottom: 10, left: 90 }}
                    titleOffsetY={-20}
                    rangeColors="oranges"
                    measureColors="purple"
                    markerColors="red"
                    motionConfig="wobbly"
                    layout="vertical"
                />
            </Container>
            <LegendContainer>
                {legend.map(item => {
                    return (
                        <Fragment key={item.title}>
                            <div style={{ width: 24, height: 24, backgroundColor: item.color, marginRight: 10 }} />
                            <p style={{ fontSize: 12, lineHeight: '24px', marginRight: 20 }}>{item.title}</p>
                        </Fragment>
                    );
                })}
            </LegendContainer>
            <Container>
                <DataGrid columns={columns} rows={statsWithTotals} getRowId={(row: ProjectFundingStats) => row.code} />
            </Container>
        </>
    );
};

export default ProjectsFundingStats;
