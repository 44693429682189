import { OpenIbanService } from '@condo/iban';
import { asClass, asValue, createContainer } from 'awilix';
import { initializeApp } from 'firebase/app';
import * as firebaseAuth from 'firebase/auth';
import ApiClient from '../api/ApiClient';
import { AuthService } from '../api/AuthService';
import HistoryService from '../api/history';
import httpClient from '../api/httpClient';
import type { IWebDI } from './types';

declare const __CONFIG__;
initializeApp(__CONFIG__.firebase);

export const container = createContainer<IWebDI>();
container.register({
    apiClient: asClass(ApiClient)
        .inject(container => ({ httpClient: httpClient(__CONFIG__.backendApiUrl, container.resolve('authService')) }))
        .singleton(),
    authService: asClass(AuthService)
        .singleton()
        .disposer(service => service.dispose()),
    firebaseAuth: asValue(firebaseAuth),
    ibanService: asClass(OpenIbanService).singleton(),
    historyService: asClass(HistoryService)
        .inject(container => ({ httpClient: httpClient(__CONFIG__.historyServiceUrl, container.resolve('authService')) }))
        .singleton(),
});
