import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import type { EntityDetails } from '../../../../../domain-entities/models/base';
import type { IProject } from '../../../../../domain-entities/models/project';
import { projectDetailsSchema } from '../../../helpers/formSchemas';
import { validateForm } from '../../../helpers/formValidation';
import { useSaveProjectMutation } from '../../../hooks/use-mutations';
import { defaultValues } from '../constants';
import {
    BrandedDetailsSection,
    ChancesRisksSection,
    CostsAndFeesSection,
    FactsSection,
    FinancialDataSection,
    ForecastSection,
    LegalSection,
    MainDetailsSection,
    PortfolioLocationSection,
} from './form/ProjectDetailsSections';

export type TDetailsKeys = keyof typeof defaultValues.details;
export type TDetails = Record<TDetailsKeys, EntityDetails>;
interface Props {
    project: IProject;
    details?: TDetails;
}

const ProjectDetailsForm: FC<Props> = ({ project, details }) => {
    const { enqueueSnackbar } = useSnackbar();
    const mutation = useSaveProjectMutation(
        () => {
            enqueueSnackbar('Project details are updated', { variant: 'success' });
        },
        error => {
            enqueueSnackbar(`Error while storing project details: ${error}`, { variant: 'error' });
        },
    );
    const { currency } = project;

    const formik = useFormik<TDetails>({
        onSubmit(values, { setSubmitting }) {
            mutation.mutate({ project, details: Object.values(values) });
            setSubmitting(false);
        },
        initialValues: { ...defaultValues.details, ...details },
        // validateOnChange: false,
        validate: values => validateForm<typeof projectDetailsSchema>(values, projectDetailsSchema),
    });
    const { isSubmitting, handleSubmit } = formik;

    return (
        <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <MainDetailsSection currency={currency} />
                    <BrandedDetailsSection title="Intro Video Link - Branded" type="introVideoLink" />
                    <FactsSection />
                    <PortfolioLocationSection />
                    <ChancesRisksSection />
                    <FinancialDataSection currency={currency} />
                    <ForecastSection currency={currency} />
                    <CostsAndFeesSection />
                    <LegalSection />
                </Grid>
                <Stack sx={{ p: 2 }} direction="row" justifyContent="end">
                    <LoadingButton type="submit" variant="contained" color="primary" loading={isSubmitting || mutation.isLoading}>
                        Save
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
};

export default ProjectDetailsForm;
