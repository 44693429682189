import type { ChipProps } from '@mui/material/Chip/Chip';
import type { FunctionComponent } from 'react';
import type { OrderStatus } from '../../../../domain-entities/models/order';
import { getStatusColor } from '../../helpers/color';
import StatusChip from '../StatusChip';

interface OrderStatusChipProps extends ChipProps {
    orderStatus: OrderStatus;
}

const OrderStatusChip: FunctionComponent<OrderStatusChipProps> = ({ orderStatus, ...props }) => {
    return <StatusChip label={orderStatus} size="small" color={getStatusColor(orderStatus)} {...props} />;
};

export default OrderStatusChip;
