import { Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Page from '../components/Page';
import { AlternativeMethods, LoginForm } from '../components/auth';

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));

export default function Login() {
    return (
        <RootStyle title="Login">
            <Container maxWidth="sm">
                <ContentStyle>
                    <Stack sx={{ mb: 5 }}>
                        <Typography variant="h4" gutterBottom>
                            Sign in Condo Invest
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
                    </Stack>
                    <AlternativeMethods />
                    <LoginForm />
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}
