import type { FC } from 'react';
import type { TabsConfig } from '../../../components/Tabs';
import Tabs from '../../../components/Tabs';
import PageWrapper from '../../PageWrapper';
import List from './List';

interface Props {
    title: string;
}
const PaymentRequestsListPage: FC<Props> = ({ title }) => {
    const tabs: TabsConfig = {
        voucher: { label: 'Unpaid Vouchers', component: () => <List type="VOUCHER" /> },
        refund: { label: 'Refund Requests', component: () => <List type="REFUND" /> },
        payout: { label: 'Payouts', component: () => <List type="PAYOUT" /> },
        buyback: { label: 'Token Buybacks', component: () => <List type="BUYBACK" /> },
    };

    return (
        <PageWrapper title={title}>
            <Tabs tabs={tabs} />
        </PageWrapper>
    );
};

export default PaymentRequestsListPage;
