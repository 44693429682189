import { currency } from '@condo/formatters';
import type { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import type { FC } from 'react';
import Section from '../Section';
import type { OrderItem } from '.prisma/client';

interface Props {
    orderItems: OrderItem[];
}

const columns: GridColumns = [
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
    },
    {
        field: 'code',
        headerName: 'Code',
        minWidth: 150,
    },
    {
        field: 'type',
        headerName: 'Type',
        minWidth: 100,
    },
    {
        field: 'amount',
        headerName: 'Amount',
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<OrderItem>) => currency(params.value),
    },
    {
        field: 'receivedAmount',
        headerName: 'Received',
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<OrderItem>) => currency(params.value),
    },
    {
        field: 'receivedAt',
        headerName: 'Received At',
        minWidth: 150,
        type: 'date',
        valueGetter: (params: GridRenderCellParams<OrderItem>) => (params.value ? new Date(params.value) : null),
    },
];

const OrderItems: FC<Props> = ({ orderItems }) => {
    return (
        <Section title="Order Items">
            <DataGrid rows={orderItems} columns={columns} autoHeight hideFooter />
        </Section>
    );
};

export default OrderItems;
