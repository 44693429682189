import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import type { CircularProgressProps } from '@mui/material/CircularProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import type { GetDistributionUsers } from '../../../../../schemas';
import type { DataGridProps } from '../../../components/DataGrid';
import DataGrid, { useGridColumns } from '../../../components/DataGrid';
import { ViewLink } from '../../../components/ViewLink';

interface Props {
    distributionId: string;
}

const UsersList: FC<Props> = ({ distributionId }) => {
    const disabledAttrs = {
        enableColumnFilter: false,
    };
    const columns = useGridColumns<GetDistributionUsers>([
        {
            ...disabledAttrs,
            id: 'onboardingProgress',
            header: 'Onboarding',
            maxSize: 100,
            Cell: ({ row }) => {
                const steps =
                    Number(Boolean(row.original.user.tanganyCustomerId)) +
                    Number(Boolean(row.original.user.tanganyEntityId)) +
                    Number(Boolean(row.original.user.tanganyWalletDetails));
                return <CircularProgressWithLabel value={steps ? 100 * (steps / 3) : 0} label={`${steps}/3`} />;
            },
        },
        {
            ...disabledAttrs,
            accessorKey: 'user.profile.lastName',
            header: 'User Name',
            minSize: 150,
            Cell: ({ row }) => {
                const {
                    userId,
                    user: {
                        profile: { firstName, lastName },
                    },
                } = row.original;
                return <ViewLink entityType="user" entityId={userId} title={`${firstName} ${lastName}`} />;
            },
        },
        {
            ...disabledAttrs,
            accessorKey: 'tokens',
            header: 'Tokens',
            maxSize: 100,
        },
        {
            ...disabledAttrs,
            accessorKey: 'user.tanganyWalletDetails.address',
            header: 'Wallet Address',
        },
        {
            ...disabledAttrs,
            accessorKey: 'ordersList',
            header: 'Orders',
            Cell: ({ cell }) => (
                <ul>
                    {cell.getValue<string[]>().map(id => (
                        <li key={id}>
                            <ViewLink entityType="order" entityId={id} title={id} />
                        </li>
                    ))}
                </ul>
            ),
        },
        {
            ...disabledAttrs,
            accessorKey: 'createdAt',
            header: 'Created At',
            dataType: 'date-time',
        },
    ]);

    const dataGridProps: DataGridProps<GetDistributionUsers> = {
        columns,
        dataFetch: { key: 'DISTRIBUTION_USERS', params: { distributionId } },
    };

    return (
        <Card>
            <DataGrid {...dataGridProps} />
        </Card>
    );
};

function CircularProgressWithLabel(props: CircularProgressProps & { value: number; label: string }) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                sx={{
                    color: theme => theme.palette.grey[500],
                }}
                size={30}
                thickness={2}
                variant="determinate"
                {...props}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {props.label}
                </Typography>
            </Box>
        </Box>
    );
}

export default UsersList;
