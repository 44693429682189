import { Button } from '@mui/material';
import type { MRT_RowSelectionState, MaterialReactTableProps } from 'material-react-table';
import type { FC } from 'react';
import { useState } from 'react';
import ConfirmDialog from '../Dialog';
import type { ExportColumns, RowSelectionData } from './types';

interface Props {
    enableRowSelection?: MaterialReactTableProps['enableRowSelection'];
    rowSelection?: MRT_RowSelectionState;
    setFetchWithoutPagination?: (value: boolean) => void;
    setExportColumns?: (data: ExportColumns) => void;
    rowSelectionData?: RowSelectionData;
    tableRef?: any;
}
const RowSelectionActionButtons: FC<Props> = ({
    enableRowSelection,
    rowSelection,
    setFetchWithoutPagination,
    setExportColumns,
    rowSelectionData,
    tableRef,
}) => {
    const [dialogOpen, setDialogOpen] = useState<boolean[]>(rowSelectionData?.map(() => false) || []);

    const toggleDialogState = (id: string | number, isOpen: boolean) => {
        setDialogOpen(prevState => {
            const newState = [...prevState];
            newState[id] = isOpen;
            return newState;
        });
    };
    return (
        <>
            {enableRowSelection && rowSelectionData?.length && rowSelectionData.length > 0
                ? rowSelectionData.map((rowSelectionAction, index) => {
                      return (
                          <div key={`${index}-action-block`}>
                              <Button
                                  color="secondary"
                                  size="small"
                                  onClick={() => {
                                      if (rowSelectionAction?.dialogContent) {
                                          toggleDialogState(index, true);
                                          return;
                                      }
                                      rowSelectionAction?.action(Object.keys(rowSelection));
                                  }}
                                  variant="outlined"
                              >
                                  {rowSelectionAction?.buttonText}
                              </Button>
                              {rowSelectionAction?.dialogContent ? (
                                  <ConfirmDialog
                                      content={rowSelectionAction?.dialogContent}
                                      title={rowSelectionAction?.dialogTitle}
                                      isOpened={dialogOpen[index]}
                                      handleClose={() => toggleDialogState(index, false)}
                                      handleConfirm={() => {
                                          rowSelectionAction?.action(Object.keys(rowSelection), tableRef, setExportColumns, setFetchWithoutPagination);
                                          toggleDialogState(index, false);
                                      }}
                                  />
                              ) : null}
                          </div>
                      );
                  })
                : null}
        </>
    );
};

export default RowSelectionActionButtons;
