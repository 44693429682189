import { Card } from '@mui/material';
import type { IJob } from '../../../../../domain-entities/models/job';
import type { DataGridProps } from '../../../components/DataGrid';
import DataGrid, { useGridColumns } from '../../../components/DataGrid';
import StatusChip from '../../../components/StatusChip';
import { getStatusColor } from '../../../helpers/color';
import PageWrapper from '../../PageWrapper';

export default function JobsListPage() {
    const columns = useGridColumns<IJob>([
        { accessorKey: 'id', header: 'Job ID', minSize: 200 },
        { accessorKey: 'ref', header: 'Task ID (Ref)', minSize: 200 },
        { accessorKey: 'name', header: 'Name', minSize: 200 },
        {
            accessorKey: 'status',
            header: 'Status',
            minSize: 150,
            filterVariant: 'multi-select',
            filterSelectOptions: ['RUNNING', 'FAILED'],
            Cell: ({ cell }) => <StatusChip label={cell.getValue()} size="small" color={getStatusColor(cell.getValue<string>())} />,
        },
        { accessorKey: 'retryCount', header: 'Retry Count', maxSize: 50 },
        {
            accessorKey: 'createdAt',
            sortingFn: 'datetime',
            header: 'Created At',
            dataType: 'date-time',
        },
        {
            accessorKey: 'scheduledAt',
            header: 'Scheduled At',
            sortingFn: 'datetime',
            dataType: 'date-time',
        },
    ]);

    const dataGridProps: DataGridProps<IJob> = {
        columns,
        dataFetch: { key: 'JOBS' },
        actions: {
            view(row) {
                const { id } = row;
                window.open(`/jobs/view/${id}`, '_blank');
            },
        },
    };

    return (
        <PageWrapper title="Failed/Running Jobs">
            <Card>
                <DataGrid {...dataGridProps} />
            </Card>
        </PageWrapper>
    );
}
