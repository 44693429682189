import type { FC } from 'react';
import { HistoryEventsTable } from '../../HistoryTimeline/HistoryEventsTable';
import Section from '../../Section';

interface Props {
    userId: string;
}

const UserHistoryEvents: FC<Props> = ({ userId }) => {
    return (
        <Section title="User History Events">
            <HistoryEventsTable userId={userId} />
        </Section>
    );
};

export default UserHistoryEvents;
