import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { Field, useFormikContext } from 'formik';
import { Select } from 'formik-mui';
import _isEmpty from 'lodash/isEmpty';
import _lowerCase from 'lodash/lowerCase';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { BrandName } from '../../../../../../domain-entities/models/base';
import type { IProjectDocument } from '../../../../../../domain-entities/models/project';
import { FileUploader } from '../../../../components/FileUploader';
import { brands, projectDocumentCategories } from '../../constants';
import type { ProjectDocumentCategory } from '../../types';

export interface FormValues {
    documents: Record<string, IProjectDocument>;
    brand: BrandName;
    category: string;
}

interface Props {}

const InputForm: FC<Props> = () => {
    const [currentDocumentId, setCurrentDocumentId] = useState('');
    const { values, setFieldValue } = useFormikContext<FormValues>();

    useEffect(() => {
        if (values.brand && values.category && currentDocumentId && !_isEmpty(values.documents)) {
            setFieldValue('documents', {
                ...values.documents,
                [currentDocumentId]: {
                    ...values.documents[currentDocumentId],
                    isPrivate: false,
                    category: values.category as ProjectDocumentCategory,
                    brand: values.brand,
                },
            });
        }
    }, [values.brand, values.category, currentDocumentId]);

    const takenCategories = Object.keys(values.documents).reduce(
        (list, key) => {
            const { category, brand } = values.documents[key];
            if (!list[brand]) {
                list[brand] = [];
            }

            list[brand].push(category);
            return list;
        },
        {} as Record<BrandName, ProjectDocumentCategory[]>,
    );
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1,
                p: 2,
                mb: 2,
                borderRadius: 1,
                border: theme => `solid 2px ${theme.palette.grey[500_8]}`,
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Stack direction="column" spacing={1}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                    }}
                >
                    <Field component={Select} name="brand" label="Brand" sx={{ width: 100 }}>
                        {brands.map(brand => (
                            <MenuItem value={brand} key={brand}>
                                {brand}
                            </MenuItem>
                        ))}
                    </Field>
                    <Field fullWidth component={Select} sx={{ width: 250 }} name="category" label="Category">
                        <MenuItem value="NOT_SELECTED" key="NOT_SELECTED" disabled>
                            Please Select
                        </MenuItem>
                        {projectDocumentCategories.map(category => {
                            return (
                                <MenuItem value={category} key={category} disabled={takenCategories[values.brand]?.includes(category)}>
                                    {_lowerCase(category)}
                                </MenuItem>
                            );
                        })}
                    </Field>
                </Box>
                <FileUploader
                    onUploadSucceed={([{ id, ...file }]) => {
                        setFieldValue('documents', {
                            ...values.documents,
                            [id]: {
                                ...file,
                                tempFileId: id,
                                isPrivate: false,
                                category: values.category as ProjectDocumentCategory,
                                brand: values.brand,
                            },
                        });
                        setCurrentDocumentId(id);
                    }}
                    id="document-files"
                    allowedFileTypes={['application/pdf']}
                />
            </Stack>
        </Box>
    );
};

export default InputForm;
