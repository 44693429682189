import { Card } from '@mui/material';
import { LAST_COMPLETED_STEP_LIST, USER_STATUS_LIST, userStatusColor } from '../../../../../constants';
import type { UserStatus } from '../../../../../infrastructure/types';
import type { GetUserFullDataResponse } from '../../../../../schemas';
import DataGrid, { useGridColumns } from '../../../components/DataGrid';
import type { DataGridProps } from '../../../components/DataGrid';
import StatusChip from '../../../components/StatusChip';
import PageWrapper from '../../PageWrapper';

type GridData = GetUserFullDataResponse;

export default function UserListPage() {
    const columns = useGridColumns<GridData>([
        {
            accessorKey: 'email',
            header: 'Email',
            size: 150,
        },
        {
            id: 'name',
            header: 'Name',
            accessorFn: getUserName,
            enableSorting: false,
            minSize: 150,
        },
        {
            accessorKey: 'status',
            header: 'Status',
            minSize: 170,
            filterVariant: 'multi-select',
            filterSelectOptions: USER_STATUS_LIST,
            Cell: ({ cell }) => {
                const value = cell.getValue<UserStatus>();
                return <StatusChip label={value} size="small" color={userStatusColor[value] || 'default'} />;
            },
        },
        {
            accessorKey: 'lastCompletedStepDate',
            header: 'Step Completed At',
            dataType: 'date-time',
        },

        {
            accessorKey: 'lastCompletedStep',
            header: 'Last Step Name',
            filterVariant: 'multi-select',
            filterSelectOptions: LAST_COMPLETED_STEP_LIST,
            size: 150,
        },
        {
            accessorKey: 'createdAt',
            header: 'Created At',
            dataType: 'date-time',
        },
    ]);

    const dataGridProps: DataGridProps<GridData> = {
        columns,
        defaultPageSize: 100,
        dataFetch: { key: 'USERS' },
        actions: {
            view(row) {
                openUser(row.id);
            },
        },
    };

    return (
        <PageWrapper title="Users">
            <Card>
                <DataGrid {...dataGridProps} />
            </Card>
        </PageWrapper>
    );
}

function openUser(userId: string) {
    return window.open(`/users/view/${userId}`, '_blank');
}

const getUserName = (row: GridData) => {
    if (row.profile) {
        const { firstName, lastName } = row.profile;
        return `${firstName} ${lastName}`;
    }

    return '–';
};
