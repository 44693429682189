import type { IIbanData, IIbanService } from '@condo/iban';
import type { GetDataListQuery } from '@condo/invest-utils';
import type { AxiosInstance } from 'axios';
import { validateIBAN } from 'ibantools';
// @ts-expect-error
import _keyBy from 'lodash/keyBy';
import type { BrandName } from '../../../domain-entities/models/base';
import type { IEstateDetails } from '../../../domain-entities/models/estate';
import type { IOrder, OrderWithItems } from '../../../domain-entities/models/order';
import type { IOrderItem } from '../../../domain-entities/models/order-item';
import type { IPaymentRequestWithOrderAndUserData } from '../../../domain-entities/models/payment-request';
import type { IProject, IProjectDetails } from '../../../domain-entities/models/project';
import type { ProjectFundingStats, RegistrationFunnelData } from '../../../domain-entities/models/reports';
import type { ISetting } from '../../../domain-entities/models/settings';
import type { PaginationParams, PaginationResult } from '../../../infrastructure/types';
import type {
    CheckDistributionOnboardingStatusResponse,
    GenericResponse,
    GetAdminUserResponse,
    GetAdminUsersListResponse,
    GetBankTransactionResponse,
    GetDistributionResponse,
    GetDistributionStatsResponse,
    GetEstateResponse,
    GetEstateWithDetailsResponse,
    GetPayoutReportResponse,
    GetPayoutReportStatsResponse,
    GetProjectResponse,
    GetProjectWithDetailsResponse,
    GetUserFilesResponse,
    GetUserFullDataResponse,
    GetUserStepsResponse,
    GetVouchersListResponse,
    SaveAdminUserSchemaRequest,
    SettingsBatchUpdateRequest,
    SettingsUpdateRequest,
    UpsertEstatePayload,
} from '../../../schemas';
import type { GetBankTransactionLogResponse, ImportBankTransactionsResponse } from '../../../schemas/bank-transaction';
import endpoints from './endpoints';
import type { ApiDataKey } from './types';
import type { PaymentRequestStatus, PaymentRequestType, SettingsCategory } from '.prisma/client';

export default class ApiClient {
    readonly httpClient: AxiosInstance;
    readonly ibanService: IIbanService;

    constructor({ httpClient, ibanService }: { httpClient: AxiosInstance; ibanService: IIbanService }) {
        this.httpClient = httpClient;
        this.ibanService = ibanService;
    }

    async getList(urlKey: ApiDataKey, params?: Record<string, any>): Promise<any> {
        if (!endpoints.LIST[urlKey]) {
            throw new Error(`Unknown urlKey: ${urlKey}`);
        }
        const { data } = await this.httpClient.request({ ...endpoints.LIST[urlKey], params });
        return data;
    }

    async getAdminUsers(): Promise<GetAdminUsersListResponse> {
        const { data } = await this.httpClient.get('/api/admin-user/list');
        return data;
    }

    async getVouchersList(userId: string): Promise<GetVouchersListResponse> {
        const { data } = await this.httpClient.get<GetVouchersListResponse>(`/api/user/${userId}/vouchers`);
        return data;
    }

    async getProjectEstates(projectId: string): Promise<GetEstateWithDetailsResponse> {
        if (!projectId) {
            throw new Error('Invalid projectId param');
        }
        const { data } = await this.httpClient.get(`/api/project/${projectId}/estate/list`);
        return data;
    }

    async getUserOrders(userId: string): Promise<OrderWithItems[]> {
        const { data } = await this.httpClient.get(`/api/user/${userId}/orders`);
        return data;
    }

    async getPayoutReportStats(payoutReportId: string): Promise<GetPayoutReportStatsResponse> {
        const { data } = await this.httpClient.get(`/api/payout-reports/${payoutReportId}/stats`);
        return data;
    }

    async findOrderItem(keyword: string): Promise<
        | (Required<IOrderItem> & {
              order: Required<IOrder> & { project: Required<IProject> };
          })
        | null
    > {
        if (!keyword) {
            return null;
        }
        const { data } = await this.httpClient.get('/api/order-item/search', { params: { keyword } });
        return data;
    }

    async getOrderById(orderId: string): Promise<Required<OrderWithItems> & { project?: IProject }> {
        const { data } = await this.httpClient.get(`/api/order/${orderId}`);
        return data;
    }

    async settleOrder(orderId: string): Promise<Required<GenericResponse>> {
        const { data } = await this.httpClient.post(`/api/order/${orderId}/settle`);
        return data;
    }

    async getProject(id: string): Promise<GetProjectWithDetailsResponse> {
        if (!id) {
            throw new Error('Invalid projectId param');
        }
        const { data } = await this.httpClient.get(`/api/project/${id}`, { params: { projectId: id } });
        return data;
    }

    async createProject(project: IProject): Promise<GetProjectResponse> {
        if (!project) {
            throw new Error('Project data is missing');
        }

        const { data } = await this.httpClient.post('/api/project', { project });
        return data;
    }

    async updateProject(project: IProject, details?: Required<IProjectDetails>[]): Promise<GetProjectResponse> {
        if (!project) {
            throw new Error('Project data is missing');
        }

        const { data } = await this.httpClient.patch('/api/project', { project, details });
        return data;
    }

    async deleteProject(id: string): Promise<void> {
        if (!id) {
            throw new Error('Project ID is missing');
        }

        await this.httpClient.delete(`/api/project/${id}`);
    }

    async updateUserRestrictions(userId: string, restrictions: Record<string, boolean>): Promise<void> {
        if (!userId) {
            throw new Error('User ID is missing');
        }

        await this.httpClient.patch(`/api/user/${userId}/settings`, { restrictions });
    }

    async cancelOrder(orderCode: string, userId: string) {
        const { data } = await this.httpClient.post(`/api/order/${orderCode}/user/${userId}/cancel`);
        return data;
    }

    async publishProject(projectId: string) {
        const { data } = await this.httpClient.post(`/api/project/${projectId}/publish`);
        return data;
    }

    async createPayoutReport(input: any) {
        const { data } = await this.httpClient.post(`/api/payout-reports`, input);
        return data;
    }

    async updatePayoutReport(id: string, input: any) {
        const { data } = await this.httpClient.patch(`/api/payout-reports/${id}`, input);
        return data;
    }

    async markDraftPayoutReport(id: string) {
        const { data } = await this.httpClient.post<Required<GetPayoutReportResponse>>(`/api/payout-reports/${id}/draft`);
        return data;
    }

    async preparePayoutReport(id: string) {
        const { data } = await this.httpClient.post(`/api/payout-reports/${id}/prepare`);
        return data;
    }

    async publishPayoutReport(id: string) {
        const { data } = await this.httpClient.post(`/api/payout-reports/${id}/publish`);
        return data;
    }

    async getPayoutReport(id: string) {
        const { data } = await this.httpClient.get(`/api/payout-reports/${id}`);
        return data;
    }

    async getJob(id: string) {
        const { data } = await this.httpClient.get(`/api/job/${id}`);
        return data;
    }

    async getAdminUser(userId: string): Promise<GetAdminUserResponse> {
        if (!userId) {
            throw new Error('User id is missing');
        }

        const { data } = await this.httpClient.get(`/api/admin-user/${userId}`);
        return data;
    }

    async saveAdminUser(input: SaveAdminUserSchemaRequest): Promise<GetAdminUserResponse> {
        const { data } = await this.httpClient.post('/api/admin-user', input);
        return data;
    }

    async getUserById(userId: string, includeProfile = false): Promise<GetUserFullDataResponse> {
        const { data } = await this.httpClient.get(`/api/user/${userId}`, { params: { includeProfile } });
        return data;
    }

    async deleteUser(userId: string): Promise<GenericResponse> {
        const { data } = await this.httpClient.delete(`/api/user/${userId}`);
        return data;
    }

    async requestManualOffboarding(userId: string): Promise<GenericResponse> {
        const { data } = await this.httpClient.post(`/api/user/${userId}/request-manual-deletion`);
        return data;
    }

    async getUserSteps(userId: string): Promise<GetUserStepsResponse> {
        const { data } = await this.httpClient.get(`/api/user/${userId}/steps`);
        return data;
    }

    async resendUserToEffecta(userId: string): Promise<GenericResponse> {
        const { data } = await this.httpClient.post(`/api/user/${userId}/resend-effecta`);
        return data;
    }

    async resendOrderToEffecta(userId: string, orderId: string): Promise<GenericResponse> {
        const { data } = await this.httpClient.post(`/api/order/${orderId}/user/${userId}/resend-effecta`);
        return data;
    }

    async getSettings(category?: SettingsCategory): Promise<Record<string, ISetting>> {
        const { data } = await this.httpClient.get(`/api/settings`, { params: category ? { category } : undefined });
        return _keyBy(data, 'key');
    }

    uploadDocument = async (params: { key: string; category: SettingsCategory; version?: string; brand?: BrandName }): Promise<GenericResponse> => {
        return this.httpClient.post(`/api/settings/${params.key}/upload`, params);
    };

    uploadPayoutReport = async (formData: FormData, payoutReportId: string): Promise<void> => {
        const { data } = await this.httpClient.post(`/api/payout-reports/${payoutReportId}/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return data;
    };

    getPayoutReportFile = async (params: { payoutReportId: string }): Promise<ArrayBuffer[] | null> => {
        if (!params.payoutReportId) {
            return null;
        }
        const { data } = await this.httpClient.get(`/api/payout-reports/${params.payoutReportId}/stream`, {
            responseType: 'arraybuffer',
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return data?.byteLength > 4 ? data : null;
    };

    getDistributionListFile = async (params: { distributionId: string }): Promise<any | null> => {
        if (!params.distributionId) {
            return null;
        }

        const { data } = await this.httpClient.get(`/api/distribution/${params.distributionId}/export-list`, {
            headers: { 'Content-Type': 'text/csv' },
        });

        if (!data.csv) {
            return null;
        }

        return data;
    };

    getDocument = async ({ key, brand }: { key: string; brand?: BrandName }): Promise<ArrayBuffer[] | null> => {
        const { data } = await this.httpClient.get(`/api/settings/${key}/stream`, {
            responseType: 'arraybuffer',
            headers: { brand, 'Content-Type': 'multipart/form-data' },
        });
        return data?.byteLength > 4 ? data : null;
    };

    deleteEstateFile = async (projectId: string, estateId: string, fileId: string): Promise<void> => {
        const { data } = await this.httpClient.delete(`/api/project/${projectId}/estate/${estateId}/file/${fileId}`);
        return data;
    };

    deleteProjectDocument = async (projectId: string, documentId: string): Promise<void> => {
        const { data } = await this.httpClient.delete(`/api/project/${projectId}/document/${documentId}`);
        return data;
    };

    deleteTempFile = async (tempFileId: string): Promise<void> => {
        if (tempFileId) {
            const { data } = await this.httpClient.delete(`/api/temp-file/${tempFileId}`);
            return data;
        }
    };

    async generateAnnualTaxReports(userId?: string): Promise<void | Buffer> {
        const { data } = await this.httpClient.post(
            '/api/payout-reports/generate-annual-tax-report',
            { userId },
            userId ? { responseType: 'blob' } : undefined,
        );
        return data;
    }

    async prepareQuarterTaxReceipts(userPayoutIds: string[]): Promise<void> {
        await this.httpClient.post('/api/user-payout/prepared-quarter-tax-receipts', { userPayoutIds });
    }

    async createEstate(projectId, estateDetails?: IEstateDetails[], estateMediaFiles?: UpsertEstatePayload['estateMediaFiles']): Promise<GetEstateResponse> {
        if (!projectId) {
            throw new Error('Project id is missing');
        }
        const { data } = await this.httpClient.post(`/api/project/${projectId}/estate`, {
            estateDetails,
            estateMediaFiles,
        });
        return data;
    }

    async saveProjectDocuments(projectId, documents: any): Promise<any> {
        if (!projectId) {
            throw new Error('Project id is missing');
        }

        const { data } = await this.httpClient.post(`/api/project/${projectId}/documents`, { documents });
        return data;
    }

    async updateEstate(
        projectId: string,
        id: string,
        estateDetails?: Required<IEstateDetails>[],
        estateMediaFiles?: UpsertEstatePayload['estateMediaFiles'],
    ): Promise<GetEstateResponse> {
        if (!id) {
            throw new Error('Estate id is missing');
        }

        const { data } = await this.httpClient.patch(`/api/project/${projectId}/estate/${id}`, {
            estateDetails,
            estateMediaFiles,
        });
        return data;
    }

    async deleteEstate(projectId: string, id: string): Promise<void> {
        if (!id) {
            throw new Error('Estate ID is missing');
        }

        await this.httpClient.delete(`/api/project/${projectId}/estate/${id}`);
    }

    async getRegistrationsFunnel(): Promise<Partial<RegistrationFunnelData>> {
        const { data } = await this.httpClient.get<Partial<RegistrationFunnelData>>(`/api/reports/funnel`);
        return data;
    }

    async getProjectsFundingStats(): Promise<ProjectFundingStats[]> {
        const { data } = await this.httpClient.get<ProjectFundingStats[]>(`/api/reports/funding`);
        return data;
    }

    async getUserFiles(userId: string, pagination?: PaginationParams): Promise<PaginationResult<GetUserFilesResponse>> {
        const { data } = await this.httpClient.get<PaginationResult<GetUserFilesResponse>>(`/api/user/${userId}/files`, { params: pagination });
        return data;
    }

    async downloadUserFile(
        userId: string,
        userFileId: string,
        customFileName?: string,
    ): Promise<{
        data: Blob;
        fileName: string;
    }> {
        const { data, headers } = await this.httpClient.get<Blob>(`/api/user/${userId}/file/${userFileId}/download`, {
            responseType: 'blob',
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (customFileName) {
            return { data, fileName: customFileName };
        }

        const contentDisposition = headers['content-disposition'];
        if (!contentDisposition) {
            throw new Error('content-disposition header is not sent');
        }

        const fileName = contentDisposition.split('filename=').pop();
        return { data, fileName };
    }

    async signIn(): Promise<GenericResponse> {
        const { data } = await this.httpClient.post<GenericResponse>(`/api/admin-user/auth/sign-in`);
        return data;
    }

    async upsertSettings(settings: SettingsUpdateRequest): Promise<GenericResponse> {
        const { data } = await this.httpClient.post<GenericResponse>(`/api/settings`, settings);
        return data;
    }

    async updatePaymentRequests(
        ids: string[],
        payload: {
            status?: PaymentRequestStatus;
            type?: PaymentRequestType;
        },
    ): Promise<GenericResponse> {
        const { data } = await this.httpClient.patch<GenericResponse>('/api/payment-requests', { ids, data: payload });
        return data;
    }

    async batchUpsertSettings(settings: SettingsBatchUpdateRequest): Promise<GenericResponse> {
        const { data } = await this.httpClient.post<GenericResponse>(`/api/settings/batch`, settings);
        return data;
    }

    async importBankTransactions(bankTransactions: Record<string, string>[]): Promise<ImportBankTransactionsResponse> {
        const { data } = await this.httpClient.post<ImportBankTransactionsResponse>('/api/bank-transaction/import', bankTransactions);
        return data;
    }

    async getBankTransaction(id: string): Promise<GetBankTransactionResponse | null> {
        if (!id) {
            return null;
        }

        const { data } = await this.httpClient.get<GetBankTransactionResponse>(`/api/bank-transaction/${id}`);
        return data;
    }

    async getPaymentRequestsStats(type: PaymentRequestType): Promise<{ total: number }> {
        const { data } = await this.httpClient.get(`/api/payment-request/stats`, { params: { type } });
        return data;
    }

    async getPaymentRequest(id: string): Promise<IPaymentRequestWithOrderAndUserData | null> {
        if (!id) {
            return null;
        }

        const { data } = await this.httpClient.get(`/api/payment-request/${id}`);
        return data;
    }

    async completePaymentRequest(id: string): Promise<any | null> {
        if (!id) {
            return null;
        }

        const { data } = await this.httpClient.post(`/api/payment-request/${id}/complete`);
        return data;
    }

    async rejectPaymentRequest(id: string): Promise<any | null> {
        if (!id) {
            return null;
        }

        const { data } = await this.httpClient.post(`/api/payment-request/${id}/reject`);
        return data;
    }

    async exportSepaXml(listQuery: GetDataListQuery): Promise<any | null> {
        return this.httpClient.post(`/api/payment-request/export-sepa`, { listQuery });
    }

    async rescheduleJob(id: string): Promise<void> {
        if (!id) {
            return;
        }

        return this.httpClient.post(`/api/job/${id}/reschedule`);
    }

    async assignOrderItem({ orderItemId, bankTransactionId }: { orderItemId: string; bankTransactionId: string }): Promise<GenericResponse> {
        const { data } = await this.httpClient.post('/api/bank-transaction/assign-order-item', {
            orderItemId,
            bankTransactionId,
        });
        return data;
    }

    async fetchBankData(iban: string): Promise<IIbanData> {
        const isValid = !iban || validateIBAN(iban).valid;
        if (!isValid) {
            return null;
        }
        return this.ibanService.getBankData(iban);
    }

    async getImportTransactionLog(importRef: string) {
        const { data } = await this.httpClient.get<GetBankTransactionLogResponse>(`/api/bank-transaction/${importRef}/log`);
        return data;
    }

    async getProjectDistributionStats(params: { projectId?: string; distributionId?: string }) {
        if (!params.projectId && !params.distributionId) {
            throw new Error('Either projectId or distributionId must be provided');
        }
        const { data } = await this.httpClient.get<GetDistributionStatsResponse>(`/api/distribution/stats`, { params });
        return data;
    }

    async checkDistributionOnboardingStatus(distributionId: string) {
        if (!distributionId) {
            throw new Error('DistributionId must be provided');
        }
        const { data } = await this.httpClient.get<CheckDistributionOnboardingStatusResponse>(`/api/distribution/${distributionId}/onboarding-status`);
        return data;
    }

    async runDistributionPreparation(params: { projectId: string; distributionId?: string }) {
        const { data } = await this.httpClient.post<GetDistributionResponse>(`/api/distribution/prepare`, params);
        return data;
    }

    async getDistribution(distributionId: string) {
        const { data } = await this.httpClient.get<GetDistributionResponse>(`/api/distribution/${distributionId}`);
        return data;
    }
}
