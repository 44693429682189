import { BaseError } from '@condo/helpers';
import type { AxiosInstance } from 'axios';
import endpoints from './endpoints';
import type { GetUserHIstoryEventParams, HistoryServiceDataKey, UserHistoryEvent } from './types';

export class HistoryServiceError extends BaseError {}

export default class HistoryService {
    private readonly httpClient: AxiosInstance;

    constructor({ httpClient }: { httpClient: AxiosInstance }) {
        this.httpClient = httpClient;
    }

    async getUserHistoryEvents(params: GetUserHIstoryEventParams): Promise<UserHistoryEvent[] | null> {
        const { data } = await this.httpClient.get<UserHistoryEvent[]>('/api/history/:userId', { params: { userId: params.userId } });
        return data;
    }

    async getList(urlKey: HistoryServiceDataKey, params?: Record<string, any>): Promise<any> {
        const { data } = await this.httpClient.request({ ...endpoints.LIST[urlKey], params });
        return data;
    }
}
