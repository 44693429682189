import { currency } from '@condo/formatters';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { Variant } from '@mui/material/styles/createTypography';
import type { FC } from 'react';
import type { OrderWithItems } from '../../../../domain-entities/models/order';
import DetailsItem from '../DetailsItem';

interface Props {
    orders: OrderWithItems[];
}

const Amounts: FC<{ value: string; totalNumber: string; variant?: Variant }> = ({ value, totalNumber, variant }) => {
    return (
        <Box component="span" sx={{ display: 'inline-block', width: '250px' }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant={variant}>{value}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={variant}>{totalNumber}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const OrdersSummary: FC<Props> = ({ orders }) => {
    let totalReceived = 0;
    let totalPlanned = 0;
    let plannedInvestmentsAmount = 0;
    let receivedInvestmentsAmount = 0;
    let plannedVouchersAmount = 0;
    let receivedVouchersAmount = 0;
    let numberOfIntestments = 0;

    if (!orders?.length) {
        return null;
    }

    orders.forEach(({ orderItems }) => {
        orderItems.forEach(item => {
            if (!['CANCELLED', 'REFUNDED'].includes(item.status)) {
                if (item.type === 'PRIMARY') {
                    plannedInvestmentsAmount += item.amount;
                    receivedInvestmentsAmount += item.receivedAmount;
                    numberOfIntestments++;
                } else if (item.type === 'VOUCHER') {
                    plannedVouchersAmount += item.amount;
                    receivedVouchersAmount += item.receivedAmount;
                }
            }
        });
    });
    totalReceived = receivedVouchersAmount + receivedInvestmentsAmount;
    totalPlanned = plannedInvestmentsAmount + plannedVouchersAmount;

    return (
        <Box sx={{ maxWidth: 500 }}>
            <Typography color="text.secondary" variant="h6">
                Summary:
            </Typography>
            <DetailsItem titleWidth={220} title="" value={<Amounts value="Received" totalNumber="Total" variant="caption" />} />
            <DetailsItem
                titleWidth={220}
                title="Amount (Investments)"
                value={<Amounts value={currency(receivedInvestmentsAmount)} totalNumber={currency(plannedInvestmentsAmount)} />}
            />
            <DetailsItem
                titleWidth={220}
                title="Amount (Vouchers)"
                value={<Amounts value={currency(receivedVouchersAmount)} totalNumber={currency(plannedVouchersAmount)} />}
            />
            <Divider />
            <DetailsItem titleWidth={220} title="Total Investments" value={<Amounts value={currency(totalReceived)} totalNumber={currency(totalPlanned)} />} />
            <DetailsItem titleWidth={220} title="Number of active investments" value={numberOfIntestments} />
        </Box>
    );
};

export default OrdersSummary;
