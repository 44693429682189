import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import type { FC } from 'react';
import type { IPaymentDetails } from '../../../../domain-entities/models/order';
import type { SettingKey } from '../../../../domain-entities/models/settings';
import { useUpsertSettingMutation } from '../../hooks/use-mutations';
import Loader from '../Loader';

interface Props {
    onSuccess: () => void;
    onError: (error) => void;
    voucherBankAccount?: Omit<IPaymentDetails, 'referenceCode'> | null;
}

const VoucherAccountForm: FC<Props> = ({ onError, onSuccess, voucherBankAccount }) => {
    const mutation = useUpsertSettingMutation(onSuccess, onError);

    const initialValues = {
        iban: '',
        accountName: '',
        bankName: '',
        bic: '',
        ...voucherBankAccount,
    };

    const onSubmit = (values, { setSubmitting }) => {
        const settingsUpdateData = { key: 'voucherBankAccount' as SettingKey, value: JSON.stringify(values), format: 'JSON', category: 'VOUCHER_ACCOUNT' };
        mutation.mutate(settingsUpdateData);
        setSubmitting(false);
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting, submitForm }) => (
                <>
                    {isSubmitting ? <Loader shouldOverride /> : null}

                    <Form>
                        <Card>
                            <Box sx={{ px: 2 }}>
                                <h2>Voucher Payments Bank Account</h2>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} mt={2}>
                                        <Field component={TextField} name="iban" type="text" label="Voucher Payments IBAN" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} mt={2}>
                                        <Field component={TextField} name="bic" type="text" label="Voucher Payments BIC" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} mt={2}>
                                        <Field component={TextField} name="accountName" type="text" label="Voucher Payments Account Name" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} mt={2}>
                                        <Field component={TextField} name="bankName" type="text" label="Voucher Payments Bank Name" />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Stack sx={{ p: 2 }} direction="row" justifyContent="end">
                                <LoadingButton variant="contained" color="primary" loading={isSubmitting} onClick={submitForm}>
                                    Update
                                </LoadingButton>
                            </Stack>
                        </Card>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export default VoucherAccountForm;
