import Box from '@mui/material/Box';
import type { FC } from 'react';
import type { IBankTransaction } from '../../../../domain-entities/models/bank-transaction';
import type { IOrder } from '../../../../domain-entities/models/order';
import type { IOrderItem } from '../../../../domain-entities/models/order-item';
import { getOrderFlagIcons } from '../../helpers/order';
import { pxToRem } from '../../theme/typography';

interface Props {
    order: Required<IOrder> & { orderItems: Required<IOrderItem>[]; bankTransactions?: Required<IBankTransaction>[] };
}

const FlagsCell: FC<Props> = ({ order }) => {
    if (!order) {
        return null;
    }

    return (
        <Box>
            {getOrderFlagIcons(order).map(({ icon: Icon, title }, index) => {
                return <Icon key={index} color="action" titleAccess={title} sx={{ mx: 0.5, fontSize: pxToRem(16) }} />;
            })}
        </Box>
    );
};

export default FlagsCell;
