import { Typography, styled } from '@mui/material';
import type { FunnelDatum } from '@nivo/funnel';
import { ResponsiveFunnel } from '@nivo/funnel';
import type { FC } from 'react';
import { Fragment } from 'react';
import type { RegistrationFunnelBucket } from '../../../../domain-entities/models/reports';
import { useGetRegistrationsFunnel } from '../../hooks/use-queries';

const FunnelContainer = styled('div')(({ theme }) => ({
    height: 200,
    padding: theme.spacing(2),
}));

const LegendContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    height: 32,
    justifyContent: 'space-evenly',
    padding: theme.spacing(4, 4),
}));

const RegistrationsFunnel: FC = () => {
    const { isLoading, data } = useGetRegistrationsFunnel();
    const legend: { title: RegistrationFunnelBucket; color: string }[] = [
        { title: 'EMAIL_VERIFICATION', color: 'rgba(251, 180, 174, 1)' },
        { title: 'ACCOUNT', color: 'rgba(179, 205, 227, 1)' },
        { title: 'QUIZ', color: 'rgba(204, 235, 197, 1)' },
        { title: 'PERSONAL_DATA', color: 'rgba(222, 203, 228, 1)' },
        { title: 'VIDEO_IDENT', color: 'rgba(254, 217, 166, 1)' },
        { title: 'ONBOARDING_COMPLETED', color: 'rgba(255, 255, 204, 1)' },
        { title: 'ONBOARDING_REJECTED', color: 'rgba(229, 216, 189, 1)' },
    ];

    const funnelData: FunnelDatum[] = legend.reduce((result, { title }) => [...result, { value: data?.[title] || 0, id: title, label: title }], []);
    return (
        <>
            <FunnelContainer>
                <Typography variant="h6">Registrations Funnel</Typography>
                {!isLoading && data ? (
                    <ResponsiveFunnel
                        data={funnelData}
                        margin={{ top: 20, right: 20, bottom: 20, left: 0 }}
                        colors={{ scheme: 'pastel1' }}
                        borderWidth={20}
                        labelColor={{
                            from: 'color',
                            modifiers: [['darker', 3]],
                        }}
                        direction="horizontal"
                        motionConfig="wobbly"
                    />
                ) : null}
            </FunnelContainer>
            <LegendContainer>
                {legend.map(item => {
                    return (
                        <Fragment key={item.title}>
                            <div style={{ width: 24, height: 24, backgroundColor: item.color }} />
                            <p style={{ float: 'right', fontSize: 12, lineHeight: '24px' }}>{item.title}</p>
                        </Fragment>
                    );
                })}
            </LegendContainer>
        </>
    );
};

export default RegistrationsFunnel;
