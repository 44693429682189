import { intlDateFormat } from '@condo/formatters';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { LoadingButton } from '@mui/lab';
import { Avatar, Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { TextField } from 'formik-mui';
import _startCase from 'lodash/startCase';
import _trimEnd from 'lodash/trimEnd';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { SIZE_LIMIT_BYTES } from '../../../../../constants';
import type { BrandName } from '../../../../../domain-entities/models/base';
import type { FileSettingValue, ISetting } from '../../../../../domain-entities/models/settings';
import { FileUploader } from '../../../components/FileUploader';
import { useUploadDocumentMutation } from '../../../hooks/use-mutations';
import { useGetDocument } from '../../../hooks/use-queries';
import type { SettingsCategory } from '.prisma/client';

interface Props {
    fileName: string;
    accept: 'pdf' | 'docx';
    category: SettingsCategory;
    setting?: ISetting<FileSettingValue>;
    brand?: BrandName;
}

const acceptMap = {
    pdf: {
        mimeType: 'application/pdf',
        icon: PictureAsPdfIcon,
    },
    docx: {
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        icon: TextSnippetIcon,
    },
};

const parseVersionFromName = (name: string): string | null => {
    if (name.includes('_')) {
        const matched = /_v(?<version>\d+\.?\d*\.?\d*)/gm.exec(name);
        if (matched?.groups.version) {
            return _trimEnd(matched.groups.version, '.');
        }
    }
    return null;
};

const AddDocumentForm: FC<Props> = ({ fileName, category, accept, setting, brand }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { data: documentBuffer, isLoading: settingsIsLoading } = useGetDocument({ brand, key: fileName });
    const uploadMutation = useUploadDocumentMutation(() => {
        closeSnackbar();
        enqueueSnackbar('Upload is successful', { variant: 'success' });
    });
    const { mimeType, icon: Icon } = acceptMap[accept];
    const onPreview = () => {
        // @ts-expect-error
        const fileBlob = new Blob([documentBuffer], { type: mimeType });
        const url = URL.createObjectURL(fileBlob);
        window.open(url, '_blank');
    };

    const fieldFileName = fileName;
    const fieldVersion = `${fileName}-version`;
    const formik = useFormik({
        initialValues: { [fieldFileName]: '', [fieldVersion]: setting?.value.version || '', tempFileId: '' },
        onSubmit: async values => {
            // @ts-expect-error
            if (values[fieldFileName] instanceof File) {
                if ((values[fieldFileName] as unknown as File).size > SIZE_LIMIT_BYTES) {
                    enqueueSnackbar('You are exceeding the allowed size limit, please try making the photos smaller or upload in batches', {
                        color: 'error',
                    });
                    return;
                }
                const { tempFileId, [fieldVersion]: version } = values;
                uploadMutation.mutate({ key: fileName, category, version, brand, tempFileId });
            }
        },
    });
    const { isSubmitting, handleSubmit, setFieldValue, values } = formik;
    return (
        <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, m: 2, mt: brand ? 0 : 2, alignItems: 'center', justifyContent: 'space-between' }}>
                    <Avatar>
                        <Icon />
                    </Avatar>
                    <Typography variant="subtitle1" width="60%">
                        {_startCase(fileName)}
                        {setting ? (
                            <Typography variant="caption">
                                (Last Modified: {intlDateFormat(setting.updatedAt || setting.createdAt, true)}, {`version: ${setting.value.version || 'n/a'})`}
                            </Typography>
                        ) : null}
                    </Typography>
                    <FileUploader
                        allowedFileTypes={[mimeType]}
                        id={`${fileName}-uploader`}
                        onUploadSucceed={([file], [fileData]) => {
                            setFieldValue(fieldFileName, fileData);
                            setFieldValue('tempFileId', file.id);
                            const parsedVersion = parseVersionFromName(fileData.name);
                            if (parsedVersion) {
                                setFieldValue(fieldVersion, parsedVersion);
                            }
                        }}
                        variant="compact"
                        showItems
                    />
                    <Field
                        name={fieldVersion}
                        type="text"
                        value={values[fieldVersion] || setting?.value.version || ''}
                        placeholder="Version"
                        size="small"
                        sx={{ width: '80px' }}
                        component={TextField}
                    />
                    <LoadingButton variant="contained" color="primary" type="submit" loading={isSubmitting}>
                        Upload
                    </LoadingButton>
                    {!settingsIsLoading && documentBuffer ? (
                        <Button color="secondary" onClick={onPreview}>
                            Preview
                        </Button>
                    ) : null}
                </Box>
            </Form>
        </FormikProvider>
    );
};

export default AddDocumentForm;
