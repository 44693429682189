import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import type { IAuthResult, IUser } from '../../infrastructure/api/AuthService';
import { useDI } from './use-di';

interface IAuthProvider {
    user: IUser;
    logInWithEmail: (email: string, password: string) => Promise<boolean>;
    logInWithProvider: () => Promise<boolean>;
    logOut: () => void;
    isLoggedIn: () => boolean;
}

const authContext = createContext<IAuthProvider>({} as any);

export const ProvideAuth: FC<PropsWithChildren<any>> = ({ children }) => {
    const auth = useAuthProvider();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
    return useContext(authContext);
};

function useAuthProvider(): IAuthProvider {
    const { authService, apiClient } = useDI();
    const [user, setUser] = useState<IUser>(null);

    const signInBackend = async (authResponse: IAuthResult) => {
        const { user, result } = authResponse;
        if (result !== 'success') {
            return false;
        }

        try {
            const { result: authResult } = await apiClient.signIn();
            setUser(user);
            if (authResult === 'success') {
                return true;
            }
            await authService.logOut();
            return false;
        } catch (error) {
            await authService.logOut();
            return false;
        }
    };

    const logInWithEmail = async (email: string, password: string) => {
        const response = await authService.loginWithEmailPassword(email, password);
        return signInBackend(response);
    };

    const logInWithProvider = async () => {
        const response = await authService.loginWithOneLogin();
        return signInBackend(response);
    };

    const logOut = () => authService.logOut();
    const isLoggedIn = () => authService.isLoggedIn();

    useEffect(() => {
        const unsubscribe = authService.on('state-change:user', setUser);
        return () => unsubscribe();
    }, []);

    // Return the user object and auth methods
    return {
        user,
        logInWithEmail,
        logInWithProvider,
        logOut,
        isLoggedIn,
    };
}
