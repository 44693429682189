import { InputAdornment } from '@mui/material';
import { FastField as Field } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import { DatePicker } from 'formik-mui-x-date-pickers';
import ContentEditor from '../../../../components/ContentEditor';
import { defaultValues } from '../../constants';
import type { TDetailsKeys } from '../ProjectDetailsForm';
import MultiSelect from './MultiSelect';
import TextField from './TextField';

export function render(key: TDetailsKeys, label: string, props?: Record<string, any>, multiSelectOptions?: string[]) {
    const { format } = defaultValues.details[key];
    return renderControl(`${key}`, label, multiSelectOptions ? 'multiselect' : format, props, multiSelectOptions);
}

export function renderControl(
    name: string,
    label: string,
    type = '',
    extraProps?: { adornment?: string } & Record<string, any>,
    multiSelectOptions?: string[],
) {
    const props: Record<string, any> = {
        name,
        label,
        component: FormikTextField,
    };

    const { adornment, ...restProps } = extraProps || {};
    if (adornment) {
        props.InputProps = { startAdornment: <InputAdornment position="start">{adornment}</InputAdornment> };
    }

    if (restProps) {
        Object.assign(props, restProps);
    }
    switch (type.toLowerCase()) {
        case 'date':
            props.component = DatePicker;
            props.inputProps = { readOnly: true };
            return <Field fullWidth {...props} name={`${name}.value`} type={type} />;

        case 'richtext':
            return <ContentEditor label={label} name={`${name}.value`} />;

        case 'multiselect':
            return <MultiSelect name={name} label={label} optionsList={multiSelectOptions} />;
        default:
            props.type = type.toLowerCase();
            return <TextField name={name} label={label} type={props.type} inputProps={props.InputProps} />;
    }
}
