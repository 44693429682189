import type { GetDataListQuery } from '@condo/invest-utils';
import type { AxiosError } from 'axios';
import _omit from 'lodash/omit';
import { useMutation, useQueryClient } from 'react-query';
import type { BrandName, EntityDetails } from '../../../domain-entities/models/base';
import type { IEstateDetails } from '../../../domain-entities/models/estate';
import type { IProject, IProjectDetails } from '../../../domain-entities/models/project';
import type { ISettings } from '../../../domain-entities/models/settings';
import type {
    GenericResponse,
    GetAdminUserResponse,
    GetDistributionResponse,
    GetEstateResponse,
    GetPayoutReportResponse,
    SaveAdminUserSchemaRequest,
    SettingsBatchUpdateRequest,
} from '../../../schemas';
import type { ImportBankTransactionsResponse } from '../../../schemas/bank-transaction';
import { normalizeData } from '../helpers/normalizeData';
import type { AssetMedia } from '../pages/project/types';
import { useDI } from './use-di';
import useOnError from './use-on-error';
import type { PaymentRequestStatus, PaymentRequestType, SettingsCategory } from '.prisma/client';

type TSaveProjectVars = { project: Omit<IProject, 'status'>; details?: EntityDetails[] } | null;
interface TSaveEstateVars {
    id?: string;
    projectId?: string;
    estateDetails: IEstateDetails[];
}

export const useCancelOrderMutation = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<unknown, unknown, { orderCode: string; userId: string; orderId: string }>(
        ['cancelOrder'],
        ({ orderCode, userId }) => {
            return apiClient.cancelOrder(orderCode, userId);
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess(data, variables) {
                onSuccess?.();
                queryClient.invalidateQueries(['order', variables.orderId]);
            },
        },
    );

    return mutation;
};

export const useSettleOrderMutation = (onSuccess?: () => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();
    const errorPopup = useOnError();

    const mutation = useMutation<GenericResponse, AxiosError, string>(['settleOrder'], orderId => apiClient.settleOrder(orderId), {
        onError(error) {
            errorPopup(error);
            mutation.reset();
        },
        onSuccess(data, orderId) {
            onSuccess?.();
            queryClient.invalidateQueries(['order', orderId]);
        },
    });

    return mutation;
};

export const usePublishProjectMutation = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();

    const mutation = useMutation<unknown, unknown, { projectId: string }>(
        ['publishProject'],
        ({ projectId }) => {
            return apiClient.publishProject(projectId);
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess() {
                onSuccess?.();
            },
        },
    );

    return mutation;
};

export const useGenerateSepaXmlMutation = (onSuccess?: (data: any) => void, onError?: (error) => void) => {
    const { apiClient } = useDI();

    const mutation = useMutation<unknown, unknown, { listQuery: GetDataListQuery }>(
        ['sepaXML'],
        ({ listQuery }) => {
            return apiClient.exportSepaXml(listQuery);
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess(data) {
                onSuccess?.(data);
            },
        },
    );

    return mutation;
};

export const useSaveProjectMutation = (onSuccess?: (projectId: string) => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<IProject, unknown, TSaveProjectVars>(
        ['saveProject'],
        async ({ project, details }: TSaveProjectVars) => {
            const normalizedProject = normalizeData(project);

            let updatedProject: IProject;
            if (project.id) {
                updatedProject = await apiClient.updateProject(normalizedProject, details as Required<IProjectDetails>[]);
            } else {
                updatedProject = await apiClient.createProject(normalizedProject);
            }
            return updatedProject;
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess(data) {
                onSuccess(data.id);
                queryClient.invalidateQueries('projects');
                queryClient.invalidateQueries(['project', data.id]);
            },
        },
    );

    return mutation;
};

export const useDeleteProjectMutation = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<unknown, unknown, string>(
        ['deleteProject'],
        id => {
            return apiClient.deleteProject(id);
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess() {
                onSuccess?.();
                queryClient.invalidateQueries(['PROJECTS']);
            },
        },
    );

    return mutation;
};

export const useUpdateUserRestrictionsMutation = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();

    const mutation = useMutation<unknown, unknown, { userId: string; restrictions: Record<string, boolean> }>(
        ['updateUserRestrictions'],
        ({ userId, restrictions }) => {
            return apiClient.updateUserRestrictions(userId, restrictions);
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess() {
                onSuccess?.();
            },
        },
    );

    return mutation;
};

export const useDeleteUserMutation = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<unknown, unknown, string>(
        ['deleteUser'],
        id => {
            return apiClient.deleteUser(id);
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            async onSuccess(_, userId) {
                onSuccess?.();
                await queryClient.invalidateQueries(['user', userId]);
                await queryClient.invalidateQueries(['users']);
            },
        },
    );

    return mutation;
};

export const useRequestUserOffboardingMutation = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<unknown, unknown, string>(
        ['userOffboarding'],
        id => {
            return apiClient.requestManualOffboarding(id);
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            async onSuccess(_, userId) {
                await queryClient.invalidateQueries(['user', userId]);
                await queryClient.invalidateQueries(['users']);
                onSuccess?.();
            },
        },
    );

    return mutation;
};

export const useSaveEstateMutation = (onSuccess?: (estateId: string) => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<GetEstateResponse, Error, TSaveEstateVars & { estateMediaFiles: Partial<AssetMedia> }>(
        ['saveEstate'],
        async ({ id, projectId, estateDetails, estateMediaFiles }) => {
            let estateId = id;
            if (estateId) {
                await apiClient.updateEstate(projectId, id, estateDetails as Required<IEstateDetails>[], estateMediaFiles);
            } else if (projectId) {
                const data = await apiClient.createEstate(projectId, estateDetails, estateMediaFiles);
                estateId = data.id;
            }
            return { id: estateId };
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess(data) {
                onSuccess?.(data.id);
                queryClient.invalidateQueries('estates');
                queryClient.invalidateQueries(['estate', data.id]);
            },
        },
    );

    return mutation;
};

export const useSaveProjectDocuments = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<GetEstateResponse, Error, TSaveEstateVars & { documents: Partial<AssetMedia> }>(
        ['saveProjectDocuments'],
        async ({ projectId, documents }) => {
            if (projectId) {
                await apiClient.saveProjectDocuments(projectId, documents);
            }
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess(_data, { projectId }) {
                queryClient.invalidateQueries(['project', projectId]);
                onSuccess?.();
            },
        },
    );

    return mutation;
};

export const useCreatePayoutReportMutation = (onSuccess?: (payoutReportId: string) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();
    const onError = useOnError();

    const mutation = useMutation<GetPayoutReportResponse, Error, any>(
        ['createPayoutReport'],
        async input => {
            return apiClient.createPayoutReport(_omit(input, ['project', 'checksum']));
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess(data) {
                onSuccess?.(data.id);
                queryClient.invalidateQueries(['payoutReports']);
            },
        },
    );

    return mutation;
};

export const useUpdatePayoutReportMutation = (onSuccess?: (payoutReportId: string) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();
    const onError = useOnError();

    const mutation = useMutation<GetPayoutReportResponse, Error, any>(
        ['updatePayoutReport'],
        async ({ id, data }) => {
            return apiClient.updatePayoutReport(id, _omit(data, ['project', 'checksum']));
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess(data) {
                onSuccess?.(data.id);
                queryClient.invalidateQueries(['payoutReports']);
                queryClient.invalidateQueries(['payoutReport', data.id]);
            },
        },
    );

    return mutation;
};

export const usePreparePayoutReport = (onSuccess?: (payoutReportId: string) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();
    const onError = useOnError();

    const mutation = useMutation<GetPayoutReportResponse, Error, string>(
        ['preparePayoutReport'],
        async id => {
            return apiClient.preparePayoutReport(id);
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess(_, payoutReportId) {
                onSuccess?.(payoutReportId);
                queryClient.invalidateQueries(['payoutReports']);
                queryClient.invalidateQueries(['payoutReport', payoutReportId]);
                queryClient.invalidateQueries(['payoutReportStats', payoutReportId]);
            },
        },
    );

    return mutation;
};

export const useMarkDraftPayoutReport = (onSuccess?: (payoutReportId: string) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();
    const onError = useOnError();

    const mutation = useMutation<GetPayoutReportResponse, Error, string>(['preparePayoutReport'], async id => apiClient.markDraftPayoutReport(id), {
        onError(error) {
            onError?.(error);
            mutation.reset();
        },
        onSuccess(_, payoutReportId) {
            onSuccess?.(payoutReportId);
            queryClient.invalidateQueries(['payoutReports']);
            queryClient.invalidateQueries(['payoutReport', payoutReportId]);
            queryClient.invalidateQueries(['payoutReportStats', payoutReportId]);
        },
    });

    return mutation;
};

export const usePublishPayoutReport = (onSuccess?: (payoutReportId: string) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();
    const onError = useOnError();

    const mutation = useMutation<GetPayoutReportResponse, Error, any>(
        ['publishPayoutReport'],
        async id => {
            return apiClient.publishPayoutReport(id);
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess(data) {
                onSuccess?.(data.id);
                queryClient.invalidateQueries(['payoutReports']);
                queryClient.invalidateQueries(['payoutReport', data.id]);
            },
        },
    );

    return mutation;
};

export const useDeleteEstateMutation = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<unknown, unknown, { projectId: string; id: string }>(
        ['saveEstate'],
        ({ id, projectId }) => {
            return apiClient.deleteEstate(projectId, id);
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess() {
                onSuccess?.();
                queryClient.invalidateQueries('estates');
            },
        },
    );

    return mutation;
};

export const useGenerateAnnualTaxReportsMutation = ({ onSuccess }: { onSuccess?: (data: any) => any }) => {
    const { apiClient } = useDI();
    const onError = useOnError();

    const mutation = useMutation<void | Buffer, AxiosError, { userId?: string }>(
        ['generateAnnualTaxReport'],
        ({ userId }) => apiClient.generateAnnualTaxReports(userId),
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess(data) {
                onSuccess?.(data);
                return data;
            },
        },
    );

    return mutation;
};

export const useGenerateQuarterTaxReceiptMutation = ({ onSuccess }: { onSuccess?: () => void }) => {
    const { apiClient } = useDI();
    const onError = useOnError();

    const mutation = useMutation<void, AxiosError, string[]>(
        ['generateQuarterTaxReceipt'],
        userPayoutIds => apiClient.prepareQuarterTaxReceipts(userPayoutIds),
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess,
        },
    );

    return mutation;
};

export const useDeleteFileMutation = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<
        void,
        unknown,
        {
            type: 'TEMPORARY' | 'PROJECT_ESTATE_FILE' | 'PROJECT_DOCUMENT';
            fileId: string;
            projectId?: string;
            estateId?: string;
        }
    >(
        ['assetFile'],
        ({ type, fileId, projectId, estateId }) => {
            switch (type) {
                case 'TEMPORARY':
                    return apiClient.deleteTempFile(fileId);
                case 'PROJECT_ESTATE_FILE':
                    return apiClient.deleteEstateFile(projectId, estateId, fileId);
                case 'PROJECT_DOCUMENT':
                    return apiClient.deleteProjectDocument(projectId, fileId);
                default:
                    onError?.(new Error('File not support for deletion'));
            }
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            async onSuccess(_, { projectId }) {
                onSuccess?.();
                await Promise.all([queryClient.invalidateQueries('estates'), queryClient.invalidateQueries(['project', projectId])]);
            },
        },
    );

    return mutation;
};

export const useUploadDocumentMutation = (onSuccess?: () => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();
    const onError = useOnError();
    const mutation = useMutation<
        GenericResponse,
        AxiosError,
        {
            key: string;
            tempFileId: string;
            category: SettingsCategory;
            version?: string;
            brand?: BrandName;
        }
    >(['uploadDocument'], async params => apiClient.uploadDocument(params), {
        onError(error) {
            onError?.(error);
            mutation.reset();
        },
        onSuccess(_, params) {
            if (onSuccess) {
                onSuccess?.();
            }
            queryClient.invalidateQueries(['document', params.key]);
            queryClient.invalidateQueries(['settings', params.category]);
        },
    });

    return mutation;
};

export const useUploadPayoutReportMutation = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();
    const mutation = useMutation<void, unknown, { formData: FormData; payoutReportId: string }>(
        ['uploadPayoutReport'],
        async ({ formData, payoutReportId }) => {
            apiClient.uploadPayoutReport(formData, payoutReportId);
        },
        {
            onError(error) {
                if (onError) {
                    onError?.(error);
                }
                mutation.reset();
            },
            onSuccess(_, { payoutReportId }) {
                if (onSuccess) {
                    onSuccess?.();
                }
                queryClient.invalidateQueries(['payout-reports', payoutReportId]);
            },
        },
    );

    return mutation;
};

export const useSaveAdminUserMutation = (onSuccess?: (userId: string) => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<GetAdminUserResponse, unknown, SaveAdminUserSchemaRequest>(
        ['saveAdminUser'],
        user => {
            return apiClient.saveAdminUser(user);
        },
        {
            onError(error) {
                onError?.(error);
                mutation.reset();
            },
            onSuccess(data) {
                onSuccess?.(data.id);
                return Promise.all([queryClient.invalidateQueries('adminUsers'), queryClient.invalidateQueries(['adminUser', data.id])]);
            },
        },
    );

    return mutation;
};

export const useUpsertSettingMutation = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<GenericResponse, unknown, ISettings>(
        ['upsertSettings'],
        ({ key, value, format, category }) => {
            return apiClient.upsertSettings({ key, value, format, category });
        },
        {
            onSuccess() {
                onSuccess?.();
                return queryClient.invalidateQueries(['settings', 'PLATFORM']);
            },
            onError(err) {
                onError?.(err);
                mutation.reset();
            },
        },
    );

    return mutation;
};

export const useUpdatePaymentRequests = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<
        GenericResponse,
        unknown,
        {
            ids: string[];
            data: { status?: PaymentRequestStatus; type?: PaymentRequestType };
        }
    >(
        ['updatePaymentRequests'],
        ({ ids, data }: { ids: string[]; data: { status?: PaymentRequestStatus; type?: PaymentRequestType } }) => {
            return apiClient.updatePaymentRequests(ids, data);
        },
        {
            onSuccess() {
                if (onSuccess) {
                    onSuccess?.();
                }
                queryClient.invalidateQueries(['paymentRequestsStats', 'VOUCHER']);
                return queryClient.invalidateQueries(['PAYMENT_REQUESTS', 0, 25]);
            },
            onError(err) {
                if (onError) {
                    onError?.(err);
                }
                mutation.reset();
            },
        },
    );

    return mutation;
};

export const useBatchUpsertSettingMutation = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();

    const mutation = useMutation<GenericResponse, unknown, SettingsBatchUpdateRequest>(
        ['upsertSettings'],
        data => {
            return apiClient.batchUpsertSettings(data);
        },
        {
            onSuccess() {
                onSuccess?.();
            },
            onError(err) {
                onError?.(err);
                mutation.reset();
            },
        },
    );

    return mutation;
};

export const useImportBankTransactions = (onSuccess?: (data: ImportBankTransactionsResponse) => void, onError?: (error) => void) => {
    const { apiClient } = useDI();

    const mutation = useMutation<ImportBankTransactionsResponse, unknown, Record<string, string>[]>(
        ['importBankTransactions'],
        data => {
            return apiClient.importBankTransactions(data);
        },
        {
            onSuccess(data) {
                if (onSuccess) {
                    onSuccess?.(data);
                }
            },
            onError(err) {
                if (onError) {
                    onError?.(err);
                }
                mutation.reset();
            },
        },
    );

    return mutation;
};

export const useAssignOrderItem = (onSuccess?: (isSuccess: boolean) => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<
        GenericResponse,
        unknown,
        {
            orderId: string;
            orderItemId: string;
            bankTransactionId: string;
        }
    >(['findOrder'], data => apiClient.assignOrderItem(data), {
        onSuccess({ result }, { orderId, bankTransactionId }) {
            const isSuccess = result === 'success';
            if (isSuccess) {
                queryClient.invalidateQueries(['bankTransaction', bankTransactionId]);
                queryClient.invalidateQueries(['order', orderId]);
                queryClient.invalidateQueries('findOrder');
            }
            onSuccess?.(isSuccess);
        },
        onError(err) {
            if (onError) {
                onError?.(err);
            }
            mutation.reset();
        },
    });

    return mutation;
};

export const useResendEffectaUser = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();

    const mutation = useMutation<GenericResponse, unknown, string>(['resendEffectaUser'], userId => apiClient.resendUserToEffecta(userId), {
        onSuccess,
        onError(err) {
            onError?.(err);
            mutation.reset();
        },
    });

    return mutation;
};

export const useResendEffectaOrder = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();

    const mutation = useMutation<GenericResponse, unknown, { userId: string; orderId: string }>(
        ['resendEffectaOrder'],
        ({ userId, orderId }) => apiClient.resendOrderToEffecta(userId, orderId),
        {
            onSuccess,
            onError(err) {
                onError?.(err);
                mutation.reset();
            },
        },
    );

    return mutation;
};

export const useCompletePaymentRequest = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<GenericResponse, unknown, string>(
        ['completePaymentRequest'],
        paymentRequestId => apiClient.completePaymentRequest(paymentRequestId),
        {
            onSuccess(_, paymentRequestId) {
                queryClient.invalidateQueries(['paymentRequestsStats']);
                queryClient.invalidateQueries(['paymentRequest', paymentRequestId]);
                if (onSuccess) {
                    onSuccess?.();
                }
            },
            onError(err) {
                onError?.(err);
                mutation.reset();
            },
        },
    );

    return mutation;
};

export const useRejectPaymentRequest = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<GenericResponse, unknown, string>(
        ['rejectPaymentRequest'],
        paymentRequestId => apiClient.rejectPaymentRequest(paymentRequestId),
        {
            onSuccess(_, paymentRequestId) {
                queryClient.invalidateQueries(['paymentRequestsStats']);
                queryClient.invalidateQueries(['paymentRequest', paymentRequestId]);
                if (onSuccess) {
                    onSuccess?.();
                }
            },
            onError(err) {
                onError?.(err);
                mutation.reset();
            },
        },
    );

    return mutation;
};

export const useRescheduleJobMutation = (onSuccess?: () => void, onError?: (error) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    const mutation = useMutation<void, unknown, string>(['rescheduleJob'], id => apiClient.rescheduleJob(id), {
        onSuccess(_, id) {
            queryClient.invalidateQueries(['jobs']);
            queryClient.invalidateQueries(['job', id]);
            if (onSuccess) {
                onSuccess?.();
            }
        },
        onError(err) {
            onError?.(err);
            mutation.reset();
        },
    });

    return mutation;
};

export const useRunDistributionPreparation = (onSuccess?: (distribution: GetDistributionResponse) => void) => {
    const { apiClient } = useDI();
    const queryClient = useQueryClient();
    const errorPopup = useOnError();

    const mutation = useMutation<GetDistributionResponse, AxiosError, { projectId: string; distributionId?: string }>(
        ['runDistributionPreparation'],
        data => apiClient.runDistributionPreparation(data),
        {
            onSuccess(data, { distributionId }) {
                queryClient.invalidateQueries(['getDistribution', distributionId]);
                if (onSuccess) {
                    onSuccess?.(data);
                }
            },
            onError(error) {
                errorPopup(error);
                mutation.reset();
            },
        },
    );

    return mutation;
};
