import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.min.css';
import { Dashboard, StatusBar, DragDrop as UppyDragDrop } from '@uppy/react';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { SIZE_LIMIT_BYTES } from '../../../constants';
import type { ITempFile } from '../../../domain-entities/models/tempFile';
import { useFileUploader } from '../hooks/use-file-uploader';
import useOnError from '../hooks/use-on-error';

type Variant = 'default' | 'compact' | 'dashboard';
interface Props {
    id: string;
    variant?: Variant;
    onUploadSucceed: (files: ITempFile[], filesData: File[]) => void;
    showItems?: boolean;
    allowedFileTypes?: string[];
    multiple?: boolean;
}

const icons = {
    'application/pdf': PictureAsPdfIcon,
};

const DragDrop = styled(UppyDragDrop)(({ theme }) => ({
    '& .uppy-DragDrop-arrow': { marginTop: '5px' },
    '& .uppy-DragDrop-inner': {
        padding: '0 60px',
    },
    '& .uppy-DragDrop-label': {
        color: theme.palette.text.primary,
        fontSize: theme.typography.body1.fontSize,
        fontFamily: theme.typography.body1.fontFamily,
    },
    '& .uppy-DragDrop-browse': {
        color: theme.palette.primary.main,
    },
    '& .uppy-DragDrop--isDraggingOver': {
        backgroundColor: theme.palette.grey[500_8],
        borderColor: theme.palette.primary.main,
    },
    '& .uppy-DragDrop--isDragDropSupported': {
        borderWidth: '1px',
    },
}));

const DragDropCompact = styled(DragDrop)(({ theme }) => ({
    '& .uppy-DragDrop-arrow': { display: 'none' },
    '& .uppy-DragDrop-inner': {
        padding: '0 10px',
    },
    '& .uppy-DragDrop-label': {
        fontSize: theme.typography.body2.fontSize,
        margin: 0,
    },
}));

const components = {
    default: DragDrop,
    compact: DragDropCompact,
    dashboard: Dashboard,
};

export const FileUploader: FC<Props> = ({ id, variant = 'default', showItems, onUploadSucceed, allowedFileTypes, multiple = false, ...props }) => {
    const onError = useOnError();
    const [attachedFiles, setAttachedFiles] = useState<{ name: string; mimeType: string; link: string }[]>([]);
    const fileUploader = useFileUploader(id, {
        multiple,
        restrictions: { allowedFileTypes, maxNumberOfFiles: multiple ? 10 : 1, maxFileSize: SIZE_LIMIT_BYTES },
    });
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        fileUploader.on('complete', result => {
            setAttachedFiles(
                result.successful.map(
                    ({
                        meta: { name, type: mimeType },
                        response: {
                            // @ts-expect-error
                            body: [{ link }],
                        },
                    }) => ({ name, mimeType, link }),
                ),
            );
            enqueueSnackbar('File is attached', { variant: 'info' });
            if (onUploadSucceed) {
                onUploadSucceed(
                    result.successful.map(item => item.response.body[0] as ITempFile),
                    result.successful.map(item => item.data as File),
                );
            }
        });
        fileUploader.on('error', onError);
        fileUploader.on('upload-error', (_, error) => onError(error));
        return () => {
            fileUploader.close();
        };
    }, [fileUploader, enqueueSnackbar, onUploadSucceed, onError]);

    const Component = components[variant] || components.default;
    return (
        <div>
            <Component uppy={fileUploader} {...props} />
            <StatusBar uppy={fileUploader} />
            {attachedFiles.length && showItems ? (
                <Box sx={{ mt: variant === 'compact' ? 0 : 2 }}>
                    {attachedFiles.map(data => (
                        <FileIcon key={data.link} {...data} variant={variant} />
                    ))}
                </Box>
            ) : null}
        </div>
    );
};

const FileIcon: FC<{ link: string; name: string; mimeType: string; variant?: Variant }> = ({ link, name, mimeType, variant }) => {
    const Icon = icons[mimeType] || PhotoLibraryIcon;
    if (variant === 'compact') {
        return (
            <Typography fontSize={10} textAlign="center" whiteSpace="nowrap" textOverflow="ellipsis" maxWidth="120px" overflow="hidden">
                <b>{name}</b>
            </Typography>
        );
    }

    return (
        <Paper sx={{ maxWidth: 100 }}>
            <Box sx={{ p: 1, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <Link href={`/document?link=${link}`} target="_blank" rel="noreferrer">
                    <Icon sx={{ fontSize: 40 }} />
                </Link>
                <Typography fontSize={10}>
                    <b>{name}</b>
                </Typography>
            </Box>
        </Paper>
    );
};
