import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { IBankTransaction } from '../../../../../domain-entities/models/bank-transaction';
import type { DataGridProps } from '../../../components/DataGrid';
import DataGrid, { useGridColumns } from '../../../components/DataGrid';
import StatusChip from '../../../components/bankTransaction/StatusChip';
import PageWrapper from '../../PageWrapper';

interface Props {
    title: string;
}
const BankTransactionsList: FC<Props> = ({ title }) => {
    const columns = useGridColumns<IBankTransaction>([
        {
            accessorKey: 'status',
            header: 'Status',
            minSize: 100,
            filterVariant: 'multi-select',
            filterSelectOptions: ['PENDING', 'RECONCILED', 'SKIPPED'],
            Cell: ({ row }) => <StatusChip status={row.original.status} />,
        },
        {
            accessorKey: 'orderCode',
            header: 'Order Code',
            minSize: 100,
            enableColumnFilter: false,
            Cell: ({ row }) => {
                if (!row.original.orderId) {
                    return 'n/a';
                }
                return <a href={`/orders/view/${row.original.orderId}`}>{row.original.orderCode}</a>;
            },
        },
        {
            accessorKey: 'description',
            header: 'Description',
            minSize: 250,
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            minSize: 100,
            dataType: 'currency',
        },
        {
            accessorKey: 'bankAccountIban',
            header: 'Bank Account IBAN',
            minSize: 220,
        },
        {
            accessorKey: 'entryDate',
            header: 'Entry Date',
            dataType: 'date',
        },
        {
            accessorKey: 'createdAt',
            header: 'Created At',
            dataType: 'date',
        },
        {
            accessorKey: 'source',
            header: 'Data Source',
            filterVariant: 'multi-select',
            filterSelectOptions: ['apiImport', 'manualImport'],
        },
    ]);

    const dataGridProps: DataGridProps = {
        columns,
        dataFetch: { key: 'BANK_TRANSACTIONS' },
        actions: {
            view(row) {
                window.open(`/bank-transactions/view/${row.id}`, '_blank');
            },
        },
    };

    return (
        <PageWrapper
            title={title}
            actionButtons={
                <>
                    <Button variant="contained" component={RouterLink} to="/bank-transactions/import" startIcon={<UploadIcon />} sx={{ mx: 1 }}>
                        Import
                    </Button>
                    <Button variant="text" component={RouterLink} to="/bank-transactions/imports-history">
                        Imports History
                    </Button>
                </>
            }
        >
            <Card>
                <DataGrid {...dataGridProps} />
            </Card>
        </PageWrapper>
    );
};
export default BankTransactionsList;
