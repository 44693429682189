import _ from 'lodash';
import { SettingKey } from './domain-entities/models/settings';
import type { SettingsCategory } from '.prisma/client';

export const DRAWER_WIDTH = 200;
export const APPBAR_MOBILE = 64;
export const APPBAR_DESKTOP = 92;
export const GENDER_LIST = ['MALE', 'FEMALE', 'OTHER'];
export const SIZE_LIMIT_BYTES = 10000000; // ~ 10 MB

export const BrandedSettings: SettingKey[] = [SettingKey.SupportEmail, SettingKey.PlatformAgb];
// TODO: fix enums build issue to reuse `SettingKey` enum
export const SettingDocumentKeys: Record<Extract<SettingsCategory, 'DOCUMENTS' | 'TEMPLATES'>, string[]> = {
    DOCUMENTS: ['effectaAgb', 'effectaCustomerInfo', 'effectaConsumerInfo', 'tanganyAgb', 'platformAgb'],
    TEMPLATES: [
        'quizSummaryTemplate',
        'quizFailedTemplate',
        'quizSkippedTemplate',
        'investmentReceiptTemplate',
        'projectCostsCalculationTemplate',
        'investmentReceiptFinalTemplate',
        'annualTaxReportTemplate',
        'quarterTaxReceiptTemplate',
    ],
};

export const userStatusColor = {
    ONBOARDING_INPROGRESS: 'info',
    ONBOARDING_COMPLETED: 'success',
    ONBOARDING_REJECTED: 'warning',
    ON_HOLD: 'warning',
};

export const USER_STATUS_LIST = ['CREATED', 'ONBOARDING_INPROGRESS', 'ONBOARDING_COMPLETED', 'ONBOARDING_REJECTED', 'DELETION_PENDING', 'DELETED', 'ON_HOLD'];
export const LAST_COMPLETED_STEP_LIST = ['EMAIL_VERIFICATION', 'ACCOUNT', 'QUIZ', 'PERSONAL_DATA', 'VIDEO_IDENT'];

export const brandsList = ['IS24', 'TECIS', 'INVESTABLE'] as const;
export type Brands = (typeof brandsList)[number];

export const brand: Record<Brands, Brands> = _.zipObject(brandsList, brandsList) as Record<Brands, Brands>;

export const DEFAULT_CURRENCY = 'EUR';
