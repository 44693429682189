import type { FC } from 'react';
import type { DataGridProps } from '../../DataGrid';
import DataGrid, { useGridColumns } from '../../DataGrid';
import Section from '../../Section';

interface Props {
    userId: string;
}

const UserPaymentRequests: FC<Props> = ({ userId }) => {
    const columns = useGridColumns<any>([
        {
            accessorKey: 'order.project.code',
            header: 'Project Code',
            minSize: 150,
        },
        {
            accessorKey: 'order.code',
            header: 'Order code',
            minSize: 150,
        },
        {
            accessorKey: 'status',
            header: 'Status',
            minSize: 150,
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            minSize: 150,
            dataType: 'currency',
        },
        {
            accessorKey: 'type',
            header: 'Type',
            minSize: 150,
        },
        {
            accessorKey: 'createdAt',
            header: 'Created At',
            dataType: 'date',
        },
    ]);

    const dataGridProps: DataGridProps = {
        columns,
        dataFetch: { key: 'PAYMENT_REQUESTS', params: { userId } },
        actions: {
            view(row) {
                window.open(`/payment-requests/view/${row.id}`, '_blank');
            },
        },
    };

    return (
        <Section title="Payment Requests">
            <DataGrid {...dataGridProps} />
        </Section>
    );
};

export default UserPaymentRequests;
