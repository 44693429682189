import { supportedPrismaOperators } from '@condo/invest-utils';
import type { GridDataType } from '../types';

const mapToPrismaOperators = {
    fuzzy: 'contains',
    arrIncludesSome: 'in',
    greaterThan: 'gt',
    greaterThanOrEqualTo: 'gte',
    lessThan: 'lt',
    lessThanOrEqualTo: 'lte',
    betweenInclusive(value: number[]) {
        if (typeof value[0] !== 'number' || typeof value[1] !== 'number') {
            return null;
        }
        return {
            gte: value[0],
            lte: value[1],
        };
    },
};

export const getFilterQueryByTable = (tableRef: any) => {
    const query = {};
    if (tableRef) {
        tableRef.getAllColumns().forEach(col => {
            const filterValue = col.getFilterValue();
            const { dataType, _filterFn: filterFn, accessorKey, id } = col.columnDef;
            const columnKey = accessorKey || id;
            if (!filterValue || !filterFn) {
                return;
            }

            const parsedValue = normalizeFilterValue(dataType as GridDataType, filterValue);

            if (!supportedPrismaOperators.includes(filterFn)) {
                const prismaFilterFn = mapToPrismaOperators[filterFn] || filterFn;
                const filterExpression = typeof prismaFilterFn === 'function' ? prismaFilterFn(parsedValue) : { [prismaFilterFn]: parsedValue };
                if (filterExpression) {
                    query[columnKey] = filterExpression;
                }
                return;
            }

            if (['isDate', 'betweenDates'].includes(filterFn)) {
                query[columnKey] = parsedValue;
            } else {
                query[columnKey] = { [filterFn]: parsedValue };
            }

            if (typeof query[columnKey][filterFn] === 'string') {
                query[columnKey] = { ...query[columnKey], mode: 'insensitive' };
            }
        });
    }
    return query;
};

function normalizeFilterValue(dataType: GridDataType, value: string | string[]) {
    switch (dataType) {
        case 'number':
            return Array.isArray(value) ? value.map(v => v && Number.parseInt(v, 10)) : value && Number.parseInt(value, 10);

        default:
            return value;
    }
}
