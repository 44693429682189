import { iban, intlDateFormat, taxIdFormat } from '@condo/formatters';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import countries from 'i18n-iso-countries';
// @ts-expect-error
import enCountries from 'i18n-iso-countries/langs/en.json';
import type { FC } from 'react';
import type { GetUserFullDataResponse } from '../../../../../schemas';
import DetailsItem from '../../DetailsItem';
import Section from '../../Section';

countries.registerLocale(enCountries);

const getCountryName = (code: string) => code && countries.getName(code, 'en', { select: 'official' });
interface Props {
    user: GetUserFullDataResponse;
}

const itemProps = { item: true, xs: 12, md: 6 };

// DE labels
const confessionMap = {
    ak: 'altkatholisch',
    ev: 'evangelisch',
    fg: 'freireligiöse gemeinde',
    fr: 'französisch reformiert',
    is: 'israelitisch',
    jd: 'jüdisch',
    lt: 'evangelisch lutherisch',
    no: 'keine Kirchensteuerpflicht',
    other: 'andere mit Kirchensteuerpflicht',
    rf: 'evangelisch reformiert',
    rk: 'römisch-katholisch',
    up: 'unitarisch-protestantisch',
};

const UserPersonalDataBox: FC<Props> = ({ user }) => {
    const { profile } = user;
    if (!profile) {
        return (
            <Section>
                <Typography variant="h6">No Profile Data</Typography>
            </Section>
        );
    }

    return (
        <Grid container spacing={1}>
            <Grid {...itemProps}>
                <Section title="Personal Details">
                    <DetailsItem title="Title" value={profile.title} />
                    <DetailsItem title="Gender" value={profile.gender} />
                    <DetailsItem title="First Name" value={profile.firstName} />
                    <DetailsItem title="Last Name" value={profile.lastName} />
                    <DetailsItem title="Birth Name" value={profile.birthName} />
                    <DetailsItem title="Birth Date" value={intlDateFormat(profile.birthday, false, 'short')} />
                    <DetailsItem title="Place of Birth" value={profile.birthPlace} />
                    <DetailsItem title="Country of Birth" value={getCountryName(profile.birthCountry)} />
                    <DetailsItem title="Family Status" value={profile.familyStatus} />
                    <DetailsItem title="Confession" value={confessionMap[profile.confession?.toLowerCase()]} />
                    <DetailsItem title="Nationality" value={getCountryName(profile.nationality)} />
                    <DetailsItem title="2nd Nationality" value={profile.nationality2 ? getCountryName(profile.nationality2) : null} />
                </Section>
            </Grid>
            <Grid {...itemProps}>
                <Section title="Tax">
                    <DetailsItem title="Tax Residency" value={getCountryName(profile.taxResidency)} />
                    <DetailsItem title="Tax ID" value={taxIdFormat(profile.taxId)} />
                    <DetailsItem title="Is taxable abroad" value={profile.isTaxableAbroad} />
                    <DetailsItem title="Tax Residency (2nd)" value={profile.taxResidency2 ? getCountryName(profile.taxResidency2) : null} />
                    <DetailsItem title="Tax ID (2nd)" value={profile.taxId2} />
                    <DetailsItem title="Is PEP" value={profile.isPoliticallyExposed} />
                    <DetailsItem title="PEP comment" value={profile.pepComment} />
                    <DetailsItem title="Is USA taxable" value={!profile.isNotUsaTaxable} />
                    <DetailsItem title="Investing As" value={profile.investingAs} />
                    <DetailsItem title="Investing For Myself" value={profile.investingForMyself} />
                </Section>
            </Grid>
            <Grid {...itemProps}>
                <Section title="Address">
                    <DetailsItem title="Country" value={getCountryName(profile.addressCountry)} />
                    <DetailsItem title="State" value={profile.addressState} />
                    <DetailsItem title="Zip" value={profile.addressZip} />
                    <DetailsItem title="City" value={profile.addressCity} />
                    <DetailsItem title="Street" value={profile.addressStreet} />
                    <DetailsItem title="Street 2" value={profile.addressStreet2} />
                    <DetailsItem title="Building #" value={profile.addressHouseNumber} />
                </Section>
            </Grid>
            <Grid {...itemProps}>
                <Section title="KYC">
                    <DetailsItem title="Document Type" value={profile.idDocument?.documentType} />
                    <DetailsItem title="ID Number" value={profile.idDocument?.idNumber} />
                    <DetailsItem title="Issue Date" value={iban(profile.idDocument?.issueDate)} />
                    <DetailsItem title="Nationality" value={profile.idDocument?.nationality} />
                    <DetailsItem title="Submitted At" value={profile.idDocument?.submittedAt} />
                </Section>
            </Grid>
            <Grid {...itemProps}>
                <Section title="Bank Details">
                    <DetailsItem title="Holder Name" value={profile.bankHolder} />
                    <DetailsItem title="Bank Name" value={profile.bankName} />
                    <DetailsItem title="IBAN" value={iban(profile.bankIban)} />
                    <DetailsItem title="BIC" value={profile.bankBic} />
                </Section>
            </Grid>
        </Grid>
    );
};

export default UserPersonalDataBox;
