import { Box, Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Page } from '../../../../components';
import AdminUserForm from '../shared/Form';

const AdminUserCreatePage: FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    function onSuccess(id: string) {
        enqueueSnackbar('User is created', { variant: 'success' });
        navigate(`/settings/admin-user/${id}`);
    }

    return (
        <Page title="Create App User">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4" gutterBottom>
                        Create App User
                    </Typography>

                    <Breadcrumbs items={{ '/settings': 'Settings', '/settings/admin-user': 'Users', '': 'New' }} />
                    <AdminUserForm onError={onError} onSuccess={onSuccess} />
                </Box>
            </Container>
        </Page>
    );
};

export default AdminUserCreatePage;
