import { Card } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import Loader from '../../components/Loader';
import MergingCategoriesForm from '../../components/MergingCategories/Form';
import { useGetSettings } from '../../hooks/use-queries';
import PageWrapper from '../PageWrapper';

interface MergingCategoriesPageProps {}

const MergingCategoriesPage: FunctionComponent<MergingCategoriesPageProps> = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { data: settings, isLoading } = useGetSettings('MERGING_CATEGORY');

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    function onSuccess() {
        enqueueSnackbar('Merging categories settings have been updated', { variant: 'success', preventDuplicate: true });
    }

    return (
        <PageWrapper title="Merging categories">
            <Card>{isLoading ? <Loader /> : <MergingCategoriesForm onError={onError} onSuccess={onSuccess} settings={settings || null} />}</Card>
        </PageWrapper>
    );
};

export default MergingCategoriesPage;
