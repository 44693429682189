import { intlDateFormat } from '@condo/formatters';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import QuizIcon from '@mui/icons-material/Quiz';
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedIcon from '@mui/icons-material/Verified';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Typography } from '@mui/material';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import type { IUserStep } from '../../../../../domain-entities/models/user-step';
import { useGetUserSteps } from '../../../hooks/use-queries';
import Loader from '../../Loader';
import Section from '../../Section';
import StepQuizDetails from './StepQuizDetails';

interface Props {
    userId: string;
}

const stepsList = [
    { type: 'EMAIL_VERIFICATION', title: 'Email Verification', icon: <EmailIcon /> },
    { type: 'ACCOUNT', title: 'Account Setup', icon: <SecurityIcon /> },
    { type: 'QUIZ', title: 'Quiz', icon: <QuizIcon />, DetailsComponent: StepQuizDetails },
    { type: 'PERSONAL_DATA', title: 'Personal Data Collection', icon: <PersonIcon /> },
    { type: 'VIDEO_IDENT', title: 'KYC', icon: <VerifiedIcon /> },
];

const UserSteps: FC<Props> = ({ userId }) => {
    const { isLoading, data: steps, error } = useGetUserSteps(userId);
    const { enqueueSnackbar } = useSnackbar();

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    if (!isLoading && error) {
        onError(error);
    }

    const groupedSteps = _keyBy<IUserStep>(steps, 'type');
    return (
        <Section title="Onboarding">
            {isLoading ? (
                <Loader />
            ) : _isEmpty(steps) ? (
                <Typography color="text.primary">No User Steps found</Typography>
            ) : (
                <Timeline>
                    {stepsList.map(step => {
                        const { createdAt, updatedAt, isCompleted } = groupedSteps[step.type] || {};

                        return (
                            <TimelineItem key={`list-${step.type}`}>
                                <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
                                    {createdAt ? (
                                        <>
                                            <Typography fontSize="xx-small">Started</Typography>
                                            <Typography fontSize="x-small">{intlDateFormat(createdAt, true)}</Typography>
                                        </>
                                    ) : null}

                                    {updatedAt ? (
                                        <>
                                            <Typography fontSize="xx-small">Last Updated</Typography>
                                            <Typography fontSize="x-small">{intlDateFormat(updatedAt, true)}</Typography>
                                        </>
                                    ) : null}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot color={isCompleted ? 'primary' : 'secondary'}>{step.icon}</TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="h6" component="span">
                                        {step.title}
                                    </Typography>
                                    <Typography variant="body2" color={isCompleted ? 'primary' : 'secondary'}>
                                        {isCompleted ? 'Completed' : createdAt ? 'In Progress' : null}
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })}
                </Timeline>
            )}
        </Section>
    );
};

export default UserSteps;
