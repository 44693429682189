import _merge from 'lodash/merge';
import Backdrop from './Backdrop';
import Breadcrumbs from './Breadcrumbs';
import Button from './Button';
import Card from './Card';
import Density from './Density';
import IconButton from './IconButton';
import Input from './Input';
import Lists from './Lists';
import Paper from './Paper';
import Tabs from './Tabs';
import Tooltip from './Tooltip';
import Typography from './Typography';

export default function ComponentsOverrides(theme) {
    return _merge(
        Card(theme),
        Lists(theme),
        Paper(),
        Input(theme),
        Button(theme),
        Tooltip(theme),
        Backdrop(theme),
        Typography(theme),
        IconButton(theme),
        Breadcrumbs(theme),
        Tabs(theme),
        Density(),
    );
}
