import { Stack } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import Section from './Section';

interface Props {
    title?: string;
}
const ActionsPanel: FC<PropsWithChildren<Props>> = ({ title = 'Actions', children }) => {
    return (
        <Section title={title} elevation={1} sx={theme => ({ backgroundColor: theme.palette.action.focus })}>
            <Stack direction="row" gap={2}>
                {children}
            </Stack>
        </Section>
    );
};

export default ActionsPanel;
