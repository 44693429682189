import { Field, useFormikContext } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import _get from 'lodash/get';
import type { FC } from 'react';
import { useState } from 'react';

interface ValidatedFieldProps {
    name: string;
    label: string;
    type: string;
    inputProps?: Record<string, any>;
}

const TextField: FC<ValidatedFieldProps> = ({ name, label, type, inputProps }) => {
    const props: Record<string, any> = {
        name,
        label,
        component: FormikTextField,
    };

    const { setFieldValue, values } = useFormikContext();
    const [inputVal, setInputVal] = useState(_get(values, `${name}.value`));
    props.type = type.toLowerCase();
    return (
        <Field
            fullWidth
            {...props}
            InputProps={inputProps}
            name={name}
            type={type}
            value={inputVal}
            onChange={e => {
                setInputVal(e.target.value);
                setFieldValue(`${name}.value`, e.target.value);
            }}
        />
    );
};

export default TextField;
