import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatStrikeIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import type { FC } from 'react';
import type React from 'react';
import { useState } from 'react';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

const MenuBar: FC<any> = ({ editor }) => {
    const [list, setList] = useState('');
    const [formats, setFormats] = useState(() => []);

    if (!editor) {
        return null;
    }

    const handleFormat = (_event: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
        setFormats(newFormats);
    };

    const handleList = (_event: React.MouseEvent<HTMLElement>, newList: string) => {
        setList(newList);
    };

    return (
        <Paper
            elevation={0}
            sx={{
                display: 'flex',
                border: theme => `1px solid ${theme.palette.divider}`,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                flexWrap: 'wrap',
            }}
        >
            <StyledToggleButtonGroup size="small" value={formats} onChange={handleFormat} aria-label="text formatting">
                <ToggleButton value="bold" aria-label="bold" onClick={() => editor.chain().focus().toggleBold().run()}>
                    <FormatBoldIcon />
                </ToggleButton>
                <ToggleButton value="italic" aria-label="italic" onClick={() => editor.chain().focus().toggleItalic().run()}>
                    <FormatItalicIcon />
                </ToggleButton>
                <ToggleButton value="underlined" aria-label="underlined" onClick={() => editor.chain().focus().toggleUnderline().run()}>
                    <FormatUnderlinedIcon />
                </ToggleButton>
                <ToggleButton value="strike" aria-label="strike" onClick={() => editor.chain().focus().toggleStrike().run()}>
                    <FormatStrikeIcon />
                </ToggleButton>
            </StyledToggleButtonGroup>
            <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
            <StyledToggleButtonGroup size="small" value={list} exclusive onChange={handleList} aria-label="lists">
                <ToggleButton value="bullet" aria-label="bullet list" onClick={() => editor.chain().focus().toggleBulletList().run()}>
                    <FormatListBulletedIcon />
                </ToggleButton>
                <ToggleButton value="ordered" aria-label="numbered list" onClick={() => editor.chain().focus().toggleOrderedList().run()}>
                    <FormatListNumberedIcon />
                </ToggleButton>
            </StyledToggleButtonGroup>
        </Paper>
    );
};

export default MenuBar;
