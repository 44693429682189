import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Card, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { Brands } from '../../../../../constants';
import { brand, brandsList } from '../../../../../constants';
import Loader from '../../../components/Loader';
import PlatformSettingsForm from '../../../components/PlatformSettings/Form';
import { useGetSettings } from '../../../hooks/use-queries';
import PageWrapper from '../../PageWrapper';
import AddSupportEmailForm from './AddSupportEmailForm';

interface PlatformSettingsPageProps {}

const PlatformSettingsPage: FunctionComponent<PlatformSettingsPageProps> = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { data: settings, isLoading } = useGetSettings('PLATFORM');
    const [tab, setTab] = useState<Brands>(brand.IS24);

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    function onSuccess() {
        enqueueSnackbar('Platform settings have been updated', { variant: 'success' });
    }

    return (
        <PageWrapper title="Platform Settings">
            <Card>{isLoading ? <Loader /> : <PlatformSettingsForm onError={onError} onSuccess={onSuccess} settings={settings || null} />}</Card>
            <Typography variant="h6" gutterBottom sx={{ pt: 5 }}>
                Support Emails by Brand
            </Typography>
            <TabContext value={tab}>
                <TabList
                    value={tab}
                    onChange={(_event: React.SyntheticEvent, value: Brands) => {
                        setTab(value);
                    }}
                    textColor="primary"
                    indicatorColor="secondary"
                    sx={{ mb: 2, bgcolor: 'background.neutral' }}
                >
                    {brandsList.map(brand => (
                        <Tab key={brand} value={brand} label={brand} />
                    ))}
                </TabList>

                {brandsList.map(brand => (
                    <TabPanel key={brand} value={brand}>
                        <AddSupportEmailForm settings={settings} brand={tab} onError={onError} onSuccess={onSuccess} />
                    </TabPanel>
                ))}
            </TabContext>
        </PageWrapper>
    );
};

export default PlatformSettingsPage;
