import { Button, Card, Stack } from '@mui/material';
import _snakeCase from 'lodash/snakeCase';
import { useSnackbar } from 'notistack';
import { parse as papaParse } from 'papaparse';
import type { FC } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { ImportBankTransactionsResponse } from '../../../../schemas/bank-transaction';
import { useImportBankTransactions } from '../../hooks/use-mutations';
import PageWrapper from '../PageWrapper';

interface Props {
    title: string;
}
const UploadBankTransactions: FC<Props> = ({ title }) => {
    const [files, setFiles] = useState(null);
    const [filesData, setFilesData] = useState([]);
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    function onSuccess({ importRef }: ImportBankTransactionsResponse) {
        enqueueSnackbar('Data added', { variant: 'success' });
        setFilesData(null);
        navigate(`/bank-transactions/imports-history/${importRef}`);
    }

    function parseCSVData(file: any) {
        papaParse(file, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: header => _snakeCase(header),
            complete: results => {
                setFilesData(prevState => [...prevState, ...results.data]);
            },
        });
    }

    function onSelectFiles(event) {
        const filesRaw = event.target.files;
        const files = Array.from(filesRaw);
        setFiles(files);
        for (const file of files) {
            parseCSVData(file);
        }
    }

    const mutation = useImportBankTransactions(onSuccess, onError);
    const breadcrumbs = {
        '/dashboard': 'Dashboard',
        '/bank-transactions': 'Bank Transactions',
        '': 'Import',
    };

    return (
        <PageWrapper title={`${title} - Upload`} breadcrumbs={breadcrumbs}>
            <Card sx={{ p: 5 }}>
                <Stack direction="row" alignItems="center">
                    <input type="file" name="file" accept=".csv" multiple style={{ display: 'block', margin: '10px auto' }} onChange={onSelectFiles} />

                    <Button
                        variant="contained"
                        onClick={() => {
                            mutation.mutate(filesData);
                        }}
                        disabled={!filesData || mutation.isLoading}
                    >
                        Upload
                    </Button>
                </Stack>
                <Stack>
                    <h4>Attached Files</h4>
                    <ul>
                        {files?.map(file => {
                            return <li key={file.name}>{file.name}</li>;
                        })}
                    </ul>
                </Stack>
            </Card>
        </PageWrapper>
    );
};
export default UploadBankTransactions;
