import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { FC, PropsWithChildren } from 'react';
import { Breadcrumbs, Page } from '../components';
import StatusChip from '../components/StatusChip';
import { getStatusColor } from '../helpers/color';

interface Props {
    title: string;
    breadcrumbs?: Record<string, string>;
    status?: string;
    hideHeader?: boolean;
    actionButtons?: React.ReactNode;
}

const PageWrapper: FC<PropsWithChildren<Props>> = ({ children, title, breadcrumbs, status, hideHeader, actionButtons }) => {
    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    {!hideHeader ? (
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                            <Typography variant="h4" gutterBottom>
                                {title}
                            </Typography>
                            {actionButtons ? <Box>{actionButtons}</Box> : null}
                        </Stack>
                    ) : null}

                    {breadcrumbs || status ? (
                        <Stack direction="row" justifyContent="start" gap={2}>
                            {breadcrumbs ? <Breadcrumbs items={breadcrumbs} /> : null}
                            {status ? <StatusChip label={status} size="small" color={getStatusColor(status)} /> : null}
                        </Stack>
                    ) : null}

                    {children}
                </Box>
            </Container>
        </Page>
    );
};

export default PageWrapper;
