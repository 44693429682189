import type { Breakpoint, Theme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

type Direction = 'Up' | 'Down';
interface Props {
    width: `${Breakpoint}${Direction}`;
}

const MHidden: FC<PropsWithChildren<Props>> = ({ width, children }) => {
    const breakpoint = width.substring(0, 2) as Breakpoint;

    const hiddenUp = useMediaQuery<Theme>(theme => theme.breakpoints.up(breakpoint));
    const hiddenDown = useMediaQuery<Theme>(theme => theme.breakpoints.down(breakpoint));

    if (width.includes('Down')) {
        return hiddenDown ? null : <>{children}</>;
    }

    if (width.includes('Up')) {
        return hiddenUp ? null : <>{children}</>;
    }

    return null;
};

export default MHidden;
