import { intlDateFormat } from '@condo/formatters';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import type { GetUserFullDataResponse } from '../../../../../schemas';
import DetailsItem from '../../DetailsItem';
import JsonDetails from '../../JsonDetails';
import Section from '../../Section';

interface Props {
    user: GetUserFullDataResponse;
}

const UserGeneralDetailsBox: FC<Props> = ({ user }) => {
    return (
        <Section title="General">
            <DetailsItem title="Registered At" value={user.registeredAt} />
            <DetailsItem title="Active Brand" value={user.activeBrand} />
            <DetailsItem title="Created At" value={intlDateFormat(user.createdAt, true)} />
            <DetailsItem title="Last Updated" value={user.updatedAt ? intlDateFormat(user.updatedAt, true) : null} />
            <DetailsItem title="Firebase Uid" value={user.uid} />
            <DetailsItem title="Risk Class" value={user.riskClass} />
            <DetailsItem title="Last Completed Step" value={user.lastCompletedStep} />
            {user.attributes ? <JsonDetails title="Attributes" data={user.attributes} /> : null}

            <Typography color="text.secondary" variant="overline">
                Bitbond
            </Typography>
            <DetailsItem title="User ID" value={user.bbUserId} />
            <DetailsItem title="Identity ID" value={user.bbIdentityId} />

            <Typography color="text.secondary" variant="overline">
                Tangany
            </Typography>
            <DetailsItem title="Entity ID" value={user.tanganyEntityId} />
            <DetailsItem title="Customer ID" value={user.tanganyCustomerId} />
            <DetailsItem title="Wallet ID" value={user.tanganyWalletDetails?.id} />
            <DetailsItem title="Wallet Address" value={user.tanganyWalletDetails?.address} />
            <JsonDetails title="Last Request Status" data={user.tanganyLastRequestStatus} />
        </Section>
    );
};

export default UserGeneralDetailsBox;
