import { Box, List } from '@mui/material';
import type { FC } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import NavItem from './NavItem';
import type { ISidebarConfig } from './types';

interface Props {
    navConfig: ISidebarConfig[];
}

const NavSection: FC<Props> = ({ navConfig }) => {
    const { pathname } = useLocation();
    const match = path => (path ? Boolean(matchPath({ path, end: false }, pathname)) : false);

    return (
        <Box>
            <List disablePadding>
                {navConfig.map(item => (
                    <NavItem key={item.title} item={item} active={match} />
                ))}
            </List>
        </Box>
    );
};

export default NavSection;
export * from './types';
