import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Villa from '@mui/icons-material/Villa';
import TabContext from '@mui/lab/TabContext';
import TabsList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import NotFoundEntity from '../../../components/NotFoundEntity';
import { useGetProjectQuery } from '../../../hooks/use-queries';
import PageWrapper from '../../PageWrapper';
import ProjectDataTabs from '../shared/ProjectDataTabs';
import ProjectEstates from './Estates';
import ProjectDocuments from './ProjectDocuments';

const ProjectEditPage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { projectId } = useParams();
    const { hash } = useLocation();
    const { isLoading, data, error } = useGetProjectQuery(projectId);
    const [globalTab, setGlobalTab] = useState('main');
    const navigate = useNavigate();

    useEffect(() => {
        if (hash) {
            setGlobalTab(hash.replace('#', ''));
        }
    }, [hash]);
    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    function onSuccess(projectId: string, message?: string) {
        enqueueSnackbar(message || 'Project is updated', { variant: 'success' });
    }

    if (!isLoading && error) {
        onError(error);
    }

    const breadcrumbs = {
        '/dashboard': 'Dashboard',
        '/projects': 'Projects',
        '': data?.project.code || '...',
    };

    if (isLoading) {
        return (
            <PageWrapper title="Edit Project" breadcrumbs={breadcrumbs}>
                <Loader />
            </PageWrapper>
        );
    }

    if (!data) {
        return (
            <PageWrapper title="Edit Project" breadcrumbs={breadcrumbs}>
                <NotFoundEntity name="Project" />
            </PageWrapper>
        );
    }
    const documents = data?.documents?.reduce((acc, doc) => ({ ...acc, [doc.id]: doc }), {});

    return (
        <PageWrapper title="Edit Project" breadcrumbs={breadcrumbs} status={data.project.status}>
            <TabContext value={globalTab}>
                <TabsList
                    value={globalTab}
                    onChange={(_event: React.SyntheticEvent, value: string) => {
                        navigate(`#${value}`);
                    }}
                    textColor="primary"
                    indicatorColor="secondary"
                    sx={{ mb: 2 }}
                >
                    <Tab value="main" label="Project" icon={<AdminPanelSettings />} iconPosition="start" />
                    <Tab value="estates" label="Estates" icon={<Villa />} iconPosition="start" />
                    <Tab value="documents" label="Documents" icon={<PictureAsPdfIcon />} iconPosition="start" />
                </TabsList>

                <TabPanel value="main" sx={{ padding: 0 }}>
                    <ProjectDataTabs {...{ data, onError, onSuccess }} />
                </TabPanel>
                <TabPanel value="estates" sx={{ padding: 0 }}>
                    <ProjectEstates
                        {...{
                            projectId,
                            onError,
                            onSuccess,
                        }}
                    />
                </TabPanel>
                <TabPanel value="documents" sx={{ padding: 0 }}>
                    <ProjectDocuments projectId={projectId} documents={documents} />
                </TabPanel>
            </TabContext>
        </PageWrapper>
    );
};

export default ProjectEditPage;
