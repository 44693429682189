import Box from '@mui/material/Box';
import type { FC } from 'react';
import { flattenObject } from '../helpers/normalizeData';
import DetailsItem, { DEFAULT_TITLE_WIDTH } from './DetailsItem';

const JsonDetails: FC<{ title?: string; data: Record<string, string | boolean | number | Record<string, string | boolean | number>> }> = ({ title, data }) => {
    if (!data || !Object.keys(data).length) {
        return <DetailsItem title={title} value={null} />;
    }

    const dataList = flattenObject(data);
    const values = Object.keys(dataList).map(key => (
        <div key={key}>
            <b>{key}</b>: {dataList[key]}
        </div>
    ));

    return (
        <DetailsItem
            title={title}
            value={
                <Box sx={{ border: theme => `solid 1px ${theme.palette.grey[500_8]}`, p: 1, fontSize: 10, marginLeft: `${DEFAULT_TITLE_WIDTH}px` }}>
                    {values}
                </Box>
            }
        />
    );
};

export default JsonDetails;
