import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { di } from './infrastructure/di';
import { ProvideAuth } from './ui/hooks/use-auth';
import { DIContext } from './ui/hooks/use-di';

const container = document.getElementById('root');
if (!container) {
    throw new Error('Root element not found');
}

const root = createRoot(container);
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <BrowserRouter>
                <DIContext.Provider value={di}>
                    <ProvideAuth>
                        <App />
                    </ProvideAuth>
                </DIContext.Provider>
            </BrowserRouter>
        </HelmetProvider>
    </React.StrictMode>,
);
