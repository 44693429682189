import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

export function diffYears(dateFrom: string, dateTo: string) {
    return Math.round(dayjs(dateTo).diff(dayjs(dateFrom), 'years', true));
}

const quarterMonths = [1, 4, 7, 10];

function getPayoutQuarterData(date: Dayjs) {
    const month = date.month() + 1;
    if (month > quarterMonths[0] && month <= quarterMonths[1]) {
        return { quarter: 'Q1', month: quarterMonths[1] };
    }
    if (month > quarterMonths[1] && month <= quarterMonths[2]) {
        return { quarter: 'Q2', month: quarterMonths[2] };
    }
    if (month > quarterMonths[2] && month <= quarterMonths[3]) {
        return { quarter: 'Q3', month: quarterMonths[3] };
    }
    return { quarter: 'Q4', month: quarterMonths[0] };
}

export function getNextPayout(firstPayoutDate: string | Date) {
    if (dayjs(firstPayoutDate).isAfter(dayjs())) {
        return {
            quarter: getPayoutQuarterData(dayjs(firstPayoutDate)).quarter,
            year: dayjs(firstPayoutDate).year(),
            month: dayjs(firstPayoutDate).month() + 1,
            date: dayjs(firstPayoutDate).format('YYYY-MM-DD'),
        };
    }
    const today = dayjs();
    const payoutQuarter = getPayoutQuarterData(today);
    const payoutYear = payoutQuarter.quarter === 'Q4' && today.month() !== 0 ? today.year() + 1 : today.year();
    return {
        year: payoutYear,
        quarter: payoutQuarter.quarter,
        month: payoutQuarter.month,
        date: dayjs(`${payoutYear}-${payoutQuarter.month}-01`).format('YYYY-MM-DD'),
    };
}
