import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { BrandedSettings, SettingDocumentKeys } from '../../../../../constants';
import type { TabsComponents, WarnIcon } from '../../../components/BrandTabs';
import BrandTabs from '../../../components/BrandTabs';
import { useGenerateAnnualTaxReportsMutation } from '../../../hooks/use-mutations';
import { useGetSettings } from '../../../hooks/use-queries';
import PageWrapper from '../../PageWrapper';
import { brands } from '../../project/constants';
import AddDocumentForm from './AddDocumentForm';

const Page: FunctionComponent = () => {
    const { enqueueSnackbar } = useSnackbar();
    const generateAnnualTaxReportsMutation = useGenerateAnnualTaxReportsMutation({ onSuccess });
    const { data } = useGetSettings();

    function onSuccess() {
        enqueueSnackbar(`Successfully scheduled users annual tax report generation`, { variant: 'success' });
    }

    return (
        <PageWrapper title="Documents" hideHeader>
            <Typography variant="h4" gutterBottom>
                Generic Documents
            </Typography>
            <Box sx={{ p: 2 }}>
                {SettingDocumentKeys.DOCUMENTS.map(key => {
                    if (BrandedSettings.includes(key as any)) {
                        const tabsComponents = {} as TabsComponents;
                        const warnIcons = {} as WarnIcon;
                        brands.forEach(brand => {
                            let setting = data?.[key];
                            if (setting) {
                                setting = { ...setting, value: setting?.value[brand] || {} };
                                warnIcons[brand] = setting?.value && Boolean(Object.keys(setting?.value).length);
                            }

                            tabsComponents[brand] = (
                                <AddDocumentForm key={key} setting={setting} fileName={key} brand={brand} accept="pdf" category="DOCUMENTS" />
                            );
                        });
                        return <BrandTabs key={`tab-${key}`} tabsComponents={tabsComponents} warnIcon={warnIcons} />;
                    }

                    return <AddDocumentForm key={key} setting={data ? data[key] : null} fileName={key} accept="pdf" category="DOCUMENTS" />;
                })}
            </Box>

            <Typography variant="h4" gutterBottom>
                Templates
            </Typography>
            <Box sx={{ p: 2 }}>
                {SettingDocumentKeys.TEMPLATES.map(key => (
                    <AddDocumentForm key={key} setting={data ? data[key] : null} fileName={key} accept="docx" category="TEMPLATES" />
                ))}
            </Box>
            <Typography variant="h4" gutterBottom>
                Annual Tax Reports
            </Typography>
            <Box sx={{ p: 2 }}>
                <Button color="primary" onClick={() => generateAnnualTaxReportsMutation.mutate({})}>
                    Generate Annual Tax Reports
                </Button>
            </Box>
        </PageWrapper>
    );
};

export default Page;
