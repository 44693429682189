import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import type { GetUserFullDataResponse } from '../../../../../schemas';
import { UserGeneralDetailsBox, UserOrders, UserPersonalData, UserRestrictions, UserSteps, UserVouchers } from '../../../components';
import Loader from '../../../components/Loader';
import Section from '../../../components/Section';
import UserProfilePortfolioSummary from '../../../components/order/UserProfilePortfolioSummary';
import UserDangerZone from '../../../components/user/view/UserDangerZone';
import UserFiles from '../../../components/user/view/UserFiles';
import UserHistoryEvents from '../../../components/user/view/UserHistoryEvents';
import UserPaymentRequests from '../../../components/user/view/UserPaymentRequests';
import UserSummary from '../../../components/user/view/UserSummary';
import UserTransactions from '../../../components/user/view/UserTransactions';
import { useGetUser } from '../../../hooks/use-queries';
import PageWrapper from '../../PageWrapper';

const Wrapper: FC<PropsWithChildren<{ user?: GetUserFullDataResponse }>> = ({ children, user }) => (
    <PageWrapper
        title="View User"
        breadcrumbs={{
            '/dashboard': 'Dashboard',
            '/users': 'Users',
            '': !user ? '...' : user.profile ? `${user.profile?.firstName} ${user.profile.lastName}` : user.email,
        }}
    >
        {children}
    </PageWrapper>
);

const UserViewPage: FC = () => {
    const { userId, tabName } = useParams();
    const [tab, setTab] = useState(tabName || 'general');
    const { enqueueSnackbar } = useSnackbar();
    const { isLoading, data: user, error } = useGetUser(userId);

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    function onTabChange(_, newTab: string) {
        setTab(newTab);
    }

    if (!isLoading && error) {
        onError(error);
    }

    if (isLoading) {
        return (
            <Wrapper>
                <Loader />
            </Wrapper>
        );
    }

    if (!user) {
        return (
            <Wrapper>
                <Section>
                    <Typography color="text.primary" align="center">
                        User is not found
                    </Typography>
                </Section>
            </Wrapper>
        );
    }

    return (
        <Wrapper user={user}>
            <Box
                sx={{
                    bgcolor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <UserSummary user={user} />
                <TabContext value={tab}>
                    <TabList onChange={onTabChange}>
                        <Tab label="General" value="general" />
                        <Tab label="Personal Data" value="personal" />
                        <Tab label="Payments" value="payments" />
                        <Tab label="Settings" value="settings" />
                        <Tab label="Timeline" value="timeline" />
                    </TabList>
                    <TabPanel value="general" sx={{ p: 0 }}>
                        <Grid container spacing={1}>
                            <Grid item xs md={8}>
                                <Stack direction="column" spacing={1}>
                                    <UserGeneralDetailsBox user={user} />
                                    <UserFiles userId={userId} />
                                </Stack>
                            </Grid>
                            <Grid item xs>
                                <UserSteps userId={userId} />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="personal" sx={{ p: 0 }}>
                        <UserPersonalData user={user} />
                    </TabPanel>
                    <TabPanel value="payments" sx={{ p: 0 }}>
                        <Stack direction="column" spacing={1}>
                            <UserProfilePortfolioSummary user={user} />
                            <UserVouchers userId={userId} />
                            <UserOrders userId={userId} />
                            <UserTransactions userId={userId} />
                            <UserPaymentRequests userId={userId} />
                        </Stack>
                    </TabPanel>
                    <TabPanel value="settings" sx={{ p: 0 }}>
                        <Stack direction="column" spacing={4}>
                            <UserRestrictions user={user} />
                            <UserDangerZone user={user} />
                        </Stack>
                    </TabPanel>
                    <TabPanel value="timeline" sx={{ p: 0 }}>
                        <Stack direction="column" spacing={4}>
                            <UserHistoryEvents userId={user.id} />
                        </Stack>
                    </TabPanel>
                </TabContext>
            </Box>
        </Wrapper>
    );
};

export default UserViewPage;
