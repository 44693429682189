import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import type { FC } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectsSelect from '../../../components/ProjectsSelect';
import { useRunDistributionPreparation } from '../../../hooks/use-mutations';
import PageWrapper from '../../PageWrapper';
import ProjectDistributionStats from '../shared/ProjectDistributionStats';

interface Props {
    onSuccess?: (projectId: string, message?: string) => void;
    onError?: (error) => void;
}

const DistributionCreatePage: FC<Props> = () => {
    const [projectId, setProjectId] = useState<string>();
    const navigate = useNavigate();
    const mutation = useRunDistributionPreparation(distribution => {
        navigate(`/distribution/edit/${distribution.id}`);
    });

    return (
        <PageWrapper title="Create Tokens Distribution" breadcrumbs={{ '/dashboard': 'Dashboard', '/distribution': 'Distributions', '': 'New' }}>
            <ProjectsSelect onChange={setProjectId} />
            {projectId ? (
                <>
                    <ProjectDistributionStats
                        projectId={projectId}
                        onFetch={({ runningDistributionId }) => {
                            if (runningDistributionId) {
                                navigate(`/distribution/edit/${runningDistributionId}`);
                            }
                        }}
                    />
                    <Stack sx={{ p: 2 }} direction="row" gap={1} justifyContent="end">
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            disabled={!projectId || mutation.isLoading}
                            loading={mutation.isLoading}
                            onClick={() => {
                                if (projectId) {
                                    mutation.mutate({ projectId });
                                }
                            }}
                            sx={{ mr: 2 }}
                        >
                            Prepare Distribution
                        </LoadingButton>
                    </Stack>
                </>
            ) : null}
        </PageWrapper>
    );
};

export default DistributionCreatePage;
