import { currency } from '@condo/formatters';
import { Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import type { IOrder } from '../../../../domain-entities/models/order';
import type { IOrderItem } from '../../../../domain-entities/models/order-item';
import type { IProject } from '../../../../domain-entities/models/project';
import DetailsItem from '../DetailsItem';
import JsonDetails from '../JsonDetails';
import LinkRouter from '../LinkRouter';
import Section from '../Section';

interface Props {
    order: IOrder & { project?: IProject; orderItems?: IOrderItem[] };
}
const OrderDetailsBox: FC<Props> = ({ order }) => {
    return (
        <Section title="Order Details">
            {order ? (
                <>
                    <DetailsItem title="Order code" value={<LinkRouter to={`/orders/view/${order.id}`}>{order.code}</LinkRouter>} />
                    <DetailsItem title="Order status" value={order.status} />
                    <DetailsItem title="On Hold?" value={order.isOnhold} />
                    <DetailsItem title="Total Amount" value={currency(order.tokens * order.tokenPrice)} />
                    <DetailsItem title="Order Tokens" value={order.tokens} />
                    <DetailsItem title="Token Price" value={order.tokenPrice} />
                    <DetailsItem title="Created at" value={new Date(order.createdAt).toLocaleString()} />
                    <DetailsItem title="Completed at" value={order.completedAt ? new Date(order.completedAt).toLocaleString() : 'Not completed'} />
                    <JsonDetails title="Buyback" data={order.buyback} />

                    <Typography color="text.secondary" variant="overline">
                        Project
                    </Typography>
                    {order.project ? (
                        <>
                            <DetailsItem title="Project code" value={order.project.code} />
                            <DetailsItem title="Project status" value={order.project.status} />
                        </>
                    ) : (
                        <Alert severity="warning">No project details</Alert>
                    )}

                    <Typography color="text.secondary" component="p" variant="overline">
                        Payment Details
                    </Typography>

                    <DetailsItem title="Account name" value={order.paymentDetails.accountName} />
                    <DetailsItem title="Bank name" value={order.paymentDetails.bankName} />
                    <DetailsItem title="IBAN" value={order.paymentDetails.iban} />

                    <Typography color="text.secondary" variant="overline">
                        User
                    </Typography>

                    <DetailsItem title="User Ref" value={<LinkRouter to={`/users/view/${order.userId}`}>{order.userId}</LinkRouter>} />

                    <JsonDetails title="Metadata" data={order.metadata} />
                </>
            ) : null}
        </Section>
    );
};

export default OrderDetailsBox;
