import type { TSchema } from '@sinclair/typebox';
import type { AnySchema, ErrorObject } from 'ajv';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { set } from 'lodash';
import _isObjectLike from 'lodash/isObjectLike';

export const ajv = addFormats(
    new Ajv({
        allErrors: true,
        coerceTypes: true,
    }),
)
    // needed for @sinclair/typebox, without them it shows an error in the console
    .addKeyword('kind')
    .addKeyword('modifier');

function ajvErrorsToFormikErrors(ajvErrors?: ErrorObject[] | null) {
    const mappedErrors = {};
    if (ajvErrors) {
        ajvErrors.forEach(ajvError => {
            let key = ajvError.instancePath.substring(1);
            if (ajvError.params.missingProperty) {
                key += `/${ajvError.params.missingProperty}`;
            }
            if (key.includes('/')) {
                const keys = key.split('/');
                set(mappedErrors, keys, ajvError.message);
            }
        });
    }
    return mappedErrors;
}

export const validateForm = async <T extends TSchema>(values: { [key: string]: any }, jsonSchema: T) => {
    let errors = {};
    const updatedValues = filterValues(values, true);
    try {
        const validationResult = await ajv.validate<AnySchema>(jsonSchema, updatedValues);
        if (!validationResult) {
            errors = ajvErrorsToFormikErrors(ajv.errors);
        }
    } catch (err: any) {
        if (!(err instanceof Ajv.ValidationError)) {
            throw err;
        }
        errors = ajvErrorsToFormikErrors(err.errors as ErrorObject[] | null);
    }
    return errors;
};

export const filterValues = (values: Record<string, any>, replaceEmptyString = false) => {
    return Object.keys(values).reduce(
        (result, key) => {
            const value = values[key];
            if (_isObjectLike(values[key])) {
                result[key] = filterValues(value, replaceEmptyString);
            } else if (typeof value === 'string') {
                result[key] = value.replace(/\s+/g, ' ').trim();
                if (replaceEmptyString) {
                    result[key] ||= undefined;
                }
            } else {
                result[key] = value;
            }
            return result;
        },
        {} as Record<string, any>,
    );
};

export const submitForm = (values: { [key: string]: any }, onSubmit: Function, extraProp?: any) => {
    return onSubmit(values, extraProp);
};
