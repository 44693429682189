import DownloadIcon from '@mui/icons-material/Download';
import { Card } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import type { GetUserPayoutResponse } from '../../../../../schemas';
import type { DataGridProps } from '../../../components/DataGrid';
import DataGrid, { useGridColumns } from '../../../components/DataGrid';
import StatusChip from '../../../components/StatusChip';
import { ViewLink } from '../../../components/ViewLink';
import { getStatusColor } from '../../../helpers/color';
import { downloadFile } from '../../../helpers/dom';
import { useDI } from '../../../hooks/use-di';
import { useGenerateQuarterTaxReceiptMutation } from '../../../hooks/use-mutations';

interface Props {
    payoutReportId: string;
    payoutReportQuarter?: 'Q1' | 'Q2' | 'Q3' | 'Q4';
    payoutReportYear?: number;
}

const UserPayoutsListPage: FC<Props> = ({ payoutReportId, payoutReportQuarter, payoutReportYear }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { apiClient } = useDI();
    const mutation = useGenerateQuarterTaxReceiptMutation({
        onSuccess: () => {
            enqueueSnackbar('Receipts generation is scheduled. Please refresh the page in a minute', { variant: 'success' });
        },
    });
    const download = (userId: string, userFileId: string) => {
        apiClient.downloadUserFile(userId, userFileId, `QTR_${userId}.pdf`).then(({ data, fileName }) => {
            downloadFile(data, fileName);
        });
    };

    const columns = useGridColumns<GetUserPayoutResponse>([
        {
            accessorKey: 'status',
            header: 'Status',
            size: 80,
            filterVariant: 'multi-select',
            filterSelectOptions: ['CREATED', 'PUBLISHED'],
            Cell: ({ cell }) => <StatusChip label={cell.getValue()} size="small" color={getStatusColor(cell.getValue<string>())} />,
        },
        {
            accessorKey: 'userFileId',
            header: 'QTR',
            size: 20,
            enableColumnFilter: false,
            Cell: ({ row }) => {
                if (!row.original.userFileId) {
                    return 'n/a';
                }
                return (
                    <IconButton
                        aria-label="download"
                        onClick={() => {
                            download(row.original.userId, row.original.userFileId);
                        }}
                    >
                        <DownloadIcon />
                    </IconButton>
                );
            },
        },
        {
            id: 'userName',
            header: 'User Name',
            minSize: 150,
            enableColumnFilter: false,
            Cell: ({ row }) => {
                if (!row.original.userId) {
                    return 'n/a';
                }
                return (
                    <ViewLink
                        entityType="user"
                        entityId={row.original.userId}
                        title={`${row.original.user?.profile?.firstName} ${row.original.user?.profile?.lastName}`}
                    />
                );
            },
        },
        {
            accessorKey: 'tokens',
            header: 'Tokens',
            size: 80,
        },
        {
            accessorKey: 'grossAmount',
            header: 'Gross Amount',
            minSize: 150,
            dataType: 'currency',
        },
        {
            accessorKey: 'netAmount',
            header: 'Net Amount',
            minSize: 150,
            dataType: 'currency',
        },
        {
            accessorKey: 'capitalGainTaxAmount',
            header: 'Capital Gain Tax',
            minSize: 120,
            dataType: 'currency',
        },
        {
            accessorKey: 'solidaritySurchargeAmount',
            header: 'Solidarity Surcharge',
            minSize: 120,
            dataType: 'currency',
        },
        {
            accessorKey: 'churchTaxAmount',
            header: 'Church Tax',
            minSize: 120,
            dataType: 'currency',
        },
        {
            accessorKey: 'vouchersAmount',
            header: 'Vouchers Amount',
            minSize: 120,
            dataType: 'currency',
        },
        {
            accessorKey: 'createdAt',
            header: 'Created At',
            dataType: 'date-time',
        },
    ]);
    const dataGridProps: DataGridProps<GetUserPayoutResponse> = {
        columns,
        dataFetch: { key: 'USER_PAYOUTS', params: { payoutReportId } },
        allowExport: true,
        defaultPageSize: 200,
        enableRowSelection: true,
        toolbarActions: [
            {
                title: 'Generate QTR',
                onClick: ({ selected }) => {
                    if (selected.length) {
                        const userPayoutIds = selected.map(({ id }) => id);
                        mutation.mutate(userPayoutIds);
                    }
                },
            },
        ],
        defaultExportId: 'tax-data',
        exportElements: [
            {
                columns: [
                    { key: 'grossAmount' },
                    { key: 'capitalGainTaxAmount' },
                    { key: 'churchTaxAmount' },
                    { key: 'solidaritySurchargeAmount' },
                    { key: 'netAmount' },
                    {
                        key: 'user.profile',
                        type: 'object',
                        childrenColumns: [
                            'firstName',
                            'lastName',
                            'addressCountry',
                            'addressState',
                            'addressStreet',
                            'addressStreet2',
                            'addressHouseNumber',
                            'addressZip',
                            'taxId',
                            'confession',
                        ],
                    },
                ],
                buttonText: 'Export Tax Data',
                action: (_rows, _tableRef, _setExportColumns, setFetchWithoutPagination) => {
                    if (setFetchWithoutPagination) {
                        setFetchWithoutPagination(true);
                    }
                },
                dialogContent: 'Are you sure you want to export user tax data?',
                id: 'tax-data',
                dialogTitle: 'Export tax data',
                fileName: `user-payouts-data-${payoutReportQuarter}-${payoutReportYear}`,
            },
            {
                columns: [
                    { key: 'grossAmount' },
                    { key: 'netAmount' },
                    {
                        key: 'ordersCodes',
                    },
                    { key: 'paymentDetails', type: 'object', childrenColumns: ['referenceCode', 'accountName', 'iban', 'bankName'] },
                ],
                id: 'payment-data',
                action: (_rows, _tableRef, _setExportColumns, setFetchWithoutPagination) => {
                    setFetchWithoutPagination(true);
                },
                hasIcon: true,
                buttonText: 'Export Payment Data',
                fileName: `user-payouts-payment-${payoutReportQuarter}-${payoutReportYear}`,
            },
        ],
    };
    return (
        <Card>
            <DataGrid {...dataGridProps} />
        </Card>
    );
};

export default UserPayoutsListPage;
