import _isObject from 'lodash/isObject';
import _reduce from 'lodash/reduce';
import _transform from 'lodash/transform';

export const normalizeData = data => {
    const normalizedProject = { ...data };
    Object.keys(normalizedProject).forEach(key => {
        normalizedProject[key] = normalizedProject[key] === '' ? null : normalizedProject[key];
    });
    return normalizedProject;
};

export function flattenObject(obj: Record<string, any>): Record<string, any> {
    return _transform(
        obj,
        (result, value, key) => {
            if (_isObject(value)) {
                const flattenedValue = flattenObject(value);
                _reduce(
                    flattenedValue,
                    (nestedResult, nestedValue, nestedKey) => {
                        const newKey = `${key}.${nestedKey}`;
                        nestedResult[newKey] = nestedValue;
                        return nestedResult;
                    },
                    result,
                );
            } else {
                result[key] = value;
            }
        },
        {},
    );
}
