import { Box, CircularProgress } from '@mui/material';
import type { FC } from 'react';

interface Props {
    shouldOverride?: boolean;
}

const Loader: FC<Props> = ({ shouldOverride = false }) => {
    return (
        <Box
            sx={{
                ...(!shouldOverride && { maxWidth: 480 }),
                margin: 'auto',
                textAlign: 'center',
                ...(shouldOverride && {
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 999,
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }),
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default Loader;
