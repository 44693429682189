import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Icon } from '@iconify/react';
import type { ISidebarConfig } from './components/NavSection';

const getIcon = icon => <Icon icon={icon} width={22} height={22} />;

/**
 * Icons lib: https://icon-sets.iconify.design/
 */

export const accountPopoverOptions = [
    {
        label: 'Profile',
        icon: personFill,
        linkTo: '#',
    },
    {
        label: 'Settings',
        icon: settings2Fill,
        linkTo: '#',
    },
];

export const sidebarOptions: ISidebarConfig[] = [
    {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
        title: 'Projects',
        path: '/projects',
        icon: getIcon('eva:layers-fill'),
    },
    {
        title: 'users',
        path: '/users',
        icon: getIcon('eva:people-fill'),
    },
    {
        title: 'Payments',
        path: '/orders',
        icon: getIcon('eva:credit-card-outline'),
        children: [
            {
                title: 'Orders',
                path: '/orders/all',
            },
            {
                title: 'Payment Requests',
                path: '/payment-requests/list',
            },
            {
                title: 'Bank Transactions',
                path: '/bank-transactions/list',
            },
        ],
    },
    {
        title: 'Distributions',
        path: '/distribution/list',
        icon: getIcon('majesticons:etherium-circle-line'),
    },
    {
        title: 'Payout Reports',
        path: '/payout-reports',
        icon: getIcon('eva:trending-up-fill'),
    },
    {
        title: 'History Events',
        path: '/history-events',
        icon: getIcon('mdi:events'),
    },
    {
        title: 'Jobs',
        path: '/jobs',
        icon: getIcon('eva:clock-fill'),
    },
    {
        title: 'Settings',
        path: '/settings',
        icon: getIcon('eva:settings-2-fill'),
        children: [
            {
                title: 'Users',
                path: '/settings/admin-user',
            },
            {
                title: 'Integrations',
                path: '/settings/integrations',
            },
            {
                title: 'Documents',
                path: '/settings/documents',
            },
            {
                title: 'Voucher Account',
                path: '/settings/voucher-account',
            },
            {
                title: 'Merging Categories',
                path: '/settings/merging-categories',
            },
            {
                title: 'Platform Settings',
                path: '/settings/platform-settings',
            },
        ],
    },
];
