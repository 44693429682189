import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import type { FC } from 'react';
import LinkRouter from './LinkRouter';

interface Props {
    items: Record<string, string>;
}

const Breadcrumbs: FC<Props> = ({ items }) => (
    <MuiBreadcrumbs aria-label="breadcrumb">
        {Object.keys(items).map(link => {
            const label = items[link];
            if (link) {
                return (
                    <LinkRouter underline="hover" color="inherit" to={link} key={link}>
                        {label}
                    </LinkRouter>
                );
            }

            return (
                <Typography color="text.primary" key="bc-key">
                    {label}
                </Typography>
            );
        })}
    </MuiBreadcrumbs>
);

export default Breadcrumbs;
