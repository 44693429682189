import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Field, useFormikContext } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import type { IProjectDocument } from '../../../../../../domain-entities/models/project';
import ContentEditor from '../../../../components/ContentEditor';
import { useDeleteFileMutation } from '../../../../hooks/use-mutations';

interface Props {
    documentId: string;
    projectId: string;
    document: IProjectDocument & { tempFileId?: string };
}

const DocumentItem: FC<Props> = ({ projectId, document, documentId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { values } = useFormikContext<{ documents: Record<string, IProjectDocument> }>();
    const deleteMutation = useDeleteFileMutation(
        () => {
            enqueueSnackbar(`Documents is deleted: ${document.category}`, { variant: 'success' });
        },
        error => {
            enqueueSnackbar(`Error while deleting documents: ${error}`, { variant: 'error' });
        },
    );
    const onDeleteDocument = async (item: IProjectDocument & { tempFileId?: string }) => {
        if (item) {
            await deleteMutation.mutateAsync({
                type: item?.tempFileId ? 'TEMPORARY' : 'PROJECT_DOCUMENT',
                fileId: item.id,
                projectId,
            });
            delete values.documents[item?.tempFileId || item.id];
        }
    };

    const name = document?.tempFileId || document.id;
    const brand = document.brand;
    return (
        <Stack direction="row" gap={1} justifyContent="space-evenly">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 100 }}>
                <Link href={`/document?link=${document.link}`} target="_blank" rel="noreferrer">
                    <PictureAsPdfIcon sx={{ fontSize: 50 }} />
                </Link>
                <Typography fontSize={9}>
                    <b>{brand}:</b> {name}
                </Typography>
            </Box>
            <Stack direction="column" gap={1}>
                <Field type="hidden" value={document.brand} name={`documents.${documentId}.brand`} />
                <Field type="hidden" value={document.category} name={`documents.${documentId}.category`} />
                <Field
                    value={document.title || ''}
                    name={`documents.${documentId}.title`}
                    component={TextField}
                    size="small"
                    label="Title"
                    type="text"
                    sx={{ width: '100%' }}
                />
                <FormControlLabel control={<Field component={Switch} name={`documents.${documentId}.isPrivate`} type="checkbox" />} label="Private" />
            </Stack>
            <Box sx={{ width: '40%' }}>
                <ContentEditor name={`documents.${documentId}.description`} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button variant="contained" color="warning" onClick={() => onDeleteDocument(document)}>
                    Delete
                </Button>
            </Box>
        </Stack>
    );
};

export default DocumentItem;
