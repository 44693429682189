import { Type } from '@sinclair/typebox';
import type { IProjectDocument } from '../../../domain-entities/models/project';
import { defaultValues } from '../pages/project/constants';
// import { PayoutReportQuarter, PayoutReportStatus } from '.prisma/client';

export const projectSchema = Type.Object({
    code: Type.String(),
    dividendBps: Type.Integer(),
    tokenPrice: Type.Integer(),
    tokensAmount: Type.Integer({ minimum: 1 }),
    minInvestment: Type.Integer({ minimum: 100 }),
    bankAccountName: Type.String(),
    bankName: Type.String(),
    projectStartsAt: Type.Any(),
    maturityDate: Type.Any(),
    saleStartsAt: Type.Any(),
    saleEndsAt: Type.Any(),
    iban: Type.String({ minLength: 15, maxLength: 33 }),
});

export const payoutReportSchema = Type.Object({
    projectId: Type.String(),
    status: Type.Any(), // Type.Enum(PayoutReportStatus),
    year: Type.Number({ minimum: 2023 }),
    quarter: Type.Any(), //Type.Enum(PayoutReportQuarter),
    payoutDate: Type.String({ format: 'date' }),
    summaryText: Type.Optional(Type.String()),

    rentalLossRisk: Type.Number(),
    netRevenue: Type.Number(),
    otherCosts: Type.Number(),
    securityTrustee: Type.Number(),
    icServiceFees: Type.Number(),
    camPmFees: Type.Number(),
    invPmFees: Type.Number(),
    maintenanceCosts: Type.Number(),
    loanPayments: Type.Number(),
    loanInterest: Type.Number(),
    totalDividendAmount: Type.Number(),
});

export const projectDetailsSchema = Type.Object({
    ...Object.keys(defaultValues.details).reduce(
        (result, key) => ({
            ...result,
            [key]: mapType(defaultValues.details[key].format),
        }),
        {},
    ),

    mainTitle: Type.String(),
    location: Type.String(),
    legalNotes: Type.String(),
});

export const estateFormGeneralValidationSchema = Type.Object({
    estateDetails: Type.Object({
        description: Type.String(),
        livingSpace: Type.Number(),
        numberOfRooms: Type.Number(),
        constructionYear: Type.Integer(),
        renovationYear: Type.Integer(),
        latitude: Type.Number(),
        longitude: Type.Number(),
    }),
});

function mapType(type: string) {
    if (type === 'NUMBER') {
        return Type.Integer();
    }
    return Type.Optional(Type.Any());
}

export const buildDocumentsSchema = (values: Record<string, Omit<IProjectDocument, 'projectId'>>) => {
    const schema = {};
    Object.keys(values).map(key => {
        return Object.assign(schema, {
            [key]: Type.Object({
                title: Type.String(),
                category: Type.String(),
            }),
        });
    });
    return Type.Object({ documents: Type.Object(schema) });
};
