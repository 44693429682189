import { currency } from '@condo/formatters';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import type { MRT_TableInstance } from 'material-react-table';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import DataCard from '../../../components/DataCard';
import type { Columns, DataGridProps } from '../../../components/DataGrid';
import DataGrid, { useGridColumns } from '../../../components/DataGrid';
import { getFilterQueryByTable } from '../../../components/DataGrid/helpers/getFilterQueryByTable';
import { ViewLink } from '../../../components/ViewLink';
import { useGenerateSepaXmlMutation, useUpdatePaymentRequests } from '../../../hooks/use-mutations';
import { useGetPaymentRequestsStats } from '../../../hooks/use-queries';
import type { PaymentRequestType } from '.prisma/client';

interface Props {
    type: PaymentRequestType;
}

const List: FC<Props> = ({ type }) => {
    const exportedFileName = `${type}_request_${dayjs().format('YYYYMMDD')}`;
    const { data: stats, isLoading } = useGetPaymentRequestsStats(type);
    const { enqueueSnackbar } = useSnackbar();
    const onError = error => {
        enqueueSnackbar(`Operation failed: ${error}`, { variant: 'error' });
    };

    const exportSepa = useGenerateSepaXmlMutation(data => {
        const blob = new Blob([data.data], { type: 'application/xml' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = `${exportedFileName}.xml`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, onError);
    const mutation = useUpdatePaymentRequests();

    const columns = useGridColumns<any>(
        [
            {
                accessorKey: type === 'PAYOUT' ? 'userPayout.payoutReport.project.code' : 'order.project.code',
                header: 'Project Code',
                minSize: 150,
                accessorFn: row => _get(row, type === 'PAYOUT' ? 'userPayout.payoutReport.project.code' : 'order.project.code'),
            },
            type !== 'PAYOUT'
                ? {
                      accessorKey: 'order.code',
                      header: 'Order code',
                      minSize: 150,
                  }
                : undefined,
            {
                accessorKey: 'user.name',
                header: 'User Name',
                minSize: 150,
                Cell: ({ row }) => {
                    if (!row.original.userId) {
                        return 'n/a';
                    }
                    return (
                        <ViewLink
                            entityId={row.original.userId}
                            entityType="user"
                            title={`${row.original.user?.profile?.firstName} ${row.original.user?.profile?.lastName}`}
                        />
                    );
                },
                enableColumnFilter: false,
            },
            {
                accessorKey: 'amount',
                header: type === 'PAYOUT' ? 'Net Amount' : 'Amount',
                minSize: 150,
                dataType: 'currency',
            },
            type === 'PAYOUT'
                ? {
                      id: 'taxIdFlag',
                      header: 'Has TaxID',
                      enableColumnFilter: false,
                      minSize: 50,
                      accessorFn: row => (_get(row, 'user.profile.taxId') ? 'Yes' : 'No'),
                  }
                : undefined,
            {
                accessorKey: 'type',
                header: 'Type',
                minSize: 150,
                enableColumnFilter: false,
            },
            {
                accessorKey: 'createdAt',
                header: 'Created At',
                dataType: 'date',
            },
        ].filter(v => v) as Columns,
    );

    const dataGridProps: DataGridProps = {
        columns,
        enableRowSelection: type === 'PAYOUT' ? (row: any) => Boolean(row.original?.user?.profile?.taxId) : true,
        rowSelectionData: [
            {
                buttonText: 'Mark selected as paid',
                action: (selectedRowsIds: string[]) => {
                    mutation.mutate({
                        ids: selectedRowsIds,
                        data: {
                            status: 'PAID',
                        },
                    });
                },
                dialogContent: 'Are you sure you want to mark selected as paid?',
                dialogTitle: 'Mark as Paid',
            },
        ],
        defaultExportId: 'payment-requests-sepa-xml-data',
        exportElements: [
            {
                buttonText: 'Export selected as SEPA XML',
                action: (selectedRowsIds: string[], tableRef: MRT_TableInstance) => {
                    if (selectedRowsIds.length === 0) {
                        const filterQuery = getFilterQueryByTable(tableRef);
                        exportSepa.mutate({
                            listQuery: { filterQuery: JSON.stringify({ ...filterQuery, type, status: 'OPEN' }), page: '0', pageSize: 'Infinity' },
                        });
                    } else {
                        exportSepa.mutate({ listQuery: { filterQuery: JSON.stringify({ id: { in: selectedRowsIds } }), page: '0', pageSize: 'Infinity' } });
                    }
                },
                dialogContent: 'Are you sure you want to export selected requests?',
                id: 'payment-requests-sepa-xml-data',
                dialogTitle: 'Export SEPA XML',
                fileName: `payment-requests-sepa-xml`,
            },
            {
                columns: [
                    { key: 'amount' },
                    { key: 'recipient', type: 'object', childrenColumns: ['accountName', 'bic', 'iban', 'bankName', 'referenceCode'] },
                ],
                id: 'payment-requests',
                hasIcon: true,
                buttonText: 'Export',
                fileName: exportedFileName,
                action: (_rows, _tableRef, _setExportColumns, setFetchWithoutPagination) => {
                    setFetchWithoutPagination(true);
                },
            },
        ],
        getRowId: row => row.id,
        dataFetch: { key: 'PAYMENT_REQUESTS', params: { type, status: 'OPEN' } },
        actions: {
            view(row) {
                window.open(`/payment-requests/view/${row.id}`, '_blank');
            },
        },
    };

    return (
        <>
            <Stack direction="row" gap={2} sx={{ mb: 2 }}>
                <DataCard title="Total Amount" value={isLoading ? '...' : currency(stats.total)} />
            </Stack>
            <Card>
                <DataGrid {...dataGridProps} />
            </Card>
        </>
    );
};

export default List;
