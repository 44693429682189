import Link from '@mui/material/Link';
import type { FC } from 'react';

interface Props {
    entityType: 'project' | 'order' | 'user' | 'bankTransaction';
    entityId: string;
    title: string;
    tabName?: string;
}

const mapEntityLinks = {
    project: 'projects/edit',
    order: 'orders/view',
    user: 'users/view',
    bankTransaction: 'bank-transactions/view',
};

export const ViewLink: FC<Props> = ({ entityType, entityId, title, tabName }) => {
    const href = `/${mapEntityLinks[entityType]}/${entityId}${tabName ? `/${tabName}` : ''}`;
    return (
        <Link href={href} target="_blank" rel="noreferrer">
            {title}
        </Link>
    );
};
