import { Box, Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Page from '../../../components/Page';
import ProjectDataTabs from '../shared/ProjectDataTabs';

const ProjectCreatePage: FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    function onSuccess(projectId: string) {
        enqueueSnackbar('Project is created', { variant: 'success' });
        navigate(`/projects/edit/${projectId}`);
    }

    return (
        <Page title="Project Details">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4" gutterBottom>
                        Create Project
                    </Typography>

                    <Breadcrumbs items={{ '/dashboard': 'Dashboard', '/projects': 'Projects', '': 'New' }} />
                    <ProjectDataTabs onError={onError} onSuccess={onSuccess} />
                </Box>
            </Container>
        </Page>
    );
};

export default ProjectCreatePage;
