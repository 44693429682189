import { intlDateFormat } from '@condo/formatters';
import { Box, Typography } from '@mui/material';
import type { FC } from 'react';

type TValue = string | boolean | number | Date | JSX.Element | JSX.Element[];
interface FormatProps {
    onlyDate?: boolean;
}
interface Props {
    title: string;
    value: TValue;
    titleWidth?: number;
    formatProps?: FormatProps;
}
const renderValue = (value: TValue, formatProps?: FormatProps) => {
    if (typeof value === 'boolean') {
        return value ? 'yes' : 'no';
    }
    if (!value) {
        return '–';
    }

    // @ts-expect-error
    if (Array.isArray(value) && typeof value[0].$$typeof !== 'symbol') {
        return value.map(v => JSON.stringify(v));
    }

    if (value instanceof Date) {
        return intlDateFormat(value, !formatProps?.onlyDate);
    }

    return value;
};

export const DEFAULT_TITLE_WIDTH = 180;

const DetailsItem: FC<Props> = ({ title, value, formatProps, titleWidth = DEFAULT_TITLE_WIDTH }) => {
    return (
        <Typography
            variant="body2"
            component="div"
            sx={{
                color: theme => theme.palette.grey[600],
                paddingLeft: theme => theme.spacing(1),
                paddingBottom: theme => theme.spacing(0.5),
            }}
        >
            <Box
                component="span"
                sx={{
                    display: 'inline-block',
                    width: titleWidth,
                    color: theme => theme.palette.grey[900],
                }}
            >
                {title ? <>{title}:&nbsp;</> : null}
            </Box>
            {renderValue(value, formatProps)}
        </Typography>
    );
};
export default DetailsItem;
