import { bpsToPercent } from '@condo/formatters';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormControlLabel, Grid, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { Select, Switch, TextField } from 'formik-mui';
import { DatePicker } from 'formik-mui-x-date-pickers';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useEffect } from 'react';
import { DEFAULT_CURRENCY } from '../../../../../constants';
import type { IProject } from '../../../../../domain-entities/models/project';
import DetailsItem from '../../../components/DetailsItem';
import ExtLink from '../../../components/ExtLink';
import Loader from '../../../components/Loader';
import { diffYears } from '../../../helpers/date';
import { projectSchema } from '../../../helpers/formSchemas';
import { validateForm } from '../../../helpers/formValidation';
import { usePublishProjectMutation, useSaveProjectMutation } from '../../../hooks/use-mutations';
import { useBankDataQuery } from '../../../hooks/use-queries';
import { defaultValues } from '../constants';

declare const __CONFIG__: { clientUrl: string };
interface Props {
    project?: IProject;
    onSuccess: (projectId: string, message?: string) => void;
    onError: (error) => void;
}
type FormValues = typeof defaultValues.project;

const currencies = [DEFAULT_CURRENCY];

const ProjectGeneralForm: FC<Props> = ({ onSuccess, onError, project }) => {
    const { enqueueSnackbar } = useSnackbar();
    const mutation = useSaveProjectMutation(onSuccess, onError);
    const publishProjectMutation = usePublishProjectMutation(
        () => {
            enqueueSnackbar('Successfully published project');
        },
        error => {
            enqueueSnackbar(`Error while publishing project: ${error}`, { variant: 'error' });
        },
    );
    const slotProps = { TextField: { readOnly: true } };
    const onPublish = () => {
        // TODO: improve data validation at server-side before publishing
        publishProjectMutation.mutate({ projectId: project.id });
    };

    const formik = useFormik<FormValues>({
        onSubmit(values, { setSubmitting }) {
            mutation.mutate({ project: values as any });
            setSubmitting(false);
        },
        initialValues: {
            ...defaultValues.project,
            ...project,
        },
        validateOnChange: false,
        validate: values => validateForm<typeof projectSchema>(values, projectSchema),
    });

    const { isSubmitting, handleSubmit, values, getFieldMeta, setFieldValue, dirty } = formik;
    const ibanMeta = getFieldMeta('iban');
    const needRefetch = Boolean(values.iban) && (values.iban !== ibanMeta.initialValue || !values.bankName);
    const { data } = useBankDataQuery(values.iban, needRefetch);
    const { bank } = data ?? {};

    useEffect(() => {
        if (bank?.name) {
            setFieldValue('bankName', bank?.name);
        }
    }, [bank?.name, setFieldValue]);

    const url = project?.id && `${__CONFIG__.clientUrl}/project/${project.code}`;
    return (
        <FormikProvider value={formik}>
            {isSubmitting || mutation.isLoading ? <Loader shouldOverride /> : null}
            <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {project ? (
                        <Grid item>
                            <DetailsItem title="Bitbond ID" value={project.bbId} />
                            <DetailsItem title="Url" value={<ExtLink url={url} />} />
                            <DetailsItem
                                title="Token Url"
                                value={
                                    project.tokenAddress ? (
                                        <ExtLink url={`https://polygonscan.com/token/${project.tokenAddress}`} title={project.tokenAddress} />
                                    ) : null
                                }
                            />
                        </Grid>
                    ) : null}

                    <Grid item xs={12}>
                        <FormControlLabel control={<Field component={Switch} name="isPrivate" type="checkbox" />} label="Is Private" />
                    </Grid>
                    <GridHeader title="Basic" />
                    <Grid item xs={12} sm={3}>
                        <Field fullWidth component={TextField} name="code" label="Project Code" />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Field fullWidth component={Select} name="currency" label="Currency" sx={{ width: 100 }}>
                            {currencies.map(currency => (
                                <MenuItem value={currency} key={currency}>
                                    {currency}
                                </MenuItem>
                            ))}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Field
                            fullWidth
                            component={TextField}
                            name="minInvestment"
                            label="Min. Investment"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{values.currency}</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Field
                            fullWidth
                            component={TextField}
                            name="dividendBps"
                            label="Dividend (BSP)"
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">BPS</InputAdornment>,
                            }}
                            helperText={`Percentage example: ${bpsToPercent(values.dividendBps)}`}
                        />
                    </Grid>

                    <GridHeader title="Crypto" />
                    <Grid item xs={12} sm={2}>
                        <Field
                            fullWidth
                            component={TextField}
                            name="tokensAmount"
                            type="number"
                            label="Number of Tokens"
                            helperText={`Emission value: ${values.currency} ${values.tokenPrice ? values.tokenPrice * values.tokensAmount : 0}`}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Field
                            fullWidth
                            component={TextField}
                            name="tokenPrice"
                            label="Token Price"
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{values.currency}</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Field fullWidth component={TextField} name="buybackTokenPrice" type="number" label="Buyback Token Price" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Field fullWidth component={TextField} name="tokenAddress" label="Token Address" />
                    </Grid>

                    <GridHeader title="Durations" />
                    <Grid item xs={12} sm={6} alignItems="center">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Field component={DatePicker} name="projectStartsAt" label="Start of Project" slotProps={slotProps} />
                            <span>&rarr;</span>
                            <Field component={DatePicker} name="maturityDate" label="Maturity Date" minDate={Date.now()} slotProps={slotProps} />
                        </Stack>
                        <Typography variant="caption" color="text.secondary">
                            Duration (year/s): {values.projectStartsAt && values.maturityDate ? diffYears(values.projectStartsAt, values.maturityDate) : 'n/a'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Field component={DatePicker} width="100px" name="saleStartsAt" label="Sale Starts At" slotProps={slotProps} />
                            <span>&rarr;</span>
                            <Field
                                component={DatePicker}
                                name="saleEndsAt"
                                label="Sale Ends At (excluding)"
                                minDate={values.saleStartsAt || Date.now()}
                                slotProps={slotProps}
                            />
                        </Stack>
                    </Grid>

                    <GridHeader title="Bank Details" />
                    <Grid item xs={12} sm={4}>
                        <Field fullWidth component={TextField} name="bankAccountName" label="Bank Account Name" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Field fullWidth component={TextField} name="iban" label="IBAN" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Field fullWidth component={TextField} name="bankName" label="Bank Name" />
                    </Grid>

                    <GridHeader title="Effecta" />
                    <Grid item xs={12} sm={4}>
                        <Field fullWidth component={TextField} name="effectaProductId" label="Product ID" />
                    </Grid>

                    <GridHeader title="Payouts Bank Details" />
                    <Grid item xs={12} sm={4}>
                        <Field fullWidth component={TextField} name="payoutsAccountDetails.accountName" label="Bank Account Name" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Field fullWidth component={TextField} name="payoutsAccountDetails.iban" label="IBAN" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Field fullWidth component={TextField} name="payoutsAccountDetails.bic" label="BIC" />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Field fullWidth component={TextField} name="payoutsAccountDetails.bankName" label="Bank Name" />
                    </Grid>
                </Grid>
                <Stack sx={{ p: 2 }} direction="row" justifyContent="end">
                    <LoadingButton type="submit" variant="contained" color="primary" loading={isSubmitting || mutation.isLoading}>
                        Save
                    </LoadingButton>
                    {project?.status === 'DRAFT' && (
                        <LoadingButton
                            sx={{ ml: 2 }}
                            variant="contained"
                            color="secondary"
                            disabled={dirty}
                            loading={publishProjectMutation.isLoading}
                            onClick={onPublish}
                        >
                            Publish project
                        </LoadingButton>
                    )}
                </Stack>
            </Form>
        </FormikProvider>
    );
};

const GridHeader: FC<{ title: string }> = ({ title }) => (
    <Grid item xs={12}>
        <Typography color="text.secondary" variant="h5" mt={2}>
            {title}
        </Typography>
    </Grid>
);

export default ProjectGeneralForm;
