import { Icon } from '@iconify/react';
import { Avatar, Box, Button, Divider, IconButton, MenuItem, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MenuPopover from '../../components/MenuPopover';
import { useAuth } from '../../hooks/use-auth';
import { accountPopoverOptions } from '../../menus';

export default function AccountPopover() {
    const auth = useAuth();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleLogOut = () => auth.logOut();

    const { user } = auth;

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
                        },
                    }),
                }}
            >
                <Avatar src={user.photoUrl} alt="photoURL" />
            </IconButton>

            <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {user.displayName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user.email}
                    </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />

                {accountPopoverOptions.map(option => (
                    <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose} sx={{ typography: 'body2', py: 1, px: 2.5 }}>
                        <Box
                            component={Icon}
                            icon={option.icon}
                            sx={{
                                mr: 2,
                                width: 24,
                                height: 24,
                            }}
                        />

                        {option.label}
                    </MenuItem>
                ))}

                <Box sx={{ p: 2, pt: 1.5 }}>
                    <Button fullWidth color="inherit" variant="outlined" onClick={handleLogOut}>
                        Logout
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}
