import AddIcon from '@mui/icons-material/Add';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { IProject } from '../../../../../domain-entities/models/project';
import type { DataGridProps } from '../../../components/DataGrid';
import DataGrid, { useGridColumns } from '../../../components/DataGrid';
import ConfirmDialog from '../../../components/Dialog';
import Page from '../../../components/Page';
import StatusChip from '../../../components/StatusChip';
import { getStatusColor } from '../../../helpers/color';
import { useDeleteProjectMutation } from '../../../hooks/use-mutations';

export default function ProjectsListPage() {
    const { enqueueSnackbar } = useSnackbar();
    const [dialogOpened, setDialogOpened] = useState(false);
    const [projectToDelete, setProject] = useState<IProject>();

    const mutation = useDeleteProjectMutation(
        () => {
            setDialogOpened(false);
            enqueueSnackbar('Project have been deleted', { variant: 'success' });
        },
        error => {
            enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
        },
    );

    const columns = useGridColumns<IProject>([
        { accessorKey: 'code', header: 'Code', minSize: 200 },
        {
            accessorKey: 'status',
            header: 'Status',
            minSize: 150,
            filterVariant: 'multi-select',
            filterSelectOptions: ['CREATED', 'FUNDING_INPROGRESS', 'FUNDING_COMPLETE', 'FUNDING_FAILED'],
            Cell: ({ cell, row }) => (
                <>
                    <StatusChip label={cell.getValue()} size="small" color={getStatusColor(cell.getValue<string>())} />
                    {row.original.isPrivate ? <LoyaltyIcon fontSize="small" /> : null}
                </>
            ),
        },
        {
            accessorKey: 'projectStartsAt',
            sortingFn: 'datetime',
            header: 'Project Starts',
            dataType: 'date',
        },
        {
            accessorKey: 'saleStartsAt',
            sortingFn: 'datetime',
            header: 'Sales Starts',
            dataType: 'date',
        },
        {
            accessorKey: 'saleEndsAt',
            header: 'Sales Ends',
            dataType: 'date',
        },
    ]);

    const dataGridProps: DataGridProps<IProject> = {
        columns,
        dataFetch: { key: 'PROJECTS' },
        actions: {
            edit(row) {
                window.open(`/projects/edit/${row.id}`, '_blank');
            },
            delete(row) {
                setDialogOpened(true);
                setProject(row);
            },
        },
    };

    return (
        <Page title="Projects">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Projects
                        </Typography>
                        <Button variant="contained" component={RouterLink} to="/projects/add" startIcon={<AddIcon />}>
                            Add Project
                        </Button>
                    </Stack>
                    <Card>
                        <DataGrid {...dataGridProps} />
                    </Card>
                </Box>
                <ConfirmDialog
                    content="Are you sure you want to delete the project?"
                    title="Project deletion"
                    isOpened={dialogOpened}
                    handleClose={() => setDialogOpened(false)}
                    handleConfirm={() => {
                        mutation.mutate(projectToDelete.id);
                    }}
                />
            </Container>
        </Page>
    );
}
