import type { AxiosInstance } from 'axios';
import axios from 'axios';
import type { AuthService } from './AuthService';

export default function getClient(baseURL: string, authService: AuthService): AxiosInstance {
    const client = axios.create({
        baseURL,
    });

    client.interceptors.request.use(config => {
        if (config?.params) {
            const url = Object.keys(config.params).reduce((finalUrl, param) => {
                const value = config.params[param];
                if (finalUrl.includes(`:${param}`)) {
                    // if it's replaced in path params - no need to send it in querystring
                    delete config.params[param];
                }
                return finalUrl?.replace(new RegExp(`:${param}`, 'g'), value?.toString());
            }, config.url);
            config.url = url;
        }
        return authService.getToken().then(token => {
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });
    });

    client.interceptors.response.use(
        response => response,
        error => {
            const { response } = error;
            if (response?.data?.message) {
                error.message = response.data.message;
                error.code = response.data.code;
            }
            return Promise.reject(error);
        },
    );

    return client;
}
