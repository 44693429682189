import { Box, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import type { GetUserFullDataResponse } from '../../../../../schemas';
import UserDeleteButton from './dangerZone/UserDeleteButton';
import UserOffboardingButton from './dangerZone/UserOffboardButton';

interface Props {
    user: GetUserFullDataResponse;
}

const UserDangerZone: FC<Props> = ({ user }) => {
    return (
        <Box
            sx={theme => ({
                bgcolor: 'background.warning',
                gap: 2,
                borderRadius: theme.spacing(1),
                padding: theme.spacing(2),
            })}
        >
            <Typography color="error.main" gutterBottom variant="h5" mb={2}>
                Danger Zone
            </Typography>
            <Stack direction="row" justifyContent="start" gap={2}>
                <UserDeleteButton user={user} />
                <UserOffboardingButton user={user} />
            </Stack>
        </Box>
    );
};

export default UserDangerZone;
