import CreditCardOff from '@mui/icons-material/CreditCardOff';
import PersonOff from '@mui/icons-material/PersonOff';
import type { IBankTransaction } from '../../../domain-entities/models/bank-transaction';
import type { IOrder, OrderStatus, OrderWithItems } from '../../../domain-entities/models/order';
import type { IOrderItem } from '../../../domain-entities/models/order-item';
import type { EffectaProcessState } from '../../../infrastructure/external/effecta';

export type OrderResolvedState = OrderStatus | 'ON_HOLD' | 'OVERPAID' | 'UNDERPAID' | 'REFUND_PENDING' | 'REFUNDED' | 'BUYBACK';

export function getOrderFlagIcons(order: Required<IOrder> & { orderItems: Required<IOrderItem>[]; bankTransactions?: Required<IBankTransaction>[] }) {
    const icons = [];
    if ((['REJECTED', 'UPDATE_REQUIRED', 'UNKNOWN'] as EffectaProcessState[]).includes(order.metadata?.effectaState)) {
        icons.push({ icon: PersonOff, title: 'Effecta issue' });
    }
    if (!order?.bankTransactions?.some(({ data }) => data?.isNameMatching)) {
        icons.push({ icon: CreditCardOff, title: 'Sender name mismatch' });
    }
    return icons;
}

// Shared functionality with client. See invest-client/src/web/ui/helpers
export function getUserProfileOrdersAmount(orders: OrderWithItems[]) {
    if (!orders?.length) {
        return 0;
    }
    return orders?.reduce((total, { status, orderItems }) => {
        if (status === 'BUYBACK') {
            return total;
        }
        const itemsAmount = orderItems.reduce((result, { type, status, receivedAmount, amount }) => {
            if (type === 'VOUCHER' && ['PAID', 'OPEN'].includes(status)) {
                return result + amount;
            }
            if (type === 'PRIMARY' && ['PAID', 'OPEN'].includes(status)) {
                return result + receivedAmount;
            }

            return result;
        }, 0);
        return total + itemsAmount;
    }, 0);
}

export function getOrderState(order: OrderWithItems): OrderResolvedState {
    if (order.isOnhold) {
        return 'ON_HOLD';
    }

    if (order.status === 'BUYBACK') {
        return 'BUYBACK';
    }

    // take into account only primary items
    const items = order.orderItems.filter(item => item.type === 'PRIMARY');

    // overpaid case - at least one item is overpaid
    const isOverPaid = items.some(({ status, receivedAmount, amount }) => status === 'PAID' && receivedAmount > amount);
    // underpaid case - at least one item is underpaid
    const isUnderPaid = items.some(({ status, receivedAmount, amount }) => status === 'OPEN' && receivedAmount && receivedAmount < amount);
    // refund started
    const isRefundPending = items.some(({ status }) => status === 'REFUND_PENDING');
    // all items are refunded
    const isRefunded = items.some(({ status }) => status === 'REFUNDED');
    // all primaries are paid
    const isPaid = items.every(({ status }) => status === 'PAID');

    switch (true) {
        case isOverPaid:
            return 'OVERPAID';
        case isUnderPaid:
            return 'UNDERPAID';
        case isRefundPending:
            return 'REFUND_PENDING';
        case isRefunded:
            return 'REFUNDED';
        case isPaid:
            return 'PAID';
        default:
            return order.status;
    }
}
