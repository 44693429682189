import { currency } from '@condo/formatters';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import type { GetUserFullDataResponse } from '../../../../schemas';
import { getUserProfileOrdersAmount } from '../../helpers/order';
import { useGetUserOrders } from '../../hooks/use-queries';
import DataCard from '../DataCard';

interface Props {
    user: GetUserFullDataResponse;
}

const UserProfilePortfolioSummary: FunctionComponent<Props> = ({ user }) => {
    const { isLoading, data: orders, error } = useGetUserOrders(user.id);
    const userProfileInvestedAmount = getUserProfileOrdersAmount(orders);

    const { enqueueSnackbar } = useSnackbar();

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    if (!isLoading && error) {
        onError(error);
    }

    return (
        <Stack direction="row" gap={2} sx={{ mb: 2 }}>
            <DataCard title="Invested amount" value={isLoading ? '...' : currency(userProfileInvestedAmount)} />
            <DataCard title="Available Balance" value={currency(user.currentBalance)} />
        </Stack>
    );
};

export default UserProfilePortfolioSummary;
