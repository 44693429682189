import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { IDistribution } from '../../../../../domain-entities/models/distribution';
import { useCheckDistributionOnboardingStatusQuery } from '../../../hooks/use-queries';

interface Props {
    distribution: IDistribution;
}

const OnboardingProgress: FC<Props> = ({ distribution }) => {
    const [isEnabled, setIsEnabled] = useState(distribution.status === 'INPROGRESS');
    const { data, refetch } = useCheckDistributionOnboardingStatusQuery(distribution.id, isEnabled);

    useEffect(() => {
        if (distribution.status === 'INPROGRESS') {
            refetch();
        } else {
            setIsEnabled(false);
        }
    }, [distribution.status, refetch]);

    if (!data || distribution.status === 'PREPARED') {
        return null;
    }

    return (
        <Box sx={{ my: 2, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ minWidth: 150 }}>
                <Typography variant="body2" color="text.secondary">
                    Onboarding Progress:
                </Typography>
            </Box>
            <Box sx={{ width: '100%', mx: 2 }}>
                <LinearProgress variant="determinate" value={(data.onboardedUsers / data.totalUsers) * 100} />
            </Box>
            <Box sx={{ minWidth: 70 }}>
                <Typography variant="body2" color="text.secondary">{`${data.onboardedUsers}/${data.totalUsers} Users`}</Typography>
            </Box>
        </Box>
    );
};

export default OnboardingProgress;
