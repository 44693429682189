import type { FunctionComponent } from 'react';
import StatusChip from '../StatusChip';

interface OrderStatusChipProps {
    status: string;
}

const statusColor = {
    EXISTS: 'default',
    SKIPPED: 'default',
    PENDING: 'info',
    RECONCILED: 'success',
};

const TransactionStatusChip: FunctionComponent<OrderStatusChipProps> = ({ status }) => {
    return <StatusChip label={status} size="small" color={statusColor[status] || 'default'} />;
};

export default TransactionStatusChip;
