import type { Endpoints } from './types';

const endpoints: Endpoints = {
    LIST: {
        HISTORY: {
            url: '/api/history/list',
            method: 'GET',
        },
    },
};

export default endpoints;
