import { Box } from '@mui/material';
import type { SxProps } from '@mui/system';
import type { FC } from 'react';

interface Props {
    sx?: SxProps;
}

const Logo: FC<Props> = ({ sx }) => <Box component="img" src="/static/media/logo-investable.svg" sx={{ width: 160, height: 40, ...sx }} />;

export default Logo;
