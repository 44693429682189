import { currency, percent } from '@condo/formatters';
import Typography from '@mui/material/Typography';
import type { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import _isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import type { GetVoucherResponse } from '../../../../../schemas';
import { getStatusColor } from '../../../helpers/color';
import { useGetUserVouchers } from '../../../hooks/use-queries';
import Loader from '../../Loader';
import Section from '../../Section';
import StatusChip from '../../StatusChip';

interface Props {
    userId: string;
}

const UserVouchers: FC<Props> = ({ userId }) => {
    const { isLoading, data: vouchers, error } = useGetUserVouchers(userId);
    const { enqueueSnackbar } = useSnackbar();

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    if (!isLoading && error) {
        onError(error);
    }

    const columns: GridColumns = [
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<GetVoucherResponse>) => (
                <StatusChip label={params.value} size="small" color={getStatusColor(params.value)} />
            ),
        },
        {
            field: 'code',
            headerName: 'Code',
            minWidth: 200,
            renderCell: (params: GridRenderCellParams<GetVoucherResponse>) => params.value,
        },
        {
            field: 'orderItem',
            headerName: 'Order Item Code',
            minWidth: 200,
            renderCell: (params: GridRenderCellParams<GetVoucherResponse>) => {
                const orderItem = params.row.orderItem;
                return params.row.status !== 'ACTIVE' && orderItem.length > 0 ? orderItem[0].code : '';
            },
        },
        {
            field: 'value',
            headerName: 'Value',
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<GetVoucherResponse>) => {
                return params.row.valueType === 'AMOUNT' ? currency(params.row.value) : percent(params.row.value / 100);
            },
        },
        {
            field: 'isAutoApplied',
            headerName: 'Auto Applied',
            minWidth: 150,
            type: 'boolean',
        },
        {
            field: 'isEnabled',
            headerName: 'Enabled',
            type: 'boolean',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            type: 'date',
            valueGetter: (params: GridRenderCellParams<string, GetVoucherResponse>) => new Date(params.value),
        },
    ];

    return (
        <Section title="User Vouchers">
            {isLoading ? (
                <Loader />
            ) : _isEmpty(vouchers) ? (
                <Typography color="text.primary">No User Vouchers found</Typography>
            ) : (
                <DataGrid rows={vouchers} columns={columns} error={error} autoHeight loading={isLoading} checkboxSelection={false} />
            )}
        </Section>
    );
};

export default UserVouchers;
