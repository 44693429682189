import { currency } from '@condo/formatters';
import Card from '@mui/material/Card';
import type { FC } from 'react';
import type { IBankTransaction } from '../../../../../domain-entities/models/bank-transaction';
import type { OrderStatus, OrderWithItems } from '../../../../../domain-entities/models/order';
import type { IOrderItem } from '../../../../../domain-entities/models/order-item';
import type { IProject } from '../../../../../domain-entities/models/project';
import type { IUserFullData } from '../../../../../domain-entities/models/user';
import type { DataGridProps } from '../../../components/DataGrid';
import DataGrid, { useGridColumns } from '../../../components/DataGrid';
import FlagsCell from '../../../components/order/FlagsCell';
import OrderItemsCell from '../../../components/order/OrderItemsCell';
import OrderStatusChip from '../../../components/order/OrderStatusChip';
import { getOrderState } from '../../../helpers/order';
import PageWrapper from '../../PageWrapper';

interface Props {
    title: string;
    entity: 'order' | 'order-item' | 'payment-request';
}
const orderFilterOptions: OrderStatus[] = ['OPEN', 'CANCELLED', 'PAID', 'SETTLED', 'BUYBACK'];
type GridData = OrderWithItems & { project: Required<IProject>; user: IUserFullData; bankTransactions?: Required<IBankTransaction>[] };

const OrdersListPage: FC<Props> = ({ title, entity }) => {
    const columns = useGridColumns<GridData>([
        {
            id: 'userOrderItemStatus',
            header: 'Resolved State',
            size: 100,
            enableColumnFilter: false,
            enableSorting: false,
            Cell: ({ row }) => {
                const orderState = getOrderState(row.original);
                return <OrderStatusChip orderStatus={orderState as OrderStatus} />;
            },
        },
        {
            accessorKey: 'project.code',
            header: 'Project Code',
            size: 120,
        },
        {
            accessorKey: 'code',
            header: 'Order code',
            size: 90,
        },
        {
            id: 'user.name',
            header: 'User Name',
            size: 150,
            accessorFn: row => `${row.user?.profile?.firstName} ${row.user?.profile?.lastName}`,
        },
        {
            accessorKey: 'status',
            header: 'Order Status',
            size: 120,
            filterVariant: 'multi-select',
            filterSelectOptions: orderFilterOptions,
            Cell: ({ cell }) => {
                return <OrderStatusChip variant="outlined" orderStatus={cell.getValue<OrderStatus>()} />;
            },
        },
        {
            accessorKey: 'orderItems',
            header: 'Items (paid/amount)',
            size: 150,
            enableColumnFilter: false,
            Cell: ({ cell }) => {
                return <OrderItemsCell items={cell.getValue<IOrderItem[]>()} />;
            },
        },
        {
            accessorKey: 'tokens',
            header: 'Amount',
            size: 150,
            dataType: 'number',
            Cell: ({ row }) => {
                return currency(row.original.tokens * row.original.tokenPrice);
            },
        },
        {
            accessorKey: 'createdAt',
            header: 'Created At',
            dataType: 'date-time',
            // accessorFn: row => docDateFormat(row.createdAt),
        },
        {
            id: 'flags',
            header: 'Flags',
            size: 100,
            Cell: ({ row }) => <FlagsCell order={row.original} />,
        },
    ]);

    const dataGridProps: DataGridProps<GridData> = {
        columns,
        defaultPageSize: 100,
        dataFetch: {
            key: entity === 'order' ? 'ORDERS' : 'PAYMENT_REQUESTS',
        },
        actions: {
            view(row) {
                const { id } = row;
                const url = entity === 'order' ? `/orders/view/${id}` : `/payment-requests/view/${id}`;
                window.open(url, '_blank');
            },
        },
    };

    return (
        <PageWrapper title={title}>
            <Card>
                <DataGrid {...dataGridProps} />
            </Card>
        </PageWrapper>
    );
};
export default OrdersListPage;
