import { currency } from '@condo/formatters';
import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';
import type { FC } from 'react';
import type { IOrderItem } from '../../../../domain-entities/models/order-item';
import { getStatusColor } from '../../helpers/color';
import { pxToRem } from '../../theme/typography';

interface Props {
    items?: IOrderItem[];
}
const OrderItemsCell: FC<Props> = ({ items }) => {
    if (!items?.length) {
        return null;
    }

    return (
        <Box>
            {items.map(item => (
                <Box sx={{ fontSize: pxToRem(10) }} key={item.code}>
                    <CircleIcon sx={{ fontSize: pxToRem(8) }} color={getStatusColor(item.status, 'disabled')} />
                    <b>{item.type}:</b> {currency(item.receivedAmount)}/{currency(item.amount)}
                </Box>
            ))}
        </Box>
    );
};

export default OrderItemsCell;
