import { currency, percentage } from '@condo/formatters';
import Stack from '@mui/material/Stack';
import type { FC } from 'react';
import DataCard from './DataCard';

export interface Stat {
    type?: 'currency' | 'percentage';
    value: string | number;
    label: string;
}
interface Props {
    stats: Stat[];
}

const StatsCards: FC<Props> = ({ stats }) => {
    return (
        <Stack direction="row" flexWrap="wrap" gap={2} justifyContent="center" sx={{ mt: 6, mb: 2 }}>
            {stats.map(({ type, value: rawValue, label }) => {
                let value = rawValue;
                if (type === 'currency') {
                    value = currency(rawValue);
                } else if (type === 'percentage') {
                    value = percentage(rawValue as number, 1, 1);
                }
                return <DataCard variant="small" key={label} title={label} value={value?.toString()} />;
            })}
        </Stack>
    );
};

export default StatsCards;
