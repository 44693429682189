import { Card } from '@mui/material';
import type { FC } from 'react';
import { HistoryEventsTable } from '../../../components/HistoryTimeline/HistoryEventsTable';
import PageWrapper from '../../PageWrapper';

export const HistoryEventsListPage: FC = () => {
    return (
        <PageWrapper title="History Events">
            <Card>
                <HistoryEventsTable />
            </Card>
        </PageWrapper>
    );
};
