import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { APPBAR_DESKTOP, APPBAR_MOBILE } from '../../../../constants';
import { useAuth } from '../../hooks/use-auth';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APPBAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APPBAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export default function DashboardLayout() {
    const auth = useAuth();
    const location = useLocation();
    const [open, setOpen] = useState(false);

    if (!auth.isLoggedIn()) {
        return (
            <Navigate
                to={{
                    pathname: '/login',
                }}
                state={{ from: location }}
            />
        );
    }

    return (
        <RootStyle>
            <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
            <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle>
                <Outlet />
            </MainStyle>
        </RootStyle>
    );
}
