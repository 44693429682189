import Card from '@mui/material/Card';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import Loader from '../../components/Loader';
import VoucherAccountForm from '../../components/VoucherAccount/Form';
import { useGetSettings } from '../../hooks/use-queries';
import PageWrapper from '../PageWrapper';

interface VoucherAccountPageProps {}

const VoucherAccountPage: FunctionComponent<VoucherAccountPageProps> = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { data: settings, isLoading } = useGetSettings('VOUCHER_ACCOUNT');

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    function onSuccess() {
        enqueueSnackbar('Voucher account settings have been updated', { variant: 'success', preventDuplicate: true });
    }

    return (
        <PageWrapper title="Voucher Account">
            <Card>
                {isLoading ? (
                    <Loader />
                ) : (
                    <VoucherAccountForm onError={onError} onSuccess={onSuccess} voucherBankAccount={settings?.voucherBankAccount?.value} />
                )}
            </Card>
        </PageWrapper>
    );
};

export default VoucherAccountPage;
