import { Typography } from '@mui/material';
// @ts-expect-error
import _startCase from 'lodash/startCase';
import type { FC } from 'react';
import type { UserHistoryEvent } from '../../../infrastructure/api/history/types';
import type { DataGridProps } from '../DataGrid';
import DataGrid, { useGridColumns } from '../DataGrid';
import { getTimelineContent, getTimelineIcon } from './ElementFactory';
import { EVENT_TYPES_FILTER_LIST } from './constants';

interface HistoryEventsTableProps {
    userId?: string;
}

export const HistoryEventsTable: FC<HistoryEventsTableProps> = ({ userId }) => {
    const columns = useGridColumns<UserHistoryEvent>([
        {
            accessorKey: 'eventDate',
            header: 'Event Date',
            dataType: 'date-time',
        },
        {
            accessorKey: 'eventName',
            header: 'Event Name',
            minSize: 120,
            filterVariant: 'multi-select',
            filterSelectOptions: EVENT_TYPES_FILTER_LIST,
            Cell: ({ cell, row }) => {
                return (
                    <>
                        {getTimelineIcon(row.original)}
                        <Typography sx={{ verticalAlign: 'super' }} display="inline">
                            {_startCase(cell.getValue<string>())}
                        </Typography>
                    </>
                );
            },
        },
        {
            accessorKey: 'context',
            header: 'Context',
            Cell: ({ row }) => {
                return getTimelineContent(row.getValue<string>('eventName'), row.original);
            },
        },
        {
            accessorKey: 'userId',
            header: 'User ID',
        },
    ]);

    const dataGridProps: DataGridProps<UserHistoryEvent> = {
        columns,
        dataFetch: { key: 'HISTORY', params: { userId } },
        fetchService: 'HISTORY_SERVICE',
        actions: {
            view(row) {
                window.open(`/users/view/${row.userId}`, '_blank');
            },
        },
    };

    return <DataGrid {...dataGridProps} />;
};
