import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SearchIcon from '@mui/icons-material/Search';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useAssignOrderItem } from '../../hooks/use-mutations';
import { useFindOrderItem } from '../../hooks/use-queries';
import Section from '../Section';
import OrderDetailsBox from '../order/OrderDetailsBox';
import OrderItemDetailsBox from '../order/OrderItemDetailsBox';

interface Props {
    bankTransactionId: string;
}

const ManualMatchForm: FC<Props> = ({ bankTransactionId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [keyword, setKeyword] = useState<string | null>(null);
    const onError = error => {
        enqueueSnackbar(`Operation failed: ${error}`, { variant: 'error' });
    };
    const { isLoading, error, isSuccess, data: orderItem } = useFindOrderItem(keyword);
    const mutationAssign = useAssignOrderItem(isSuccess => {
        if (isSuccess) {
            enqueueSnackbar('Transaction was successfully reconciled', {});
        } else {
            enqueueSnackbar('Failed to reconcile the transaction', { variant: 'error' });
        }
    }, onError);

    useEffect(() => {
        if (error) {
            onError(error);
        }
    }, [error]);

    const onSearch = async ({ keyword }) => {
        setKeyword(keyword);
    };
    const onAssign = () => {
        if (orderItem) {
            mutationAssign.mutate({ bankTransactionId, orderItemId: orderItem.id, orderId: orderItem.orderId });
        }
    };

    return (
        <Section title="Match to Order">
            <Box sx={{ mb: 1 }}>
                <Formik initialValues={{ keyword: '' }} onSubmit={onSearch}>
                    {({ isSubmitting, submitForm }) => (
                        <Form>
                            <Stack direction="row" justifyContent="space-between">
                                <Field component={TextField} name="keyword" placeholder="e.g. order code or ID" label="Keyword" />
                                <LoadingButton startIcon={<SearchIcon />} loading={isSubmitting || isLoading} onClick={submitForm}>
                                    Find
                                </LoadingButton>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Box>
            {orderItem ? (
                <Stack direction="column" spacing={1} justifyContent="space-between">
                    <OrderItemDetailsBox orderItem={orderItem} />
                    <OrderDetailsBox order={orderItem.order} />
                    <LoadingButton variant="contained" disabled={orderItem.status !== 'OPEN'} startIcon={<PriceCheckIcon />} onClick={onAssign}>
                        Assign Order
                    </LoadingButton>
                </Stack>
            ) : null}
            {!orderItem && isSuccess ? <Alert severity="warning">Order Item is not found</Alert> : null}
        </Section>
    );
};

export default ManualMatchForm;
