export default function Card(theme) {
    return {
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: theme.spacing(2),
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: theme.spacing(2),
                },
            },
        },
    };
}
