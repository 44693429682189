import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import NotFoundEntity from '../../../components/NotFoundEntity';
import { useGetJob } from '../../../hooks/use-queries';
import PageWrapper from '../../PageWrapper';
import JobViewData from './JobViewData';

const JobViewPage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { jobId } = useParams();
    const { isLoading, data: job, error } = useGetJob(jobId);

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    if (!isLoading && error) {
        onError(error);
    }

    const breadcrumbs = {
        '/dashboard': 'Dashboard',
        '/jobs': 'Jobs',
        '': isLoading ? '...' : jobId,
    };

    return (
        <PageWrapper title="Job Details" breadcrumbs={breadcrumbs}>
            {isLoading ? <Loader /> : <>{!job ? <NotFoundEntity name="Job" /> : <JobViewData job={job} />}</>}
        </PageWrapper>
    );
};

export default JobViewPage;
