import { Box, Card, Container, Stack, Typography } from '@mui/material';
import Page from '../components/Page';
import ProjectsFundingStats from '../components/reports/ProjectsFundingStats';
import RegistrationsFunnel from '../components/reports/RegistrationsFunnel';

export default function DashboardApp() {
    return (
        <Page title="Dashboard">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4" gutterBottom>
                        Hi, Welcome back
                    </Typography>
                    <Stack gap={3} direction="column">
                        <Card>
                            <RegistrationsFunnel />
                        </Card>
                        <Card>
                            <ProjectsFundingStats />
                        </Card>
                    </Stack>
                </Box>
            </Container>
        </Page>
    );
}
