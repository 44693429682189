import personFill from '@iconify/icons-eva/person-fill';
import { InlineIcon } from '@iconify/react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';

export default function AlternativeMethods() {
    const auth = useAuth();
    const navigate = useNavigate();

    async function onClick() {
        const isSuccess = await auth.logInWithProvider();
        if (isSuccess) {
            navigate('/');
        }
    }

    return (
        <>
            <Stack direction="row" spacing={2}>
                <Button fullWidth size="large" color="inherit" variant="outlined" onClick={onClick}>
                    <InlineIcon icon={personFill} color="#00A9E0" height={24} />
                    OneLogin
                </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    OR
                </Typography>
            </Divider>
        </>
    );
}
