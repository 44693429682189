import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import type { OrderStatus } from '../../../../../domain-entities/models/order';
import ActionButton from '../../../components/ActionButton';
import ActionsPanel from '../../../components/ActionsPanel';
import Loader from '../../../components/Loader';
import NotFoundEntity from '../../../components/NotFoundEntity';
import OrderDetailsBox from '../../../components/order/OrderDetailsBox';
import OrderItems from '../../../components/order/OrderItems';
import { useCancelOrderMutation, useResendEffectaOrder, useSettleOrderMutation } from '../../../hooks/use-mutations';
import { useGetOrder } from '../../../hooks/use-queries';
import PageWrapper from '../../PageWrapper';

const OrderViewPage: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { orderId } = useParams();
    const { isLoading, data: order, error } = useGetOrder(orderId);

    const cancelMutation = useCancelOrderMutation(
        () => {
            enqueueSnackbar('Order has been cancelled', { variant: 'success' });
        },
        error => {
            enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
        },
    );
    const settleMutation = useSettleOrderMutation(() => {
        enqueueSnackbar('Order has been settled', { variant: 'success' });
    });

    const effectaOrderMutation = useResendEffectaOrder(
        () => enqueueSnackbar('Order data was scheduled for sending to Effecta', { variant: 'success' }),
        error => enqueueSnackbar(`Unable to resend order data: ${error.message}`, { variant: 'error' }),
    );

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    if (!isLoading && error) {
        onError(error);
    }

    const wrapperProps = {
        title: 'Order Details',
        breadcrumbs: {
            '/dashboard': 'Dashboard',
            '/orders': 'Orders',
            '': isLoading ? '...' : order?.code,
        },
    };

    if (isLoading) {
        return (
            <PageWrapper {...wrapperProps}>
                <Loader />
            </PageWrapper>
        );
    }

    if (!order) {
        return (
            <PageWrapper {...wrapperProps}>
                <NotFoundEntity name="Order" />
            </PageWrapper>
        );
    }

    const orderCreatedDaysBefore = dayjs().diff(dayjs(order.createdAt), 'days');
    const isEffectaApproved = order.metadata?.effectaState === 'APPROVED';
    const isAlreadyPaid = order.orderItems.reduce((total, { receivedAmount }) => total + receivedAmount, 0) > 0;
    const canBeCancelled = order.status === 'OPEN' || (orderCreatedDaysBefore <= 14 && order.status === 'PAID');
    const canBeRefunded = isAlreadyPaid && (['OPEN', 'PAID'] as OrderStatus[]).includes(order.status);
    const canBeDistributed = order.status === 'PAID' && orderCreatedDaysBefore > 14;

    return (
        <PageWrapper {...wrapperProps} status={order.status}>
            <Box
                sx={{
                    bgcolor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <OrderDetailsBox order={order} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <OrderItems orderItems={order.orderItems} />
                    </Grid>
                </Grid>

                <ActionsPanel>
                    <ActionButton
                        label={canBeRefunded ? 'Request Refund' : 'Cancel Order'}
                        dialogTitle={canBeRefunded ? 'Request Refund' : 'Order Cancellation'}
                        dialogContent={`Are you sure you want to cancel the order ${
                            canBeRefunded ? ' and create a refund request' : ''
                        } on behalf of the user?`}
                        disabled={!canBeCancelled}
                        onConfirm={() =>
                            cancelMutation.mutate({
                                orderCode: order.code,
                                userId: order.userId,
                                orderId: order.id,
                            })
                        }
                    />
                    {!isEffectaApproved ? (
                        <ActionButton
                            label="Resend to Effecta"
                            dialogTitle="Resend order data"
                            dialogContent="Are you sure that Effecta order data transfer should be re-scheduled?"
                            onConfirm={() => effectaOrderMutation.mutate({ orderId: order.id, userId: order.userId })}
                        />
                    ) : null}

                    <ActionButton
                        label="Mark as Distributed"
                        dialogContent="Do you want to mark this order as distributed?"
                        disabled={!canBeDistributed}
                        onConfirm={() => settleMutation.mutate(order.id)}
                    />
                </ActionsPanel>
            </Box>
        </PageWrapper>
    );
};

export default OrderViewPage;
