type Color = 'inherit' | 'action' | 'disabled' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

export function getStatusColor(status: string, defaultColor: Color = 'default'): any {
    switch (status) {
        case 'OPEN':
        case 'REFUND_PENDING': // order item
        case 'FUNDING_INPROGRESS': // project
        case 'PREPARED': // payout report, distribution
        case 'RUNNING': // jobs
        case 'INPROGRESS': // distribution
        case 'REDEEMED': // voucher
            return 'info';

        case 'PAID':
        case 'SETTLED':
        case 'PUBLISHED': // payout report
        case 'FUNDING_COMPLETE': // project
        case 'COMPLETED': // distribution
        case 'BUYBACK': // order
            return 'success';

        case 'UNDERPAID':
        case 'OVERPAID':
        case 'ON_HOLD':
        case 'FUNDING_FAILED': // project
        case 'FAILED': // distribution
            return 'warning';

        default:
            return defaultColor as string;
    }
}
