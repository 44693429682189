import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import type { FC, PropsWithChildren } from 'react';

const Wrapper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1, 2),
    background: (theme.palette.background as any).neutral,
}));

const SummaryBox: FC<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <Wrapper>
            <Stack direction="row" alignItems="center" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
                {children}
            </Stack>
        </Wrapper>
    );
};

export default SummaryBox;
