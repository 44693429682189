import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { DEFAULT_CURRENCY } from '../../../../../../constants';
import { defaultValues } from '../../constants';
import { renderControl } from '../../shared/form';
import type { BaseProjectComponentProps } from '../../types';
import EstateMedia from './EstateMedia';

type TDetailsKeys = keyof typeof defaultValues.estateDetails;

type Props = BaseProjectComponentProps & { projectId: string; currentTab?: number };

function render(key: TDetailsKeys, label: string, props?: Record<string, any>) {
    const item = defaultValues.estateDetails[key];
    return renderControl(`estateDetails.${key}`, label, item.format, props);
}

const EstateDetailsForm: FC<Props> = ({ onSuccess, projectId, onError, currentTab }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {render('description', 'Description')}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('livingSpace', 'Living Space (m2)')}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('numberOfRooms', 'Number of Rooms')}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('constructionYear', 'Construction Year')}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('renovationYear', 'Renovation Year')}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('propertyValue', 'Property Value', { adornment: DEFAULT_CURRENCY })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('rentPrice', 'Rent Price/m2', { adornment: DEFAULT_CURRENCY })}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('latitude', 'Location Latitude')}
            </Grid>
            <Grid item xs={12} sm={4}>
                {render('longitude', 'Location Longitude')}
            </Grid>
            <Grid item xs={12}>
                <Typography color="text.secondary" variant="h5" mt={2}>
                    Gallery
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <EstateMedia {...{ projectId, onSuccess, onError, currentTab }} />
            </Grid>
        </Grid>
    );
};

export default EstateDetailsForm;
