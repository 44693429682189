import type { IModel, ValueDataFormat } from './base';
import type { SettingsCategory } from '.prisma/client';

export enum SettingKey {
    ReferralCampaign = 'referralCampaign',

    // static documents
    EffectaAgb = 'effectaAgb',
    EffectaCustomerInfo = 'effectaCustomerInfo',
    EffectaConsumerInfo = 'effectaConsumerInfo',
    /**
     * @deprecated
     */
    ConsumerInfo = 'consumerInfo',

    TanganyAgb = 'tanganyAgb',
    PlatformAgb = 'platformAgb',

    // templates
    InvestmentReceiptTemplate = 'investmentReceiptTemplate',
    InvestmentReceiptFinalTemplate = 'investmentReceiptFinalTemplate',
    QuizSummaryTemplate = 'quizSummaryTemplate',
    ProjectCostsCalculationTemplate = 'projectCostsCalculationTemplate',
    QuizFailedTemplate = 'quizFailedTemplate',
    QuizSkippedTemplate = 'quizSkippedTemplate',
    AnnualTaxReportTemplate = 'annualTaxReportTemplate',
    QuarterTaxReceiptTemplate = 'quarterTaxReceiptTemplate',

    // Platform
    HideProjectsProgress = 'hideProjectsProgress',

    // Merge categories
    MergedUserDocsName = 'mergedUserDocsName',
    MergedEffectaDocsName = 'mergedEffectaDocsName',
    MergedProjectDocsName = 'mergedProjectDocsName',
    SupportEmail = 'supportEmail',

    InvestClientPublicUrl = 'investClientPublicUrl',
    VoucherBankAccount = 'voucherBankAccount',
}

export interface FileSettingValue {
    fileName: string;
    version: string;
}

export interface ISetting<T = any> extends Omit<IModel, 'id' | 'updatedAt'> {
    key: SettingKey;
    value: T;
    category: SettingsCategory;
    format: ValueDataFormat;
    updatedAt?: string | null;
}

/**
 * @deprecated
 */
export type ISettings = Record<string, string>;
