import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { Fragment } from 'react';

const NotFoundEntity: FC<{ name: string; plain?: boolean }> = ({ name, plain }) => {
    const Wrapper = plain ? Fragment : Card;
    return (
        <Wrapper>
            <CardContent sx={{ textAlign: 'center' }}>
                <Typography color="text.primary">
                    {name} {name[name.length - 1] === 's' ? 'are' : 'is'} not found
                </Typography>
            </CardContent>
        </Wrapper>
    );
};

export default NotFoundEntity;
