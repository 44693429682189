import { DataGridOptions } from '@condo/invest-utils';
import type { MaterialReactTableProps } from 'material-react-table';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import type { HistoryServiceDataKey } from '../../../../infrastructure/api/history/types';
import type { ApiDataKey, FetchService } from '../../../../infrastructure/api/types';
import { useGetListData } from '../../../hooks/use-queries';
import { getFilterQueryByTable } from '../helpers/getFilterQueryByTable';

type PaginationOptions = Pick<
    MaterialReactTableProps,
    'rowCount' | 'onPaginationChange' | 'onColumnFiltersChange' | 'onColumnFilterFnsChange' | 'onGlobalFilterChange' | 'onSortingChange' | 'state'
>;

interface Result {
    dataGridPaginationProps: PaginationOptions;
    list: any[];
    error?: Error;
    isLoading: boolean;
}

export const useGetGridData = ({
    dataKey,
    defaultPage = 0,
    defaultPageSize = DataGridOptions.pageSize,
    tableRef,
    extraProps,
    fetchService,
    fetchWithoutPagination = false,
}: {
    dataKey: ApiDataKey | HistoryServiceDataKey;
    defaultPage?: number;
    defaultPageSize?: number;
    rowsPerPageOptions?: number[];
    tableRef: any;
    extraProps?: Record<string, any>;
    fetchService?: FetchService;
    fetchWithoutPagination?: boolean;
}): Result => {
    const [paginationState, setPaginationState] = useState({ pageIndex: defaultPage, pageSize: defaultPageSize });
    const [sortQuery, setSortQuery] = useState<Record<string, string>>({});
    const [sortState, setSortState] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [filterQuery, setFilterQuery] = useState<Record<string, any>>({});
    const [globalFilter, setGlobalFilter] = useState(null);
    const [rowCount, setRowCount] = useState<number>(0);
    const { enqueueSnackbar } = useSnackbar();
    const {
        isLoading,
        isFetching,
        isRefetching,
        data = { list: [], count: 0 },
        error,
        refetch,
    } = useGetListData(
        dataKey,
        {
            page: fetchWithoutPagination ? 0 : paginationState.pageIndex,
            pageSize: fetchWithoutPagination ? Number.POSITIVE_INFINITY : paginationState.pageSize,
            // need to stringify filterQuest, otherwise data types are lost
            filterQuery: Object.keys(filterQuery).length ? JSON.stringify(filterQuery) : undefined,
            sortQuery,
            ...extraProps,
        },
        fetchService,
    );

    useEffect(() => {
        setRowCount(prevRowCount => (isLoading || data.count === undefined ? prevRowCount : data.count));
    }, [data.count, isLoading]);

    useEffect(() => {
        refetch();
    }, [refetch, paginationState.pageSize, paginationState.pageIndex, sortQuery, filterQuery]);

    useEffect(() => {
        if (globalFilter) {
            setFilterQuery({ globalFilter });
            return;
        }

        if (tableRef) {
            const query = getFilterQueryByTable(tableRef);
            setFilterQuery(query);
        }
    }, [columnFilters, globalFilter]);

    useEffect(() => {
        const sort = sortState.reduce((result, el) => ({ ...result, [el.id]: el.desc === false ? 'asc' : 'desc' }), {});
        setSortQuery(sort);
    }, [sortState, setSortQuery]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(`Filtering failed, please try again with a different filter: ${error.message}`, {
                variant: 'error',
            });
            setFilterQuery({});
        }
    }, [error, enqueueSnackbar]);

    const dataGridPaginationProps: PaginationOptions = {
        rowCount,
        onPaginationChange: setPaginationState,
        onSortingChange: setSortState,
        onColumnFiltersChange: setColumnFilters,
        state: { pagination: paginationState, sorting: sortState, columnFilters, globalFilter },
        onGlobalFilterChange: setGlobalFilter,
    };
    return { dataGridPaginationProps, list: data.list, error, isLoading: isLoading || isFetching || isRefetching };
};
