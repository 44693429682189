import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import type { GetPayoutReportResponse } from '../../../../../schemas';
import type { DataGridProps } from '../../../components/DataGrid';
import DataGrid, { useGridColumns } from '../../../components/DataGrid';
import Page from '../../../components/Page';
import StatusChip from '../../../components/StatusChip';
import { getStatusColor } from '../../../helpers/color';

export default function PayoutReportsListPage() {
    const columns = useGridColumns<GetPayoutReportResponse>([
        {
            accessorKey: 'project.code',
            header: 'Project Code',
            minSize: 200,
        },
        {
            accessorKey: 'year',
            header: 'Year',
            enableColumnFilter: false,
            minSize: 100,
        },
        {
            accessorKey: 'quarter',
            header: 'Quarter',
            minSize: 150,
            filterVariant: 'multi-select',
            filterSelectOptions: ['Q1', 'Q2', 'Q3', 'Q4'],
        },
        {
            accessorKey: 'status',
            header: 'Status',
            minSize: 150,
            filterVariant: 'select',
            filterSelectOptions: ['DRAFT', 'PUBLISHED'],
            Cell: ({ cell }) => <StatusChip label={cell.getValue()} size="small" color={getStatusColor(cell.getValue<string>())} />,
        },
        {
            accessorKey: 'publishedAt',
            header: 'Published At',
            dataType: 'date-time',
            accessorFn: row => (row.publishedAt ? new Date(row.publishedAt).toDateString() : '-'),
        },
        {
            accessorKey: 'createdAt',
            header: 'Created At',
            dataType: 'date-time',
        },
    ]);

    const dataGridProps: DataGridProps<GetPayoutReportResponse> = {
        columns,
        dataFetch: { key: 'PAYOUT_REPORTS' },
        actions: {
            edit(row) {
                window.open(`/payout-reports/edit/${row.id}`, '_blank');
            },
        },
    };

    return (
        <Page title="Projects">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Payout Reports
                        </Typography>
                        <Button variant="contained" component={RouterLink} to="/payout-reports/add" startIcon={<AddIcon />}>
                            Add Payout Report
                        </Button>
                    </Stack>

                    <Card>
                        <DataGrid {...dataGridProps} />
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}
