import { Collapse } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import ScrollToTop from './ui/components/ScrollToTop';
import { useDI } from './ui/hooks/use-di';
import Bootstrap from './ui/pages/Bootstrap';
import Router from './ui/routes';
import ThemeConfig from './ui/theme';
import GlobalStyles from './ui/theme/globalStyles';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: { refetchOnWindowFocus: false },
    },
});

export default function App() {
    const { authService } = useDI();
    const [isLoading, setLoading] = useState(true);
    const Component = isLoading ? Bootstrap : Router;

    useEffect(() => {
        authService.tryRecoverSession().then(() => {
            setLoading(false);
        });
    }, []);

    return (
        <ThemeConfig>
            <ScrollToTop />
            <GlobalStyles />

            <QueryClientProvider client={queryClient}>
                <SnackbarProvider
                    maxSnack={5}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    TransitionComponent={Collapse}
                >
                    <Component />
                </SnackbarProvider>
            </QueryClientProvider>
        </ThemeConfig>
    );
}
