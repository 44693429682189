import Button from '@mui/material/Button';
import type { FC } from 'react';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useLocation } from 'react-router-dom';

interface Props {
    fileUrl: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    // @ts-expect-error
    import.meta.url,
).toString();

// expects a url to a pdf file as a ?link= query param
export const FileViewer: FC<Props> = () => {
    const { search } = useLocation();
    const fileUrl = search.split('?link=')[1];
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const goToPreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const goToNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    return (
        <div>
            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
            </Document>
            <p>
                Page {pageNumber} of {numPages}
            </p>
            <Button onClick={goToPreviousPage} disabled={pageNumber <= 1}>
                Previous Page
            </Button>
            <Button onClick={goToNextPage} disabled={pageNumber >= numPages}>
                Next Page
            </Button>
        </div>
    );
};
