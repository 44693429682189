import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { BankTransactionsImportHistoryItem } from '../../../../../domain-entities/models/bank-transaction-log';
import type { DataGridProps } from '../../../components/DataGrid';
import DataGrid, { useGridColumns } from '../../../components/DataGrid';
import PageWrapper from '../../PageWrapper';

interface Props {
    title: string;
}

const TransactionsImportsHistory: FC<Props> = ({ title }) => {
    const columns = useGridColumns<BankTransactionsImportHistoryItem>([
        {
            accessorKey: 'importedAt',
            header: 'Imported At',
            dataType: 'date-time',
            minSize: 180,
        },
        {
            accessorKey: 'importRef',
            header: 'Ref.',
            minSize: 450,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'count',
            dataType: 'number',
            header: 'Imported Transactions',
            minSize: 180,
            enableColumnFilter: false,
        },
    ]);

    const dataGridProps: DataGridProps<BankTransactionsImportHistoryItem> = {
        columns,
        dataFetch: { key: 'BANK_TRANSACTIONS_HISTORY' },
        actions: {
            view(row) {
                window.open(`/bank-transactions/imports-history/${row.importRef}`, '_blank');
            },
        },
    };

    const breadcrumbs = {
        '/dashboard': 'Dashboard',
        '/bank-transactions': 'Bank Transactions',
        '': 'Import History',
    };

    return (
        <PageWrapper
            title={title}
            actionButtons={
                <>
                    <Button variant="contained" component={RouterLink} to="/bank-transactions/import" startIcon={<UploadIcon />} sx={{ mx: 1 }}>
                        Import
                    </Button>
                    <Button variant="text" component={RouterLink} to="/bank-transactions/list">
                        Bank Transactions
                    </Button>
                </>
            }
            breadcrumbs={breadcrumbs}
        >
            <DataGrid {...dataGridProps} />
        </PageWrapper>
    );
};
export default TransactionsImportsHistory;
