import { currency, intlDateFormat } from '@condo/formatters';
import { Box, Card, Link, Typography } from '@mui/material';
import type { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import type { BankTransactionLogResponse } from '../../../../schemas/bank-transaction';
import StatusChip from '../../components/bankTransaction/StatusChip';
import { useGetBankImportLog } from '../../hooks/use-queries';
import PageWrapper from '../PageWrapper';

interface Props {
    title: string;
}

const columns: GridColumns<BankTransactionLogResponse> = [
    {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<BankTransactionLogResponse>) => {
            return <StatusChip status={params.row.status} />;
        },
    },
    {
        field: 'orderCode',
        headerName: 'Order Code',
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<BankTransactionLogResponse>) => {
            if (!params.row.order) {
                return 'n/a';
            }

            return (
                <Link href={`/orders/view/${params.row.orderId}`} target="_blank">
                    {params.row.order.code}
                </Link>
            );
        },
    },
    {
        field: 'amount',
        headerName: 'Amount',
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<BankTransactionLogResponse>) => {
            return currency(params.row.amount);
        },
    },
    {
        field: 'entryDate',
        headerName: 'Entry Date',
        type: 'date',
        valueGetter: (params: GridRenderCellParams<string, BankTransactionLogResponse>) => new Date(params.value),
    },
    {
        field: 'description',
        headerName: 'Description',
        minWidth: 450,
    },
];

const TransactionsImportLog: FC<Props> = ({ title }) => {
    const { importRef } = useParams();
    const { isLoading, data } = useGetBankImportLog(importRef);

    const breadcrumbs = {
        '/dashboard': 'Dashboard',
        '/bank-transactions': 'Bank Transactions',
        '/bank-transactions/imports-history': 'Import History',
        '': importRef,
    };

    return (
        <PageWrapper title={title} breadcrumbs={breadcrumbs}>
            <Card>
                <Box sx={{ px: 2, py: 2 }}>
                    <Typography variant="body2">
                        <b>Reference:</b> {importRef || 'n/a'}
                    </Typography>
                    {data?.length ? (
                        <>
                            <Typography variant="body2">
                                <b>Date:</b> {intlDateFormat(data[0].importedAt, true)}
                            </Typography>
                            <Typography variant="body2">
                                <b>Imported By:</b> {data[0].user.email || data[0].user.uid} ({data[0].user.type})
                            </Typography>
                        </>
                    ) : null}
                </Box>
                <DataGrid rows={data || []} columns={columns} autoHeight loading={isLoading} checkboxSelection={false} />
            </Card>
        </PageWrapper>
    );
};
export default TransactionsImportLog;
