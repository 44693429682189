import DescriptionIcon from '@mui/icons-material/Description';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { FC } from 'react';
import { useState } from 'react';
import type { GetProjectWithDetailsResponse } from '../../../../../schemas';
import type { TDetails } from './ProjectDetailsForm';
import ProjectDetailsForm from './ProjectDetailsForm';
import ProjectGeneralForm from './ProjectGeneralForm';

interface Props {
    data?: GetProjectWithDetailsResponse;
    onSuccess?: (projectId: string, message?: string) => void;
    onError?: (error) => void;
}

const ProjectDataTabs: FC<Props> = ({ data, onSuccess, onError }) => {
    const [tab, setTab] = useState('general');

    return (
        <Paper>
            <TabContext value={tab}>
                <TabList
                    value={tab}
                    onChange={(_event: React.SyntheticEvent, value: string) => {
                        setTab(value);
                    }}
                    textColor="primary"
                    indicatorColor="secondary"
                    sx={{ mb: 2, bgcolor: 'background.neutral' }}
                >
                    <Tab value="general" label="General" icon={<FactCheckIcon />} iconPosition="start" />
                    <Tab value="details" label="Details" icon={<DescriptionIcon />} iconPosition="start" disabled={!data?.project.id} />
                </TabList>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TabPanel value="general">
                        <ProjectGeneralForm onSuccess={onSuccess} onError={onError} project={data?.project} />
                    </TabPanel>
                    <TabPanel value="details">
                        <ProjectDetailsForm project={data?.project} details={data?.details as TDetails} />
                    </TabPanel>
                </LocalizationProvider>
            </TabContext>
        </Paper>
    );
};

export default ProjectDataTabs;
