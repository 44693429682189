import type { IIbanData } from '@condo/iban';
import type { ListResult } from '@condo/invest-utils';
import type { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import type { BrandName } from '../../../domain-entities/models/base';
import type { IJob } from '../../../domain-entities/models/job';
import type { OrderWithItems } from '../../../domain-entities/models/order';
import type { ProjectFundingStats, RegistrationFunnelData } from '../../../domain-entities/models/reports';
import type { ISetting } from '../../../domain-entities/models/settings';
import type {
    CheckDistributionOnboardingStatusResponse,
    GetAdminUserResponse,
    GetDistributionResponse,
    GetDistributionStatsResponse,
    GetEstateWithDetailsResponse,
    GetPayoutReportResponse,
    GetPayoutReportStatsResponse,
    GetProjectWithDetailsResponse,
    GetUserFullDataResponse,
    GetUserStepsResponse,
    GetVouchersListResponse,
} from '../../../schemas';
import type { GetBankTransactionLogResponse } from '../../../schemas/bank-transaction';
import type { HistoryServiceDataKey } from '../../infrastructure/api/history/types';
import type { ApiDataKey, FetchService } from '../../infrastructure/api/types';
import { useDI } from './use-di';
import useOnError from './use-on-error';
import type { PaymentRequestType, SettingsCategory } from '.prisma/client';

export const useGetListData = <T = unknown>(listName: ApiDataKey | HistoryServiceDataKey, params: any, fetchService?: FetchService) => {
    const { apiClient, historyService } = useDI();
    const { page, pageSize } = params;
    return useQuery<ListResult<T>, Error>(
        [listName, page, pageSize],
        () =>
            fetchService === 'HISTORY_SERVICE'
                ? historyService.getList(listName as HistoryServiceDataKey, params)
                : apiClient.getList(listName as ApiDataKey, params),
        {
            keepPreviousData: true,
        },
    );
};

export const useGetJob = (jobId: string) => {
    const { apiClient } = useDI();

    return useQuery<IJob | null, AxiosError>(['job', jobId], () => apiClient.getJob(jobId));
};

export const useGetPayoutReportQuery = (id: string) => {
    const { apiClient } = useDI();
    return useQuery<GetPayoutReportResponse | null, Error>(['payoutReport', id], () => apiClient.getPayoutReport(id));
};

export const useGetAdminUserList = () => {
    const { apiClient } = useDI();
    return useQuery('adminUsers', () => apiClient.getAdminUsers());
};

export const useGetRegistrationsFunnel = () => {
    const { apiClient } = useDI();
    return useQuery<Partial<RegistrationFunnelData>, Error>(['reg-funnel'], () => apiClient.getRegistrationsFunnel());
};

export const useGetProjectFundingStats = () => {
    const { apiClient } = useDI();
    return useQuery<ProjectFundingStats[], Error>(['projects-funding-stats'], () => apiClient.getProjectsFundingStats());
};

export const useGetUserSteps = (userId: string) => {
    const { apiClient } = useDI();
    return useQuery<GetUserStepsResponse, Error>(['userSteps', userId], () => apiClient.getUserSteps(userId));
};

export const useGetSettings = (category?: SettingsCategory) => {
    const { apiClient } = useDI();
    return useQuery<Record<string, ISetting>, Error>(['settings', category || 'all'], () => apiClient.getSettings(category), {
        keepPreviousData: false,
    });
};

export const useGetDocument = ({ key, brand }: { key: string; brand?: BrandName }) => {
    const { apiClient } = useDI();
    return useQuery(['document', key], () => apiClient.getDocument({ key, brand }));
};

export const useGetPayoutReportFile = ({ payoutReportId }: { payoutReportId: string }) => {
    const { apiClient } = useDI();
    return useQuery(['payoutReportFile', payoutReportId], () => (payoutReportId ? apiClient.getPayoutReportFile({ payoutReportId }) : null));
};

export const useGetDistributionListFile = ({ distributionId }: { distributionId: string }) => {
    const { apiClient } = useDI();
    return useQuery(['distributionListFile', distributionId], () => apiClient.getDistributionListFile({ distributionId }), { enabled: false });
};

export const useGetProjectEstatesQuery = (projectId: string) => {
    const { apiClient } = useDI();
    return useQuery<GetEstateWithDetailsResponse, Error>(['estates', projectId], () => apiClient.getProjectEstates(projectId));
};

export const useGetPayoutReportStatsQuery = (payoutReportId: string) => {
    const { apiClient } = useDI();
    return useQuery<GetPayoutReportStatsResponse, Error>(['payoutReportStats', payoutReportId], () => apiClient.getPayoutReportStats(payoutReportId));
};

export const useGetBankTransaction = (id: string) => {
    const { apiClient } = useDI();
    return useQuery(['bankTransaction', id], () => apiClient.getBankTransaction(id));
};

export const useBankDataQuery = (iban: string, enabled = true) => {
    const { apiClient } = useDI();
    return useQuery<IIbanData | undefined, Error>(['bankData', iban], () => apiClient.fetchBankData(iban), { enabled });
};

export const useGetBankImportLog = (importRef: string) => {
    const { apiClient } = useDI();
    return useQuery<GetBankTransactionLogResponse, Error>(['bankImportLog', importRef], () => apiClient.getImportTransactionLog(importRef));
};

export const useFindOrderItem = (keyword?: string) => {
    const { apiClient } = useDI();

    return useQuery(['findOrder', keyword], () => apiClient.findOrderItem(keyword), {
        enabled: Boolean(keyword),
        retry: false,
    });
};

export const useGetPaymentRequestsStats = (type: PaymentRequestType) => {
    const { apiClient } = useDI();
    return useQuery(['paymentRequestsStats', type], () => {
        return apiClient.getPaymentRequestsStats(type);
    });
};

// GET_BY_ID

export const useGetPaymentRequest = (id: string) => {
    const { apiClient } = useDI();
    return useQuery(['paymentRequest', id], () => {
        return apiClient.getPaymentRequest(id);
    });
};

export const useGetProjectQuery = (projectId: string) => {
    const { apiClient } = useDI();
    return useQuery<GetProjectWithDetailsResponse, Error>(['project', projectId], () => apiClient.getProject(projectId));
};

export const useGetOrder = (orderId: string) => {
    const { apiClient } = useDI();
    return useQuery<Required<OrderWithItems>, Error>(['order', orderId], () => apiClient.getOrderById(orderId));
};

export const useGetAdminUser = (userId: string) => {
    const { apiClient } = useDI();
    return useQuery<GetAdminUserResponse, Error>(['adminUser', userId], () => apiClient.getAdminUser(userId));
};

export const useGetUser = (userId: string) => {
    const { apiClient } = useDI();
    return useQuery<GetUserFullDataResponse, Error>(['user', userId], () => apiClient.getUserById(userId, true));
};

export const useGetUserVouchers = (userId: string) => {
    const { apiClient } = useDI();
    return useQuery<GetVouchersListResponse, Error>(['vouchers', userId], () => apiClient.getVouchersList(userId));
};

export const useGetUserOrders = (userId: string) => {
    const { apiClient } = useDI();
    return useQuery<OrderWithItems[] | null, Error>(['userOrders', userId], () => apiClient.getUserOrders(userId));
};

export const useGetProjectDistributionStatsQuery = (params: { projectId?: string; distributionId?: string }) => {
    const errorPopup = useOnError();
    const { apiClient } = useDI();
    return useQuery<GetDistributionStatsResponse, Error>(
        ['projectDistributionStats', params.distributionId, params.projectId],
        () => apiClient.getProjectDistributionStats(params),
        {
            onError: errorPopup,
        },
    );
};

export const useCheckDistributionOnboardingStatusQuery = (distributionId: string, isEnabled = false) => {
    const errorPopup = useOnError();
    const { apiClient } = useDI();
    const queryClient = useQueryClient();

    return useQuery<CheckDistributionOnboardingStatusResponse, Error>(
        ['distributionOnboardingStatus', distributionId],
        () => apiClient.checkDistributionOnboardingStatus(distributionId),
        {
            enabled: isEnabled,
            refetchInterval: 5000,
            onError: errorPopup,
            onSuccess: ({ onboardedUsers, totalUsers }) => {
                if (onboardedUsers === totalUsers) {
                    queryClient.invalidateQueries(['getDistribution', distributionId]);
                    queryClient.invalidateQueries({ queryKey: ['projectDistributionStats'] });
                    queryClient.invalidateQueries({ queryKey: ['DISTRIBUTION_USERS'] });
                }
            },
        },
    );
};

export const useGetDistributionQuery = distributionId => {
    const errorPopup = useOnError();
    const { apiClient } = useDI();
    return useQuery<GetDistributionResponse, Error>(['getDistribution', distributionId], () => apiClient.getDistribution(distributionId), {
        onError: errorPopup,
    });
};
