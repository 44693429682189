import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';

export default function LoginForm() {
    const navigate = useNavigate();
    const auth = useAuth();

    const [showPassword, setShowPassword] = useState(false);
    const [isAuthFailed, setAuthFailed] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        // TODO: add schema validation
        // validate: (values) => {},
        onSubmit: async values => {
            const { email, password } = values;
            const isSuccess = await auth.logInWithEmail(email, password);
            setAuthFailed(!isSuccess);
            if (isSuccess) {
                navigate('/', { replace: true });
            }
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword(show => !show);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    {isAuthFailed ? <Alert severity="error">Authorization failed! Probably email/password is incorrect</Alert> : null}

                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Email address"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email ? errors.email : null}
                    />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password ? errors.password : null}
                    />

                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                        Login
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
