export default function Breadcrumbs(theme) {
    return {
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    marginBottom: theme.spacing(4),
                },
            },
        },
    };
}
