import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';
import type { FunctionComponent } from 'react';
import * as React from 'react';

interface MultiSelectProps {
    optionsList: string[];
    name: string;
    label: string;
}

const MultiSelect: FunctionComponent<MultiSelectProps> = ({ optionsList, name, label }) => {
    const { setFieldValue, values } = useFormikContext();
    const initialState = _get(values, `${name}.value`);
    const [selection, setSelection] = React.useState<string[]>(initialState ? JSON.parse(initialState) : []);

    const handleChange = (event: SelectChangeEvent<typeof selection>) => {
        const {
            target: { value },
        } = event;
        const selectedValue = typeof value === 'string' ? value.split(',') : value;
        setSelection(typeof value === 'string' ? value.split(',') : value);
        setFieldValue(`${name}.value`, JSON.stringify(selectedValue));
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id={`${name}-label`}>{label}</InputLabel>
                <Select
                    labelId={`${name}-label`}
                    id={`${label}-select`}
                    multiple
                    value={selection}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={selected => selected.join(', ')}
                >
                    {optionsList.map(option => (
                        <MenuItem key={option} value={option}>
                            <Checkbox checked={selection.includes(option)} />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default MultiSelect;
