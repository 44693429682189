import { currency } from '@condo/formatters';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import type { IOrderItem } from '../../../../domain-entities/models/order-item';
import DetailsItem from '../DetailsItem';
import Section from '../Section';

interface Props {
    orderItem: Required<IOrderItem>;
}
const OrderItemDetailsBox: FC<Props> = ({ orderItem }) => {
    return (
        <Section title="Order Item Details">
            {orderItem ? (
                <>
                    <DetailsItem title="Code" value={orderItem.code} />
                    <DetailsItem title="Status" value={orderItem.status} />
                    <DetailsItem title="Type" value={orderItem.type} />
                    <DetailsItem title="Amount" value={currency(orderItem.amount)} />
                    <DetailsItem title="Received amount" value={currency(orderItem.receivedAmount)} />
                    <DetailsItem title="Received at" value={orderItem.receivedAt ? new Date(orderItem.receivedAt).toLocaleString() : 'Not paid yet'} />
                    <Link className="data-verify__link-edit" to={`/orders/view/${orderItem.orderId}`}>
                        <DetailsItem title="Order Ref." value={orderItem.orderId} />
                    </Link>
                </>
            ) : null}
        </Section>
    );
};

export default OrderItemDetailsBox;
