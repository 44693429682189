import { DEFAULT_CURRENCY } from '../../../../constants';
import type { Brand, EstateMediaCategory, ProjectDocumentCategory } from './types';

export const brands: Brand[] = ['IS24', 'INVESTABLE', 'TECIS'];
export const defaultValues = {
    project: {
        code: '',
        tokensAmount: '' as unknown as number,
        tokenPrice: 1,
        tokenAddress: '',
        isPrivate: false,
        currency: DEFAULT_CURRENCY,
        minInvestment: '' as unknown as number,
        projectStartsAt: '',
        maturityDate: '',
        saleStartsAt: '',
        saleEndsAt: '',
        dividendBps: '' as unknown as number,
        bankAccountName: '',
        bankName: '',
        iban: '',
        effectaProductId: '',
        payoutsAccountDetails: {
            bic: '',
            iban: '',
            accountName: '',
            bankName: '',
        },
    },

    details: {
        mainTitle: { name: 'mainTitle', value: '', format: 'TEXT' },
        ...brands.reduce(
            (list, brand) => ({
                ...list,
                [`introVideoLink_${brand}`]: { dimension: brand, name: 'introVideoLink', value: '', format: 'TEXT' },
            }),
            {} as Record<`introVideoLink_${Brand}`, { name: string; value: string; format: 'TEXT'; dimension: Brand }>,
        ),
        location: { name: 'location', value: '', format: 'TEXT' },
        estatesTotalArea: { name: 'estatesTotalArea', value: '', format: 'NUMBER' },
        rentedPercentage: { name: 'rentedPercentage', value: '', format: 'NUMBER' },
        expectedReturnBps: { name: 'expectedReturnBps', value: '', format: 'NUMBER' },
        firstPayout: { name: 'firstPayout', value: '', format: 'DATE' },
        bankFinancingAmount: { name: 'bankFinancingAmount', value: '', format: 'NUMBER' },

        factsInvestment: { name: 'factsInvestment', value: '', format: 'RICHTEXT' },
        factsProperty: { name: 'factsProperty', value: '', format: 'RICHTEXT' },
        factsRenting: { name: 'factsRenting', value: '', format: 'RICHTEXT' },
        factsLocation: { name: 'factsLocation', value: '', format: 'RICHTEXT' },
        locationDescription: { name: 'locationDescription', value: '', format: 'RICHTEXT' },
        factsRenovation: { name: 'factsRenovation', value: '', format: 'RICHTEXT' },
        chancesList: { name: 'chancesList', value: '', format: 'RICHTEXT' },
        risksList: { name: 'risksList', value: '', format: 'RICHTEXT' },
        legalNotes: { name: 'legalNotes', value: '', format: 'RICHTEXT' },

        financialDataTransferTax: { name: 'financialDataTransferTax', value: '', format: 'NUMBER' },
        financialDataNotaryFees: { name: 'financialDataNotaryFees', value: '', format: 'NUMBER' },
        financialDataBrokerFees: { name: 'financialDataBrokerFees', value: '', format: 'NUMBER' },
        financialDataStructureCosts: { name: 'financialDataStructureCosts', value: '', format: 'NUMBER' },
        financialDataPrefinancingCosts: { name: 'financialDataPrefinancingCosts', value: '', format: 'NUMBER' },
        financialDataLiquidityReserve: { name: 'financialDataLiquidityReserve', value: '', format: 'NUMBER' },

        forecastNetRevenue_1year: { dimension: '1year', name: 'forecastNetRevenue', value: '', format: 'NUMBER' },
        forecastOtherCosts_1year: { dimension: '1year', name: 'forecastOtherCosts', value: '', format: 'NUMBER' },
        forecastSecurityTrustee_1year: {
            dimension: '1year',
            name: 'forecastSecurityTrustee',
            value: '',
            format: 'NUMBER',
        },
        forecastICServiceFees_1year: {
            dimension: '1year',
            name: 'forecastICServiceFees',
            value: '',
            format: 'NUMBER',
        },
        forecastINVPmFees_1year: {
            dimension: '1year',
            name: 'forecastINVPmFees',
            value: '',
            format: 'NUMBER',
        },
        forecastCAMPmFees_1year: { dimension: '1year', name: 'forecastCAMPmFees', value: '', format: 'NUMBER' },
        forecastRentalLossRisk_1year: {
            dimension: '1year',
            name: 'forecastRentalLossRisk',
            value: '',
            format: 'NUMBER',
        },
        forecastMaintenanceCosts_1year: {
            dimension: '1year',
            name: 'forecastMaintenanceCosts',
            value: '',
            format: 'NUMBER',
        },
        forecastLoanInterest_1year: { dimension: '1year', name: 'forecastLoanInterest', value: '', format: 'NUMBER' },
        forecastLoanPayments_1year: { dimension: '1year', name: 'forecastLoanPayments', value: '', format: 'NUMBER' },
        forecastValueAppreciationBps_1year: {
            dimension: '1year',
            name: 'forecastValueAppreciationBps',
            value: '',
            format: 'NUMBER',
        },

        forecastNetRevenue_10years: { dimension: '10years', name: 'forecastNetRevenue', value: '', format: 'NUMBER' },
        forecastOtherCosts_10years: { dimension: '10years', name: 'forecastOtherCosts', value: '', format: 'NUMBER' },
        forecastSecurityTrustee_10years: {
            dimension: '10years',
            name: 'forecastSecurityTrustee',
            value: '',
            format: 'NUMBER',
        },
        forecastICServiceFees_10years: {
            dimension: '10years',
            name: 'forecastICServiceFees',
            value: '',
            format: 'NUMBER',
        },
        forecastINVPmFees_10years: {
            dimension: '10years',
            name: 'forecastINVPmFees',
            value: '',
            format: 'NUMBER',
        },
        forecastCAMPmFees_10years: { dimension: '10years', name: 'forecastCAMPmFees', value: '', format: 'NUMBER' },
        forecastRentalLossRisk_10years: {
            dimension: '10years',
            name: 'forecastRentalLossRisk',
            value: '',
            format: 'NUMBER',
        },
        forecastMaintenanceCosts_10years: {
            dimension: '10years',
            name: 'forecastMaintenanceCosts',
            value: '',
            format: 'NUMBER',
        },
        forecastLoanInterest_10years: {
            dimension: '10years',
            name: 'forecastLoanInterest',
            value: '',
            format: 'NUMBER',
        },
        forecastLoanPayments_10years: {
            dimension: '10years',
            name: 'forecastLoanPayments',
            value: '',
            format: 'NUMBER',
        },
        forecastValueAppreciationBps_10years: {
            dimension: '10years',
            name: 'forecastValueAppreciationBps',
            value: '',
            format: 'NUMBER',
        },

        costsInvestableCommissionBps: { name: 'costsInvestableCommissionBps', value: '', format: 'NUMBER' },
        costsDuration: { name: 'costsDuration', value: '', format: 'NUMBER' },
        costsDistributionBps_onetime: {
            dimension: 'onetime',
            name: 'costsDistributionBps',
            value: '',
            format: 'NUMBER',
        },
        costsCommissionsBps_onetime: { dimension: 'onetime', name: 'costsCommissionsBps', value: '', format: 'NUMBER' },
        costsFinancialInstrumentsBps_onetime: {
            dimension: 'onetime',
            name: 'costsFinancialInstrumentsBps',
            value: '',
            format: 'NUMBER',
        },
        costsTotalBps_onetime: { dimension: 'onetime', name: 'costsTotalBps', value: '', format: 'NUMBER' },
        costsDistributionBps_running: {
            dimension: 'running',
            name: 'costsDistributionBps',
            value: '',
            format: 'NUMBER',
        },
        costsCommissionsBps_running: { dimension: 'running', name: 'costsCommissionsBps', value: '', format: 'NUMBER' },
        costsFinancialInstrumentsBps_running: {
            dimension: 'running',
            name: 'costsFinancialInstrumentsBps',
            value: '',
            format: 'NUMBER',
        },
        costsTotalBps_running: { dimension: 'running', name: 'costsTotalBps', value: '', format: 'NUMBER' },
        lockedSections: { name: 'lockedSections', value: '', format: 'JSON' },
    },
    documents: [],
    estateDetails: {
        description: { name: 'description', value: '', format: 'RICHTEXT' },
        livingSpace: { name: 'livingSpace', value: '', format: 'NUMBER' },
        numberOfRooms: { name: 'numberOfRooms', value: '', format: 'NUMBER' },
        constructionYear: { name: 'constructionYear', value: '', format: 'NUMBER' },
        renovationYear: { name: 'renovationYear', value: '', format: 'NUMBER' },
        propertyValue: { name: 'propertyValue', value: '', format: 'NUMBER' },
        rentPrice: { name: 'rentPrice', value: '', format: 'NUMBER' },
        latitude: { name: 'latitude', value: '', format: 'NUMBER' },
        longitude: { name: 'longitude', value: '', format: 'NUMBER' },
    },
    estateMediaFiles: {},
};

export const assetFileCategories: EstateMediaCategory[] = ['INTERIOR', 'EXTERIOR'];

export const projectDocumentCategories: ProjectDocumentCategory[] = [
    'BOND_TERMS_CONDITIONS',
    'CONSUMER_INFORMATION',
    'KEY_INFORMATION_SHEET',
    'MARKET_VALUE_APPRAISAL',
    'RISK_INFORMATION',
    'SHORT_EXPOSE',
    'TERMS_CONDITIONS',
    'ANNOUNCEMENTS',
    'BUYBACK_OFFER',
];

export const projectContentSections = ['ESTATES', 'LOCATIONS', 'OVERVIEW', 'CHANCES', 'FINANCIALS', 'DOCUMENTS'];
