import { FormControl, FormHelperText } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TextAlign from '@tiptap/extension-text-align';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { ErrorMessage, useFormikContext } from 'formik';
import _get from 'lodash/get';
import type { FC } from 'react';
import { useEffect } from 'react';
import MenuBar from './MenuBar';

TextAlign.configure({
    types: ['heading', 'paragraph'],
});

const StyledEditorContent = styled(EditorContent)(() => ({
    '.ProseMirror': {
        outline: 0,
    },
    '& ol, & ul': {
        padding: '0 1rem',
    },
}));

interface Props {
    name: string;
    label?: string;
    onChange?: (content: Record<string, any>) => void;
    disabled?: boolean;
}

const ContentEditor: FC<Props> = ({ onChange, name, label, disabled }) => {
    const { setFieldValue, values, getFieldMeta } = useFormikContext();
    const { error } = getFieldMeta(name);
    const editor = useEditor({
        extensions: [StarterKit, TextAlign],
        editable: !disabled,
        content: JSON.parse(_get(values, name) || null),
        onUpdate: ({ editor }) => {
            if (!editor.isEmpty) {
                setFieldValue(name, JSON.stringify(editor.getJSON()));
            } else {
                setFieldValue(name, null);
            }

            if (onChange) {
                onChange(editor.getJSON());
            }
        },
    });

    // To fix an issue related to not showing the existed text at first load of the page.
    useEffect(() => {
        // this condition to avoid running the useEffect onChange.
        if (editor && !editor?.isFocused) {
            editor.commands.setContent(JSON.parse(_get(values, name) || null));
        }
    }, [values]);

    return (
        <FormControl fullWidth error={Boolean(error)} sx={{ color: theme => disabled && theme.palette.grey[600] }}>
            {label ? <FormHelperText id="my-helper-text">{label}</FormHelperText> : null}

            {!disabled && <MenuBar editor={editor} />}
            <Box
                minHeight={100}
                p={2}
                sx={{
                    border: theme => `1px solid ${theme.palette.divider}`,
                    borderTop: 0,
                    borderBottomRightRadius: theme => theme.shape.borderRadius,
                    borderBottomLeftRadius: theme => theme.shape.borderRadius,
                }}
            >
                <StyledEditorContent editor={editor} />
            </Box>
            <ErrorMessage name={name} component={FormHelperText} />
        </FormControl>
    );
};

export default ContentEditor;
