import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from '@mui/material';
import type { FC } from 'react';
import { pxToRem } from '../theme/typography';

const ExtLink: FC<{ url: string; title?: string }> = ({ url, title }) => (
    <>
        <Link href={url} target="_blank" variant="body2">
            {title || url}
        </Link>
        <sup>
            <OpenInNewIcon sx={{ fontSize: pxToRem(14) }} />
        </sup>
    </>
);

export default ExtLink;
