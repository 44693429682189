import { intlDateFormat } from '@condo/formatters';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import _omit from 'lodash/omit';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import type { IJob } from '../../../../../domain-entities/models/job';
import ActionButton from '../../../components/ActionButton';
import ActionsPanel from '../../../components/ActionsPanel';
import DetailsItem from '../../../components/DetailsItem';
import JsonDetails from '../../../components/JsonDetails';
import Section from '../../../components/Section';
import { useRescheduleJobMutation } from '../../../hooks/use-mutations';

interface Props {
    job: IJob;
}
const JobViewData: FC<Props> = ({ job }) => {
    const { enqueueSnackbar } = useSnackbar();
    const rescheduleJobMutation = useRescheduleJobMutation(
        () => {
            enqueueSnackbar('Job has been rescheduled', { variant: 'success' });
        },
        error => {
            enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
        },
    );

    return (
        <Box
            sx={{
                bgcolor: 'background.default',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}
        >
            <Grid container spacing={2}>
                <Section title="General">
                    <DetailsItem title="Created At" value={intlDateFormat(job.createdAt, true)} />
                    <DetailsItem title="Executed At" value={intlDateFormat(job.executedAt, true)} />
                    <DetailsItem title="Scheduled At" value={intlDateFormat(job.scheduledAt, true)} />
                    <DetailsItem title="Status" value={job.status} />
                    <DetailsItem title="Name" value={job.name} />
                    <DetailsItem title="Ref" value={job.ref} />
                    <DetailsItem title="Retry Count" value={job.retryCount} />
                    <JsonDetails title="Payload" data={_omit(job.payload, 'context')} />
                    <JsonDetails title="Context" data={job.context as Record<string, string>} />
                </Section>
            </Grid>

            {(job.status === 'FAILED' || job.status === 'RUNNING') && (
                <ActionsPanel>
                    <ActionButton
                        label="Reschedule Job"
                        dialogTitle="Reschedule Job"
                        dialogContent={`Are you sure you want to reschedule the job ${job.name} for the user ${job.context.userId}`}
                        disabled={rescheduleJobMutation.isLoading || rescheduleJobMutation.isError}
                        onConfirm={() => rescheduleJobMutation.mutate(job.id)}
                    />
                </ActionsPanel>
            )}
        </Box>
    );
};

export default JobViewData;
