import { LoadingButton } from '@mui/lab';
import { FormControlLabel, Switch } from '@mui/material';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import type { GetUserFullDataResponse } from '../../../../../schemas';
import { useResendEffectaUser, useUpdateUserRestrictionsMutation } from '../../../hooks/use-mutations';
import ActionButton from '../../ActionButton';
import ActionsPanel from '../../ActionsPanel';
import Section from '../../Section';

interface Props {
    user: GetUserFullDataResponse;
}

const UserRestrictions: FC<Props> = ({ user }) => {
    const { id: userId, restrictions } = user;
    const { enqueueSnackbar } = useSnackbar();
    const updateRestrictionsMutation = useUpdateUserRestrictionsMutation(
        () => {
            enqueueSnackbar('Restrictions have been updated', { variant: 'success' });
        },
        error => {
            enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
        },
    );
    const effectaUserMutation = useResendEffectaUser(
        () => {
            enqueueSnackbar('User data was scheduled for sending to Effecta', { variant: 'success' });
        },
        error => {
            enqueueSnackbar(`Unable to resend user: ${error.message}`, { variant: 'error' });
        },
    );

    const onResendUser = () => {
        effectaUserMutation.mutate(userId);
    };

    const formik = useFormik({
        initialValues: {
            blockInvestments: restrictions.blockInvestments || false,
            blockLogin: restrictions.blockLogin || false,
        },
        onSubmit: async values => {
            const { blockInvestments, blockLogin } = values;
            updateRestrictionsMutation.mutate({ userId, restrictions: { blockLogin, blockInvestments } });
        },
    });

    const { isSubmitting, handleSubmit, getFieldProps } = formik;
    const isEffectaApproved = user.attributes?.effectaState === 'APPROVED';

    return (
        <Section title="User Restrictions">
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <FormControlLabel
                        control={
                            <Field component={Switch} {...getFieldProps('blockLogin')} id="blockLogin" checked={formik.values.blockLogin} type="checkbox" />
                        }
                        label="Block login"
                    />
                    <FormControlLabel
                        control={
                            <Field
                                component={Switch}
                                {...getFieldProps('blockInvestments')}
                                checked={formik.values.blockInvestments}
                                id="blockInvestments"
                                type="checkbox"
                            />
                        }
                        label="Block investments"
                    />
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                        Save Restrictions
                    </LoadingButton>
                </Form>
            </FormikProvider>

            {/* TODO: move outside of this component */}
            {!isEffectaApproved && (
                <ActionsPanel>
                    <ActionButton label="Resend to Effecta" dialogContent="Do you want to re-send data to Effecta?" onConfirm={onResendUser} />
                </ActionsPanel>
            )}
        </Section>
    );
};

export default UserRestrictions;
