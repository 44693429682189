import type { Endpoints } from './types';

const endpoints: Endpoints = {
    LIST: {
        USERS: {
            url: '/api/user/list',
            method: 'GET',
        },
        ORDERS: {
            url: '/api/order/list',
            method: 'GET',
        },
        PROJECTS: {
            url: '/api/project/list',
            method: 'GET',
        },
        ORDER_ITEMS: {
            url: '/api/order-item/list',
            method: 'GET',
        },
        PAYMENT_REQUESTS: {
            url: '/api/payment-request/list',
            method: 'GET',
        },
        BANK_TRANSACTIONS: {
            url: '/api/bank-transaction/list',
            method: 'GET',
        },
        BANK_TRANSACTIONS_HISTORY: {
            url: '/api/bank-transaction/imports-history',
            method: 'GET',
        },
        USER_FILES: {
            url: '/api/user/:userId/files',
            method: 'GET',
        },
        USER_TRANSACTIONS: {
            url: '/api/transaction/:userId/list',
            method: 'GET',
        },
        PAYOUT_REPORTS: {
            url: '/api/payout-reports/list',
            method: 'GET',
        },
        USER_PAYOUTS: {
            url: '/api/payout-reports/:payoutReportId/user-payouts/list',
            method: 'GET',
        },
        JOBS: {
            url: '/api/job/list',
            method: 'GET',
        },
        DISTRIBUTION: {
            url: '/api/distribution/list',
            method: 'GET',
        },
        DISTRIBUTION_USERS: {
            url: '/api/distribution/:distributionId/users',
            method: 'GET',
        },
    },
};

export default endpoints;
