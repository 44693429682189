import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import type { OrderStatus } from '../../../../../domain-entities/models/order';
import ActionButton from '../../../components/ActionButton';
import ActionsPanel from '../../../components/ActionsPanel';
import Loader from '../../../components/Loader';
import OrderDetailsBox from '../../../components/order/OrderDetailsBox';
import PaymentRequestDetails from '../../../components/paymentRequest/PaymentRequestDetails';
import { useCompletePaymentRequest, useRejectPaymentRequest } from '../../../hooks/use-mutations';
import { useGetPaymentRequest } from '../../../hooks/use-queries';
import PageWrapper from '../../PageWrapper';

interface Props {
    title: string;
    status?: OrderStatus | OrderStatus[];
}

const PaymentRequestPage: FC<Props> = ({ title }) => {
    const { paymentRequestId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { isLoading, data } = useGetPaymentRequest(paymentRequestId);
    const completePaymentMutation = useCompletePaymentRequest(
        () => {
            enqueueSnackbar('Payment Request has been paid', { variant: 'success' });
        },
        error => onError(error),
    );

    const rejectPaymentRequestMutation = useRejectPaymentRequest(
        () => {
            enqueueSnackbar('Payment Request has been rejected', { variant: 'success' });
        },
        error => onError(error),
    );

    function onError(error: Error & { code?: string }) {
        enqueueSnackbar(`${error.code ? `${error.code}:` : ''} ${error.message}`, { variant: 'error' });
    }

    const breadcrumbs = {
        '/dashboard': 'Dashboard',
        '/payment-requests': 'Payment Requests',
        '': paymentRequestId,
    };

    if (isLoading) {
        return (
            <PageWrapper title={title} breadcrumbs={breadcrumbs}>
                <Loader />
            </PageWrapper>
        );
    }

    return (
        <PageWrapper title={title} breadcrumbs={breadcrumbs} status={data?.status}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <PaymentRequestDetails data={data} />
                    <br />
                    <ActionsPanel>
                        <ActionButton
                            label="Mark as Paid"
                            dialogContent={`Are you sure you made the transaction of ${data.amount} Euro to ${data?.recipient.iban}?`}
                            disabled={data?.status !== 'OPEN'}
                            onConfirm={() => completePaymentMutation.mutate(data.id)}
                        />
                        <ActionButton
                            label="Reject Request"
                            dialogContent={`Are you sure you want to reject the payment request of ${data.amount} Euro to ${data?.recipient.iban}?`}
                            disabled={data?.status !== 'OPEN'}
                            onConfirm={() => rejectPaymentRequestMutation.mutate(data.id)}
                        />
                    </ActionsPanel>
                </Grid>
                <Grid item xs={12} md={6}>
                    {data.order ? <OrderDetailsBox order={data.order} /> : null}
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
export default PaymentRequestPage;
