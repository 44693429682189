import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Loader from '../components/Loader';
import Page from '../components/Page';

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
}));

export default function Boostrap() {
    return (
        <RootStyle title="Loading...">
            <Container>
                <Loader />
            </Container>
        </RootStyle>
    );
}
