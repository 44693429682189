import { Box } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
    title?: string;
}

const Page: FC<PropsWithChildren<Props>> = ({ children, title = '', ...other }) => (
    <Box {...other}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {children}
    </Box>
);

export default Page;
