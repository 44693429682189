import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import Page from '../components/Page';

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
}));

export default function Page404() {
    return (
        <RootStyle title="Page Not Found">
            <Container>
                <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                    <Typography variant="h3" paragraph>
                        Sorry, page not found!
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', mx: 'auto' }}>
                        Sorry, we couldn't find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.
                    </Typography>

                    <Button to="/" size="large" variant="contained" component={RouterLink}>
                        Go to Home
                    </Button>
                </Box>
            </Container>
        </RootStyle>
    );
}
