import type { PaperProps } from '@mui/material/Paper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import type { FC, PropsWithChildren } from 'react';

const Wrapper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const Section: FC<PropsWithChildren<{ title?: string } & PaperProps>> = ({ children, title, ...paperProps }) => (
    <Wrapper {...paperProps}>
        {title ? (
            <Typography gutterBottom variant="h5">
                {title}
            </Typography>
        ) : null}
        {children}
    </Wrapper>
);

export default Section;
