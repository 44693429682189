import { LoadingButton } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Form, FormikProvider, useFormik } from 'formik';
// @ts-expect-error
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { GetPayoutReportResponse } from '../../../../../schemas';
import DetailsItem from '../../../components/DetailsItem';
import Loader from '../../../components/Loader';
import SummaryBox from '../../../components/SummaryBox';
import { downloadFile } from '../../../helpers/dom';
import { payoutReportSchema } from '../../../helpers/formSchemas';
import { validateForm } from '../../../helpers/formValidation';
import { useUploadPayoutReportMutation } from '../../../hooks/use-mutations';
import { useGetPayoutReportFile } from '../../../hooks/use-queries';
import { defaultValues } from '../constants';
import Stats from '../edit/Stats';
import UserPayoutsListPage from '../edit/UserPayoutsList';
import PayoutReportFormElements from './PayoutReportFormElements';

interface Props {
    projectId?: string;
    payoutReport?: GetPayoutReportResponse;
    mutation: any;
    publishMutation?: any;
    preparePayoutMutation?: any;
    draftPayoutMutation?: any;
}
type FormValues = typeof defaultValues.payoutReport & { projectId: string; publishedAt?: string };

const PayoutReportForm: FC<Props> = ({ projectId, payoutReport, mutation, publishMutation, preparePayoutMutation, draftPayoutMutation }) => {
    const [showFullForm, setShowFullForm] = useState(false);
    const [tab, setTab] = useState('general');

    const formik = useFormik<FormValues>({
        async onSubmit(values, { setSubmitting }) {
            const dataToSet = payoutReport?.id ? { id: payoutReport?.id, data: values } : values;
            mutation.mutate(_.omit(dataToSet, 'reportFile'));
            setSubmitting(false);
            // @ts-expect-error
            if (payoutReport && values.reportFile instanceof File) {
                await upload({
                    id: payoutReport?.id,
                    file: values.reportFile as unknown as File,
                });
            }
        },
        initialValues: { ...defaultValues.payoutReport, projectId, ...payoutReport },
        enableReinitialize: true,
        validateOnChange: false,
        validate: values => validateForm<typeof payoutReportSchema>(values, payoutReportSchema),
    });

    const { isSubmitting, submitForm, setSubmitting, handleSubmit, values } = formik;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const documentQuery = useGetPayoutReportFile({ payoutReportId: payoutReport?.id });
    const uploadMutation = useUploadPayoutReportMutation();
    const upload = async ({ id, file }: { id: string; file: File }) => {
        const formData = new FormData();
        formData.append(file.name, file);

        try {
            await uploadMutation.mutateAsync({
                formData,
                payoutReportId: id,
            });
            closeSnackbar();
            enqueueSnackbar('Upload is successful', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Failed to upload the file', { variant: 'error' });
        }
    };
    function onTabChange(_, newTab: string) {
        setTab(newTab);
    }

    useEffect(() => {
        if (values.projectId) {
            setShowFullForm(true);
        }
    }, [values.projectId]);

    const isPublishEnabled = publishMutation && payoutReport?.status === 'PREPARED';
    const isDraftEnabled = draftPayoutMutation && payoutReport?.status === 'PREPARED';
    const isPrepareEnabled = preparePayoutMutation && ['DRAFT', 'PREPARED'].includes(payoutReport?.status);

    return (
        <FormikProvider value={formik}>
            {isSubmitting || mutation.isLoading ? <Loader shouldOverride /> : null}
            <Form onSubmit={handleSubmit} contentEditable={false}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {showFullForm || payoutReport ? (
                        <>
                            <SummaryBox>
                                <Stack direction="column">
                                    {payoutReport?.project?.code ? <DetailsItem title="Project Code" value={payoutReport?.project?.code} /> : null}
                                    <DetailsItem title="Quarter" value={`${values.year}-${values.quarter}`} />
                                    <DetailsItem
                                        title="Expected Payout"
                                        value={values.payoutDate ? new Date(values.payoutDate) : null}
                                        formatProps={{ onlyDate: true }}
                                    />
                                    {payoutReport?.status === 'PUBLISHED' && <DetailsItem title="Published" value={new Date(values.publishedAt)} />}
                                </Stack>
                            </SummaryBox>
                            {payoutReport?.id ? <Stats payoutReportId={payoutReport.id} /> : null}
                            <TabContext value={tab}>
                                <TabList onChange={onTabChange} sx={{ mt: 2 }}>
                                    <Tab label="General" value="general" />
                                    <Tab label="User Payouts List" value="userPayout" disabled={!payoutReport || payoutReport.status === 'DRAFT'} />
                                </TabList>
                                <TabPanel value="general" sx={{ px: 0 }}>
                                    <PayoutReportFormElements projectId={values.projectId} />
                                </TabPanel>
                                <TabPanel value="userPayout" sx={{ px: 0 }}>
                                    <UserPayoutsListPage
                                        payoutReportId={payoutReport?.id}
                                        payoutReportQuarter={payoutReport?.quarter}
                                        payoutReportYear={payoutReport?.year}
                                    />
                                </TabPanel>
                            </TabContext>

                            <Stack sx={{ p: 2 }} direction="row" gap={2} justifyContent="end">
                                {payoutReport ? (
                                    <Button
                                        color="secondary"
                                        disabled={!documentQuery.isLoading && !documentQuery.data}
                                        onClick={() => {
                                            downloadFile(
                                                new Blob([documentQuery.data as any], { type: 'application/pdf' }),
                                                `report_${values.year}-${values.quarter}.pdf`,
                                            );
                                        }}
                                    >
                                        Preview Report PDF
                                    </Button>
                                ) : null}
                                {!['PREPARED', 'PUBLISHED'].includes(payoutReport?.status) && (
                                    <LoadingButton variant="contained" color="secondary" loading={isSubmitting || mutation.isLoading} onClick={submitForm}>
                                        Save Draft
                                    </LoadingButton>
                                )}

                                {isDraftEnabled ? (
                                    <LoadingButton
                                        variant="contained"
                                        color="secondary"
                                        loading={isSubmitting || draftPayoutMutation?.isLoading}
                                        onClick={() => {
                                            draftPayoutMutation.mutate(payoutReport?.id);
                                            setSubmitting(false);
                                        }}
                                    >
                                        Mark as Draft
                                    </LoadingButton>
                                ) : null}

                                {isPrepareEnabled ? (
                                    <LoadingButton
                                        variant="contained"
                                        color="secondary"
                                        loading={isSubmitting || preparePayoutMutation.isLoading}
                                        onClick={() => {
                                            preparePayoutMutation.mutate(payoutReport?.id);
                                            setSubmitting(false);
                                        }}
                                    >
                                        {payoutReport.status === 'DRAFT' ? 'Prepare' : 'Re-Prepare'}
                                    </LoadingButton>
                                ) : null}

                                {isPublishEnabled ? (
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        loading={isSubmitting || publishMutation?.isLoading}
                                        onClick={() => {
                                            publishMutation.mutate(payoutReport?.id);
                                            setSubmitting(false);
                                        }}
                                    >
                                        Publish
                                    </LoadingButton>
                                ) : null}
                            </Stack>
                        </>
                    ) : null}
                </LocalizationProvider>
            </Form>
        </FormikProvider>
    );
};
export default PayoutReportForm;
