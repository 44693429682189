import { DataGridOptions } from '@condo/invest-utils';
import type { DataGridProps, GridFilterModel, GridFilterOperator, GridSortModel } from '@mui/x-data-grid';
import { getGridDateOperators, getGridSingleSelectOperators, getGridStringOperators } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { ApiDataKey } from '../../infrastructure/api/types';
import { DateRangeInput } from './DateRangeInputOperator';
import { useGetListData } from './use-queries';

type PaginationOptions = Pick<
    DataGridProps,
    | 'rowCount'
    | 'rowsPerPageOptions'
    | 'pagination'
    | 'pageSize'
    | 'page'
    | 'paginationMode'
    | 'onPageChange'
    | 'onPageSizeChange'
    | 'onFilterModelChange'
    | 'onSelectionModelChange'
    | 'sortingMode'
    | 'onSortModelChange'
    | 'filterMode'
>;

interface Result {
    dataGridPaginationProps: PaginationOptions;
    list: any[];
    error?: Error;
    isLoading: boolean;
}

const betweenOperator: GridFilterOperator = {
    label: 'between',
    value: 'between',
    getApplyFilterFn: () => null,
    InputComponent: DateRangeInput,
    InputComponentProps: { type: 'date' },
};

const supportedPrismaOperators = ['equals', 'in', 'lt', 'lte', 'gt', 'gte', 'contains', 'between', 'startsWith', 'endsWith', 'mode', 'is', 'not'];
const dataGridSupportedOperatorFilter = operator => supportedPrismaOperators.includes(operator.value);
export const useGetSupportedColumnFilters = data => {
    const columns = useMemo(() => {
        return data.map(col => {
            return {
                ...col,
                filterOperators:
                    col.type === 'singleSelect'
                        ? getGridSingleSelectOperators().filter(dataGridSupportedOperatorFilter)
                        : col.type === 'dateTime' || col.type === 'date'
                          ? [
                                ...getGridDateOperators().filter(operator => {
                                    if (operator?.value === 'not') {
                                        return false;
                                    }
                                    return dataGridSupportedOperatorFilter(operator);
                                }),
                                betweenOperator,
                            ]
                          : getGridStringOperators().filter(dataGridSupportedOperatorFilter),
            };
        });
    }, [data]);
    return columns;
};

export const useGetData = ({
    dataKey,
    defaultPage = 0,
    defaultPageSize = DataGridOptions.pageSize,
    rowsPerPageOptions = DataGridOptions.rowsPerPageOptions,
    extraProps,
}: {
    dataKey?: ApiDataKey;
    defaultPage?: number;
    defaultPageSize?: number;
    rowsPerPageOptions?: number[];
    extraProps?: Record<string, any>;
}): Result => {
    const [page, setPage] = useState<number>(defaultPage);
    const [pageSize, setPageSize] = useState<number>(defaultPageSize);
    const [rowCount, setRowCount] = useState<number>(0);
    const [filter, setFilter] = useState({});
    const [sortQuery, setSortQuery] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const { isLoading, data = { list: [], count: 0 }, error, refetch } = useGetListData(dataKey, { page, pageSize, ...filter, sortQuery, ...extraProps });
    useEffect(() => {
        setRowCount(prevRowCount => (isLoading || data.count === undefined ? prevRowCount : data.count));
    }, [data.count, setRowCount, isLoading]);

    const onFilterModelChange = useCallback((filterModel: GridFilterModel) => {
        setFilter({ filterQuery: filterModel });
    }, []);

    const onSortModelChange = useCallback((sortModel: GridSortModel) => {
        const sort = sortModel.map(el => {
            return { [el.field]: el.sort };
        });
        setSortQuery(sort);
    }, []);

    useEffect(() => {
        refetch();
    }, [refetch, pageSize, filter, sortQuery]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(`Filtering failed, please try again with a different filter: ${error.message}`, {
                variant: 'error',
            });
            setFilter({});
        }
    }, [error, enqueueSnackbar]);

    const dataGridPaginationProps: PaginationOptions = {
        rowCount,
        rowsPerPageOptions,
        pagination: true,
        page,
        pageSize,
        paginationMode: 'server',
        sortingMode: 'server',
        onPageChange: (newPage: number) => setPage(newPage),
        onPageSizeChange: (newPageSize: number) => setPageSize(newPageSize),
        filterMode: 'server',
        onFilterModelChange,
        onSortModelChange,
    };
    return { dataGridPaginationProps, list: data.list, error, isLoading };
};
