import { currency } from '@condo/formatters';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import type { IBankTransaction } from '../../../../domain-entities/models/bank-transaction';
import type { ITransaction } from '../../../../domain-entities/models/transaction';
import DetailsItem from '../DetailsItem';
import Section from '../Section';
import StatusChip from './StatusChip';

interface Props {
    data: IBankTransaction & { transaction?: ITransaction };
}

const BankTransactionDetails: FC<Props> = ({ data: bankTransaction }) => {
    const codesList = Object.keys(bankTransaction.data?.codes || {}).reduce((result, code) => {
        const value = bankTransaction.data.codes[code];
        if (value) {
            result.push(`${code}=${value}`);
        }
        return result;
    }, [] as string[]);

    const subtagsList = (bankTransaction.data?.subtags || []).reduce((result, item) => {
        const { type, value } = item;
        if (value) {
            result.push(`${type}=${value}`);
        }
        return result;
    }, [] as string[]);

    return (
        <Section title="Imported Bank Transaction">
            <DetailsItem title="Status" value={<StatusChip status={bankTransaction.status} />} />
            <DetailsItem title="Amount" value={currency(bankTransaction.amount)} />
            <DetailsItem title="Bank Account" value={bankTransaction.bankAccountIban} />
            <DetailsItem title="Entry Date" value={new Date(bankTransaction.entryDate).toLocaleString()} />
            <DetailsItem title="Created at" value={new Date(bankTransaction.createdAt).toLocaleString()} />
            <DetailsItem title="Description" value={bankTransaction.description} />
            <DetailsItem title="User name match" value={bankTransaction.data.isNameMatching} />
            <DetailsItem title="Payer Name" value={bankTransaction.data.payerName} />
            <DetailsItem title="Data Source" value={bankTransaction.source} />

            {codesList.length ? <DetailsItem title="Parsed Codes" value={codesList.join(', ')} /> : null}
            {subtagsList.length ? <DetailsItem title="Tags" value={subtagsList.join(', ')} /> : null}

            {bankTransaction.transaction ? (
                <>
                    <Typography color="text.secondary" variant="overline">
                        Linked Transaction
                    </Typography>
                    <DetailsItem title="ID" value={bankTransaction.transaction.id} />
                    <DetailsItem title="Amount" value={currency(bankTransaction.transaction.amount)} />
                    <DetailsItem title="Flow" value={bankTransaction.transaction.flow} />
                    <DetailsItem title="Type" value={bankTransaction.transaction.type} />
                    <DetailsItem title="Received At" value={new Date(bankTransaction.transaction.receivedAt).toLocaleString()} />
                    <DetailsItem title="Created At" value={new Date(bankTransaction.transaction.createdAt).toLocaleString()} />
                    <DetailsItem
                        title="IBANs"
                        value={`${bankTransaction.transaction.data?.sourceIban || 'n/a'} ⇒ ${bankTransaction.transaction.data?.targetIban || 'n/a'}`}
                    />
                </>
            ) : null}
        </Section>
    );
};

export default BankTransactionDetails;
